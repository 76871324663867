import Vue from 'vue'

/**
 * 判断是否是IE
 * @returns boolean
 */
function isIE() {
    if (!!window.ActiveXobject || 'ActiveXObject' in window) {
        return true
    } else {
        return false
    }
}
/**
 * 判断是否是IE11
 * @returns boolean
 */
function isIE11() {
    if ((/Trident\/7\./).test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

/**
 * 鉴权指令
 * @param Vue
 * @param options
 */
export default Vue.directive('auth', {
    inserted(el, bind, vNode) {
        // 获取 按钮权限
        const buttonRole = vNode.context.$store.getters.buttonRole || {}
        // 获取按钮值
        const roleName = bind.value
        if (Array.isArray(roleName)) {
            let includeRole = roleName.every(item => !buttonRole[item])
            if (includeRole) {
                if (isIE() || isIE11()) {
                    el.removeNode(true)
                } else {
                    el.remove()
                }
            }
        } else {
            // 判断是否有权限
            if (!buttonRole[roleName]) {
                if (isIE() || isIE11()) {
                    el.removeNode(true)
                } else {
                    el.remove()
                }
            }
        }
    }
})
