//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Divider as ADivider
} from 'ant-design-vue'

import {
    AN_BEI_URL,
    ICP_URL
} from '@/config'

export default {
    name: 'CrmGlobalFooter',
    components: {
        ADivider
    },
    data() {
        return {
            // 浙公网安备 url
            anBeiUrl: AN_BEI_URL,
            // 浙ICP url
            ICPUrl: ICP_URL
        }
    }
}
