//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ConfigProvider as ALocaleProvider } from 'ant-design-vue'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import TopBar from '@/components/GlobalTopBar'
import UserNav from '@/components/GlobalUserNav'
import GlobalFooter from '@/components/GlobarFooter'

import {
    PRODUCT_CONSULTING, // 类型（0：产品咨询）
    AGENT_CONSULTING // 类型（1：经纪人咨询）
} from '@/constant/enum'
moment.locale('zh-cn')

export default {
    name: 'UserLayout',
    components: {
        TopBar,
        UserNav,
        GlobalFooter,
        ALocaleProvider
    },
    data() {
        return {
            zh_CN
        }
    },
    mounted() {
        this.getAgentInsuranceCountService()
        this.getProductInsuranceCountService()
    },
    methods: {
        getAgentInsuranceCountService() {
            const data = {
                type: AGENT_CONSULTING,
                mutationsType: 'SET_AGENT_INSURANCE_COUNT'
            }
            this.$store.dispatch('getInsuranceCountService', data)
        },
        getProductInsuranceCountService() {
            const data = {
                type: PRODUCT_CONSULTING,
                mutationsType: 'SET_PRODUCT_INSURANCE_COUNT'
            }
            this.$store.dispatch('getInsuranceCountService', data)
        }
    }

}
