//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Steps as ASteps
} from 'ant-design-vue'
export default {
    name: 'RightBar',
    components: {
        ASteps,
        AStep: ASteps.Step
    },
    props: {
        current: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            stepList: [
                {
                    name: '填写投保信息',
                    index: 0
                },
                {
                    name: '确认投保信息',
                    index: 1
                },
                {
                    name: '支付保费',
                    index: 2
                },
                {
                    name: '完成',
                    index: 3
                }
            ]
        }
    }
}
