import { cookies } from '@/plugins/cookies'
import { TOKEN_KEY, CRM_TOKEN_KEY, TOKEN_TIME, ENTERPRISE_AUTHORIZATION_ID } from '@/config'

/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
    return cookies.get(TOKEN_KEY)
}

/**
 * 获取 CRM token
 * @returns {*}
 */
export function getCRMToken() {
    return cookies.get(CRM_TOKEN_KEY)
}


/**
 * 设置token
 * @param token
 * @returns {*}
 */
export function setToken(token) {
    return cookies.set(TOKEN_KEY, token, {
        maxAge: TOKEN_TIME,
        path: '/'
    })
}

/**
 * 设置 CRM token
 * @param token
 * @returns {*}
 */
export function setCRMToken(token) {
    return cookies.set(CRM_TOKEN_KEY, token, {
        maxAge: TOKEN_TIME,
        path: '/'
    })
}

/**
 * 删除token
 * @returns {*}
 */
export function removeToken() {
    return cookies.set(TOKEN_KEY, '', {
        maxAge: -1,
        path: '/'
    })
}

/**
 * 设置 enterpriseAuthorizationId
 * @param token
 * @returns {*}
 */
export function setEnterpriseAuthorizationId(enterpriseAuthorizationId) {
    return cookies.set(ENTERPRISE_AUTHORIZATION_ID, enterpriseAuthorizationId, {
        maxAge: TOKEN_TIME,
        path: '/'
    })
}


/**
 * 删除 enterpriseAuthorizationId
 * @returns {*}
 */
export function removeEnterpriseAuthorizationId() {
    return cookies.set(ENTERPRISE_AUTHORIZATION_ID, '', {
        maxAge: -1,
        path: '/'
    })
}
