export function getBrowser(userAgent) {
    const NAVIGATOR_USERAGENT = userAgent || navigator.userAgent

    // 是否为IE内核
    const IS_TRIDENT = NAVIGATOR_USERAGENT.includes('Trident')

    // 是否为Opera内核
    const IS_PRESTO = NAVIGATOR_USERAGENT.includes('Presto')

    // 是否为苹果/谷歌内核
    const IS_WEBKIT = NAVIGATOR_USERAGENT.includes('AppleWebKit')

    // 是否为火狐内核
    const IS_GECKO = NAVIGATOR_USERAGENT.includes('Gecko') && !NAVIGATOR_USERAGENT.includes('KHTML')

    // 是否为移动终端
    const IS_MOBILE = !!NAVIGATOR_USERAGENT.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i)

    // 是否为ios终端
    const IS_IOS = !!NAVIGATOR_USERAGENT.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

    // 是否为android终端或者uc浏览器
    const IS_ANDROID = NAVIGATOR_USERAGENT.includes('Android') || NAVIGATOR_USERAGENT.includes('Linux')

    // 是否为iPhone或者QQHD浏览器
    const IS_IPHONE = NAVIGATOR_USERAGENT.includes('iPhone')

    // 是否为iPad
    const IS_IPAD = NAVIGATOR_USERAGENT.includes('iPad')

    return {
        versions: {
            trident: IS_TRIDENT,
            presto: IS_PRESTO,
            webKit: IS_WEBKIT,
            gecko: IS_GECKO,
            mobile: IS_MOBILE,
            ios: IS_IOS,
            android: IS_ANDROID,
            iPhone: IS_IPHONE,
            iPad: IS_IPAD
        }
    }
}
