import Vue from 'vue'
import Vuex from 'vuex'

import {
    SET_TOKEN,
    SET_CRM_TOKEN,
    SET_ROLE,
    SET_BUTTON_ROLE,
    SET_USER_INFO,
    SET_BREADCRUMB,
    SET_BREADCRUMB_BY_NAME,
    SET_BREADCRUMB_BY_PATH,
    SET_INSURE_STEP_CURRENT,
    SET_SIDE_MENU,
    SHOW_INVITE_BROKER,
    SET_PRODUCT_INSURANCE_COUNT,
    SET_AGENT_INSURANCE_COUNT,
    SET_BUSINESS_DETAILS
} from './mutation-types'
import {
    USER_TYPE_BROKER, // 用户类型-枚举 -- 经纪端
    USER_TYPE_INSURER, // 用户类型-枚举 -- 保险端
    USER_TYPE_INSURED, // 用户类型-枚举 -- 用户端
    PERMISSION_TYPE_MENU, // 菜单权限
    PERMISSION_TYPE_BUTTON, // 按钮权限
    APP_CODE_NET_AGENT,
    APP_CODE_NET_INS,
    APP_CODE_NET_USER,
    HOUSE_KEEPER_USER_TYPE_CODE
} from '@/constant/enum'

import { SideMenuFactory } from '@/config/sideMenu'
import { getToken, removeToken, setToken, setCRMToken } from '@/utils/auth'
import { getRule, getShowMenu } from '@/utils/permission'
import userLogOutService from '@/service/userLogOutService'
import getUserInfoService from '@/service/getUserInfoService'
import getUserPermissionService from '@/service/getUserPermissionService'
import getUserGroupService from '@/service/getUserGroupService'

import insuranceCountService from '@/service/customerService/insurance/insuranceCountService'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export const state = () => ({
    userInfo: null,
    breadcrumbData: [],
    insureStepCurrent: 0,
    sideMenu: [], // 侧边栏
    showInviteBroker: null, // 判断是否显示邀请经纪人
    role: null, // 菜单权限
    buttonRole: null, // 按钮权限
    productInsuranceCount: 0,
    agentInsuranceCount: 0,
    // 业务明细
    setBusinessDetails: ''
})
export const getters = {
    userInfo(state) {
        return state.userInfo
    },
    insureStepCurrent(state) {
        return state.insureStepCurrent
    },
    sideMenu(state) {
        return state.sideMenu || []
    },
    showInviteBroker(state) {
        return state.showInviteBroker
    },
    role(state) {
        return state.role
    },
    buttonRole(state) {
        return state.buttonRole
    },
    productInsuranceCount(state) {
        return state.productInsuranceCount || 0
    },
    agentInsuranceCount(state) {
        return state.agentInsuranceCount || 0
    },
    // 业务明细
    setBusinessDetails(state) {
        return state.setBusinessDetails || 0
    }
}
export const mutations = {
    [SET_TOKEN](state, token) {
        state.token = token
    },
    [SET_CRM_TOKEN](state, tokenCRM) {
        state.tokenCRM = tokenCRM
    },
    [SET_ROLE](state, role) {
        state.role = role
    },
    [SET_BUTTON_ROLE](state, buttonRole) {
        state.buttonRole = buttonRole
    },
    [SET_USER_INFO](state, userInfo) {
        state.userInfo = userInfo
    },
    [SET_BREADCRUMB](state, breadcrumbData) {
        state.breadcrumbData = breadcrumbData
    },

    [SET_BREADCRUMB_BY_NAME](state, { oldName, newName }) {
        let curBreadcrumb = state.breadcrumbData.find(
            breadcrumb => breadcrumb.name === oldName
        )

        curBreadcrumb && (curBreadcrumb.name = newName)
    },

    [SET_BREADCRUMB_BY_PATH](state, { path, name }) {
        let curBreadcrumb = state.breadcrumbData.find(
            breadcrumb => breadcrumb.path === path
        )
        curBreadcrumb && (curBreadcrumb.name = name)
    },
    [SET_INSURE_STEP_CURRENT](state, insureStepCurrent) {
        state.insureStepCurrent = insureStepCurrent
    },
    [SET_SIDE_MENU](state, sideMenu) {
        state.sideMenu = sideMenu
    },

    [SHOW_INVITE_BROKER](state, showInviteBroker) {
        state.showInviteBroker = showInviteBroker
    },
    [SET_PRODUCT_INSURANCE_COUNT](state, productInsuranceCount) {
        state.productInsuranceCount = productInsuranceCount
    },
    [SET_AGENT_INSURANCE_COUNT](state, agentInsuranceCount) {
        state.agentInsuranceCount = agentInsuranceCount
    },
    [SET_BUSINESS_DETAILS](state, setBusinessDetails) {
        state.setBusinessDetails = setBusinessDetails
    }
}
export const actions = {
    /**
     * 登出系统
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
    async logOut({ commit }) {
        commit(SET_TOKEN, '')

        // 注销
        if (getToken()) {
            await userLogOutService()
        }
        removeToken()
        window.location.reload()
    },

    /**
     * 获取用户信息
     * @param commit
     * @returns {Promise<void>}
     */
    async getUserInfo({ commit }) {
        let userInfo = await getUserInfoService()
        const permissionMenu = await getUserPermissionService({
            type: PERMISSION_TYPE_MENU
        }) // 菜单-获取权限
        const permissionButton = await getUserPermissionService({
            type: PERMISSION_TYPE_BUTTON
        }) // 按钮-获取权限
        const menu = getRule(permissionMenu) // 菜单-格式化为对象
        const button = {
            ...getRule(permissionButton),
            ...menu
        } // 按钮-格式化为对象，并针对新开页将菜单权限加入按钮权限中

        // 菜单权限
        await commit(SET_ROLE, menu)
        // 按钮权限
        await commit(SET_BUTTON_ROLE, button)
        // 用户信息
        await commit(SET_USER_INFO, userInfo || {})

        userInfo = userInfo || {}
        let sideMenu = [],
            [{ roleName = '' } = {}] = userInfo.roleList || []

        let { appCode, valueList } = userInfo

        // if (roleName.includes("经纪")) {
        if (appCode === APP_CODE_NET_AGENT) {
            sideMenu = SideMenuFactory[USER_TYPE_BROKER]
        } else if (appCode === APP_CODE_NET_INS) {
            sideMenu = SideMenuFactory[USER_TYPE_INSURER]
        } else {
            sideMenu = SideMenuFactory[USER_TYPE_INSURED]
        }
        // } else if (roleName.includes("保险")) {
        //     sideMenu = SideMenuFactory[USER_TYPE_INSURER];
        // } else {
        //     sideMenu = SideMenuFactory[USER_TYPE_INSURED];
        // }
        await getShowMenu(sideMenu, menu)
        let sideMenuNew = JSON.parse(JSON.stringify(sideMenu))
        await commit(SET_SIDE_MENU, sideMenuNew)
        if (appCode === APP_CODE_NET_AGENT) {
            // 判断是否显示邀请经纪人逻辑修改  根据userInfo里面valueList里面是否有userTypeCode为4
            let isShowInviteBroker = valueList.some(
                item => Number(item.userTypeCode) === HOUSE_KEEPER_USER_TYPE_CODE
            )
            sideMenu.forEach(item => {
                item.treeList.forEach((item1, index) => {
                    if (item1.component === 'user-broker-invite_brokers') {
                        item1.hidden = !isShowInviteBroker
                    }
                })
            })
            let sideMenuIsAgent = JSON.parse(JSON.stringify(sideMenu))
            await commit(SET_SIDE_MENU, sideMenuIsAgent)
        }
    },

    setTokenAction({ commit }, token) {
        commit(SET_TOKEN, token)
        setToken(token)
    },

    /**
     * 保存 CRM token
     * @param commit
     * @returns {Promise<void>}
     */
    setTokenActionCRM({ commit }, token) {
        commit(SET_CRM_TOKEN, token)
        setCRMToken(token)
    },

    async getInsuranceCountService({ commit, state }, payload) {
        let differentCharacterPerspectives = null
        if (state.userInfo?.appCode === APP_CODE_NET_AGENT) {
            differentCharacterPerspectives = 1
        } else if (state.userInfo?.appCode === APP_CODE_NET_USER) {
            differentCharacterPerspectives = 0
        } else {
            return
        }
        const data = await insuranceCountService({
            type: payload.type,
            stateQuery: 1,
            mobileQuery: state.userInfo?.mobile || '',
            userIdQuery: state.userInfo?.id || '',
            differentCharacterPerspectives
        })
        commit(payload.mutationsType, data || 0)
    }
}
