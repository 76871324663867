//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LeftPanel from './LeftPanel'
import TabPanel from './TabPanel'
import SearchPanel from '@/components/GlobalNavBar/SearchPanel'
export default {
    name: 'GlobalUserNav',
    components: {
        LeftPanel,
        TabPanel,
        SearchPanel
    }
}
