//
//
//
//
//
//
//
//
//

import LeftBar from './LeftBar'
import RightBar from './RightBar'
export default {
    name: 'GlobalTopBar',
    components: {
        LeftBar,
        RightBar
    },
    computed: {
        crm() { // 分享链接携带经纪人参数情况下 不显示左侧信息避免跳转
            return this.$route?.query?.agentUserId ? true : false
        }
    }
}
