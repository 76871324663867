//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ACCOUNT_SET_MY_ACCOUNT_MENU_CODE } from '@/constant/menuEnum'
import {
    Input as AInput,
    Button as AButton,
    // Icon as AIcon,
    Divider as ADivider,
    Select as ASelect
} from 'ant-design-vue'

import { getToken } from '@/utils/auth'
export default {
    name: 'SearchPanel',
    components: {
        AInput,
        AInputSearch: AInput.Search,
        AButton,
        // AIcon,
        ADivider,
        ASelect,
        ASelectOption: ASelect.Option
    },
    data() {
        return {
            // 我的账户权限
            ACCOUNT_SET_MY_ACCOUNT_MENU_CODE,
            // 搜索-关键字
            keywords: '',
            // 搜索-类型
            keyType: '1',
            keyTypeList: [
                {
                    label: '产品',
                    value: '1',
                    routeName: 'product'
                }, {
                    label: '购物车',
                    value: '2',
                    routeName: 'cart'
                }
            ],
            // 搜索-业务url
            keyUrl: 'product'
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo
        },
        token() {
            return getToken()
        },
        // 是否展示登录
        showLogin() {
            const { userInfo } = this
            const { path, query } = this.$route || {}
            const { agentUserId } = query || {}
            let result = true
            if (!userInfo && (path === '/product/info' || path === '/cart') && agentUserId) {
                result = false
            } else {
                result = true
            }
            return result
        }
    },
    watch: {
        $route: {
            handler(route) {
                const { name } = route || {}
                // 搜索-购物车默认搜索购物车数据，其余业务搜索产品数据
                if (name === 'cart') {
                    this.keyType = '2'
                } else {
                    this.keyType = '1'
                }
            },
            immediate: true
        }
        // keywords: {
        //     handler(val) {
        //         this.handleSearch()
        //     },
        //     immediate: true
        // }
    },
    mounted() {
    },
    methods: {
        /**
         * 搜索-关键字
         */
        handleSearch() {
            const { keywords, keyType, keyTypeList } = this
            const keyUrl = keyTypeList.filter(item => item.value === keyType)[0].routeName
            this.$router.push({
                name: keyUrl,
                query: {
                    keywords
                }
            })
        },
        handleLogin() {
            this.$router.push({
                name: 'login'
            })
        },
        handleRegister() {
            this.$router.push({
                name: 'register'
            })
        },
        /**
         * 跳转我的账户
         */
        handleMyCount() {
            this.$router.push({
                name: 'set_account-user_info'
            })
        }
    }
}
