import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _07b969e6 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _4bbf200c = () => interopDefault(import('../pages/cashier/index.vue' /* webpackChunkName: "pages/cashier/index" */))
const _2030ad51 = () => interopDefault(import('../pages/companyInfo.vue' /* webpackChunkName: "pages/companyInfo" */))
const _c61320f8 = () => interopDefault(import('../pages/enterprise_service/index.vue' /* webpackChunkName: "pages/enterprise_service/index" */))
const _72f3f3a7 = () => interopDefault(import('../pages/info_disclosure/index.vue' /* webpackChunkName: "pages/info_disclosure/index" */))
const _f73eacb2 = () => interopDefault(import('../pages/insure/index.vue' /* webpackChunkName: "pages/insure/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5665078d = () => interopDefault(import('../pages/message.vue' /* webpackChunkName: "pages/message" */))
const _8e968990 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _2d61006c = () => interopDefault(import('../pages/questionnaire/index.vue' /* webpackChunkName: "pages/questionnaire/index" */))
const _33c00405 = () => interopDefault(import('../pages/quote/index.vue' /* webpackChunkName: "pages/quote/index" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _0fa005a4 = () => interopDefault(import('../pages/salary_million/index.vue' /* webpackChunkName: "pages/salary_million/index" */))
const _424a5250 = () => interopDefault(import('../pages/service_broker/index.vue' /* webpackChunkName: "pages/service_broker/index" */))
const _9a51181e = () => interopDefault(import('../pages/service_insure/index.vue' /* webpackChunkName: "pages/service_insure/index" */))
const _6bc15c41 = () => interopDefault(import('../pages/signature/index.vue' /* webpackChunkName: "pages/signature/index" */))
const _152321f8 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _47fbe09a = () => interopDefault(import('../pages/wiki.vue' /* webpackChunkName: "pages/wiki" */))
const _ca61cab2 = () => interopDefault(import('../pages/cart/confirm_pay/index.vue' /* webpackChunkName: "pages/cart/confirm_pay/index" */))
const _4c5e7285 = () => interopDefault(import('../pages/cashier/paid/index.vue' /* webpackChunkName: "pages/cashier/paid/index" */))
const _0f348fb1 = () => interopDefault(import('../pages/cashier/pay_completed/index.vue' /* webpackChunkName: "pages/cashier/pay_completed/index" */))
const _7ccd9a29 = () => interopDefault(import('../pages/cashier/pay_success/index.vue' /* webpackChunkName: "pages/cashier/pay_success/index" */))
const _7b75fdbb = () => interopDefault(import('../pages/claim_service/detail/index.vue' /* webpackChunkName: "pages/claim_service/detail/index" */))
const _1b621cf0 = () => interopDefault(import('../pages/claim_service/list/index.vue' /* webpackChunkName: "pages/claim_service/list/index" */))
const _23d28118 = () => interopDefault(import('../pages/data_base/list/index.vue' /* webpackChunkName: "pages/data_base/list/index" */))
const _631ba4bc = () => interopDefault(import('../pages/error_page/404/index.vue' /* webpackChunkName: "pages/error_page/404/index" */))
const _03e83f8e = () => interopDefault(import('../pages/error_page/500/index.vue' /* webpackChunkName: "pages/error_page/500/index" */))
const _78e412b3 = () => interopDefault(import('../pages/insure/audit/index.vue' /* webpackChunkName: "pages/insure/audit/index" */))
const _72f94fd0 = () => interopDefault(import('../pages/insure/confirm/index.vue' /* webpackChunkName: "pages/insure/confirm/index" */))
const _31c076c2 = () => interopDefault(import('../pages/insure/message/index.vue' /* webpackChunkName: "pages/insure/message/index" */))
const _07ec951e = () => interopDefault(import('../pages/insure/submit_message/index.vue' /* webpackChunkName: "pages/insure/submit_message/index" */))
const _0d79e4d6 = () => interopDefault(import('../pages/news/detail/index.vue' /* webpackChunkName: "pages/news/detail/index" */))
const _8270393a = () => interopDefault(import('../pages/news/list/index.vue' /* webpackChunkName: "pages/news/list/index" */))
const _2787ed9f = () => interopDefault(import('../pages/order/detail/index.vue' /* webpackChunkName: "pages/order/detail/index" */))
const _2194fd6c = () => interopDefault(import('../pages/order/evaluation/index.vue' /* webpackChunkName: "pages/order/evaluation/index" */))
const _01238a6c = () => interopDefault(import('../pages/order/list/index.vue' /* webpackChunkName: "pages/order/list/index" */))
const _c0ff69a4 = () => interopDefault(import('../pages/order/surrender/index.vue' /* webpackChunkName: "pages/order/surrender/index" */))
const _112e08b8 = () => interopDefault(import('../pages/policy/list/index.vue' /* webpackChunkName: "pages/policy/list/index" */))
const _8dc6fb8a = () => interopDefault(import('../pages/product/info/index.vue' /* webpackChunkName: "pages/product/info/index" */))
const _67ee2d56 = () => interopDefault(import('../pages/questionnaire/constant.js' /* webpackChunkName: "pages/questionnaire/constant" */))
const _619895ef = () => interopDefault(import('../pages/questionnaire/SubmitModal.vue' /* webpackChunkName: "pages/questionnaire/SubmitModal" */))
const _668060f4 = () => interopDefault(import('../pages/service_broker/detail/index.vue' /* webpackChunkName: "pages/service_broker/detail/index" */))
const _6ef8c6c5 = () => interopDefault(import('../pages/service_insure/detail/index.vue' /* webpackChunkName: "pages/service_insure/detail/index" */))
const _3442b02b = () => interopDefault(import('../pages/set_account/account_safe/index.vue' /* webpackChunkName: "pages/set_account/account_safe/index" */))
const _1e031710 = () => interopDefault(import('../pages/set_account/invoice_title/index.vue' /* webpackChunkName: "pages/set_account/invoice_title/index" */))
const _9ef08ebc = () => interopDefault(import('../pages/set_account/receive_address/index.vue' /* webpackChunkName: "pages/set_account/receive_address/index" */))
const _97eded28 = () => interopDefault(import('../pages/set_account/user_info/index.vue' /* webpackChunkName: "pages/set_account/user_info/index" */))
const _19014445 = () => interopDefault(import('../pages/share/quotation_sheet/index.vue' /* webpackChunkName: "pages/share/quotation_sheet/index" */))
const _5d1a6a31 = () => interopDefault(import('../pages/terms_agreement/privacy_policy_clause/index.vue' /* webpackChunkName: "pages/terms_agreement/privacy_policy_clause/index" */))
const _10c6b94c = () => interopDefault(import('../pages/terms_agreement/service_agreement/index.vue' /* webpackChunkName: "pages/terms_agreement/service_agreement/index" */))
const _dcebd998 = () => interopDefault(import('../pages/channel/product/list.vue' /* webpackChunkName: "pages/channel/product/list" */))
const _40d122f0 = () => interopDefault(import('../pages/channel/userCenter/home.vue' /* webpackChunkName: "pages/channel/userCenter/home" */))
const _42a2fe60 = () => interopDefault(import('../pages/set_account/account_safe/log_off/index.vue' /* webpackChunkName: "pages/set_account/account_safe/log_off/index" */))
const _c1ed9c18 = () => interopDefault(import('../pages/set_account/account_safe/update_mobile/index.vue' /* webpackChunkName: "pages/set_account/account_safe/update_mobile/index" */))
const _2de6a6ed = () => interopDefault(import('../pages/set_account/account_safe/update_password/index.vue' /* webpackChunkName: "pages/set_account/account_safe/update_password/index" */))
const _77182270 = () => interopDefault(import('../pages/share/insure/confirm/index.vue' /* webpackChunkName: "pages/share/insure/confirm/index" */))
const _75e5d5ba = () => interopDefault(import('../pages/user/broker/invite_brokers/index.vue' /* webpackChunkName: "pages/user/broker/invite_brokers/index" */))
const _39c47171 = () => interopDefault(import('../pages/user/broker/invite_users/index.vue' /* webpackChunkName: "pages/user/broker/invite_users/index" */))
const _2041844a = () => interopDefault(import('../pages/user/broker/quotation_tool/index.vue' /* webpackChunkName: "pages/user/broker/quotation_tool/index" */))
const _8177bafc = () => interopDefault(import('../pages/user/broker/service_broker/index.vue' /* webpackChunkName: "pages/user/broker/service_broker/index" */))
const _2fd14423 = () => interopDefault(import('../pages/user/broker/service_insure/index.vue' /* webpackChunkName: "pages/user/broker/service_insure/index" */))
const _27ec012d = () => interopDefault(import('../pages/user/insurer/audit/index.vue' /* webpackChunkName: "pages/user/insurer/audit/index" */))
const _23c278e0 = () => interopDefault(import('../pages/user/insurer/order/index.vue' /* webpackChunkName: "pages/user/insurer/order/index" */))
const _47c2d3dc = () => interopDefault(import('../pages/user/broker/correct_service/info/index.vue' /* webpackChunkName: "pages/user/broker/correct_service/info/index" */))
const _dd6c1868 = () => interopDefault(import('../pages/user/broker/correct_service/list/index.vue' /* webpackChunkName: "pages/user/broker/correct_service/list/index" */))
const _533b56f3 = () => interopDefault(import('../pages/user/broker/promote_guest/list/index.vue' /* webpackChunkName: "pages/user/broker/promote_guest/list/index" */))
const _47d4e54f = () => interopDefault(import('../pages/user/insured/correct_service/info/index.vue' /* webpackChunkName: "pages/user/insured/correct_service/info/index" */))
const _dd47f582 = () => interopDefault(import('../pages/user/insured/correct_service/list/index.vue' /* webpackChunkName: "pages/user/insured/correct_service/list/index" */))
const _a64ec3d4 = () => interopDefault(import('../pages/user/insured/invoice_manage/apply_invoice/index.vue' /* webpackChunkName: "pages/user/insured/invoice_manage/apply_invoice/index" */))
const _31c770ca = () => interopDefault(import('../pages/user/insured/invoice_manage/info/index.vue' /* webpackChunkName: "pages/user/insured/invoice_manage/info/index" */))
const _7b4e90ba = () => interopDefault(import('../pages/user/insured/invoice_manage/list/index.vue' /* webpackChunkName: "pages/user/insured/invoice_manage/list/index" */))
const _8b0f38fe = () => interopDefault(import('../pages/user/insurer/correct_service/info/index.vue' /* webpackChunkName: "pages/user/insurer/correct_service/info/index" */))
const _03ff8371 = () => interopDefault(import('../pages/user/insurer/correct_service/list/index.vue' /* webpackChunkName: "pages/user/insurer/correct_service/list/index" */))
const _56826458 = () => interopDefault(import('../pages/user/insurer/invoice_manage/info/index.vue' /* webpackChunkName: "pages/user/insurer/invoice_manage/info/index" */))
const _bfecf770 = () => interopDefault(import('../pages/user/insurer/invoice_manage/list/index.vue' /* webpackChunkName: "pages/user/insurer/invoice_manage/list/index" */))
const _a0cbb214 = () => interopDefault(import('../pages/user/insurer/order/detail/index.vue' /* webpackChunkName: "pages/user/insurer/order/detail/index" */))
const _575e4c9f = () => interopDefault(import('../pages/user/insurer/order/send_mail/index.vue' /* webpackChunkName: "pages/user/insurer/order/send_mail/index" */))
const _3c1e5c3e = () => interopDefault(import('../pages/user/insurer/order/setting/index.vue' /* webpackChunkName: "pages/user/insurer/order/setting/index" */))
const _11d0240c = () => interopDefault(import('../pages/user/insurer/order/supplement_audit/index.vue' /* webpackChunkName: "pages/user/insurer/order/supplement_audit/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _07b969e6,
    name: "cart"
  }, {
    path: "/cashier",
    component: _4bbf200c,
    name: "cashier"
  }, {
    path: "/companyInfo",
    component: _2030ad51,
    name: "companyInfo"
  }, {
    path: "/enterprise_service",
    component: _c61320f8,
    name: "enterprise_service"
  }, {
    path: "/info_disclosure",
    component: _72f3f3a7,
    name: "info_disclosure"
  }, {
    path: "/insure",
    component: _f73eacb2,
    name: "insure"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/message",
    component: _5665078d,
    name: "message"
  }, {
    path: "/product",
    component: _8e968990,
    name: "product"
  }, {
    path: "/questionnaire",
    component: _2d61006c,
    name: "questionnaire"
  }, {
    path: "/quote",
    component: _33c00405,
    name: "quote"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/salary_million",
    component: _0fa005a4,
    name: "salary_million"
  }, {
    path: "/service_broker",
    component: _424a5250,
    name: "service_broker"
  }, {
    path: "/service_insure",
    component: _9a51181e,
    name: "service_insure"
  }, {
    path: "/signature",
    component: _6bc15c41,
    name: "signature"
  }, {
    path: "/user",
    component: _152321f8,
    name: "user"
  }, {
    path: "/wiki",
    component: _47fbe09a,
    name: "wiki"
  }, {
    path: "/cart/confirm_pay",
    component: _ca61cab2,
    name: "cart-confirm_pay"
  }, {
    path: "/cashier/paid",
    component: _4c5e7285,
    name: "cashier-paid"
  }, {
    path: "/cashier/pay_completed",
    component: _0f348fb1,
    name: "cashier-pay_completed"
  }, {
    path: "/cashier/pay_success",
    component: _7ccd9a29,
    name: "cashier-pay_success"
  }, {
    path: "/claim_service/detail",
    component: _7b75fdbb,
    name: "claim_service-detail"
  }, {
    path: "/claim_service/list",
    component: _1b621cf0,
    name: "claim_service-list"
  }, {
    path: "/data_base/list",
    component: _23d28118,
    name: "data_base-list"
  }, {
    path: "/error_page/404",
    component: _631ba4bc,
    name: "error_page-404"
  }, {
    path: "/error_page/500",
    component: _03e83f8e,
    name: "error_page-500"
  }, {
    path: "/insure/audit",
    component: _78e412b3,
    name: "insure-audit"
  }, {
    path: "/insure/confirm",
    component: _72f94fd0,
    name: "insure-confirm"
  }, {
    path: "/insure/message",
    component: _31c076c2,
    name: "insure-message"
  }, {
    path: "/insure/submit_message",
    component: _07ec951e,
    name: "insure-submit_message"
  }, {
    path: "/news/detail",
    component: _0d79e4d6,
    name: "news-detail"
  }, {
    path: "/news/list",
    component: _8270393a,
    name: "news-list"
  }, {
    path: "/order/detail",
    component: _2787ed9f,
    name: "order-detail"
  }, {
    path: "/order/evaluation",
    component: _2194fd6c,
    name: "order-evaluation"
  }, {
    path: "/order/list",
    component: _01238a6c,
    name: "order-list"
  }, {
    path: "/order/surrender",
    component: _c0ff69a4,
    name: "order-surrender"
  }, {
    path: "/policy/list",
    component: _112e08b8,
    name: "policy-list"
  }, {
    path: "/product/info",
    component: _8dc6fb8a,
    name: "product-info"
  }, {
    path: "/questionnaire/constant",
    component: _67ee2d56,
    name: "questionnaire-constant"
  }, {
    path: "/questionnaire/SubmitModal",
    component: _619895ef,
    name: "questionnaire-SubmitModal"
  }, {
    path: "/service_broker/detail",
    component: _668060f4,
    name: "service_broker-detail"
  }, {
    path: "/service_insure/detail",
    component: _6ef8c6c5,
    name: "service_insure-detail"
  }, {
    path: "/set_account/account_safe",
    component: _3442b02b,
    name: "set_account-account_safe"
  }, {
    path: "/set_account/invoice_title",
    component: _1e031710,
    name: "set_account-invoice_title"
  }, {
    path: "/set_account/receive_address",
    component: _9ef08ebc,
    name: "set_account-receive_address"
  }, {
    path: "/set_account/user_info",
    component: _97eded28,
    name: "set_account-user_info"
  }, {
    path: "/share/quotation_sheet",
    component: _19014445,
    name: "share-quotation_sheet"
  }, {
    path: "/terms_agreement/privacy_policy_clause",
    component: _5d1a6a31,
    name: "terms_agreement-privacy_policy_clause"
  }, {
    path: "/terms_agreement/service_agreement",
    component: _10c6b94c,
    name: "terms_agreement-service_agreement"
  }, {
    path: "/channel/product/list",
    component: _dcebd998,
    name: "channel-product-list"
  }, {
    path: "/channel/userCenter/home",
    component: _40d122f0,
    name: "channel-userCenter-home"
  }, {
    path: "/set_account/account_safe/log_off",
    component: _42a2fe60,
    name: "set_account-account_safe-log_off"
  }, {
    path: "/set_account/account_safe/update_mobile",
    component: _c1ed9c18,
    name: "set_account-account_safe-update_mobile"
  }, {
    path: "/set_account/account_safe/update_password",
    component: _2de6a6ed,
    name: "set_account-account_safe-update_password"
  }, {
    path: "/share/insure/confirm",
    component: _77182270,
    name: "share-insure-confirm"
  }, {
    path: "/user/broker/invite_brokers",
    component: _75e5d5ba,
    name: "user-broker-invite_brokers"
  }, {
    path: "/user/broker/invite_users",
    component: _39c47171,
    name: "user-broker-invite_users"
  }, {
    path: "/user/broker/quotation_tool",
    component: _2041844a,
    name: "user-broker-quotation_tool"
  }, {
    path: "/user/broker/service_broker",
    component: _8177bafc,
    name: "user-broker-service_broker"
  }, {
    path: "/user/broker/service_insure",
    component: _2fd14423,
    name: "user-broker-service_insure"
  }, {
    path: "/user/insurer/audit",
    component: _27ec012d,
    name: "user-insurer-audit"
  }, {
    path: "/user/insurer/order",
    component: _23c278e0,
    name: "user-insurer-order"
  }, {
    path: "/user/broker/correct_service/info",
    component: _47c2d3dc,
    name: "user-broker-correct_service-info"
  }, {
    path: "/user/broker/correct_service/list",
    component: _dd6c1868,
    name: "user-broker-correct_service-list"
  }, {
    path: "/user/broker/promote_guest/list",
    component: _533b56f3,
    name: "user-broker-promote_guest-list"
  }, {
    path: "/user/insured/correct_service/info",
    component: _47d4e54f,
    name: "user-insured-correct_service-info"
  }, {
    path: "/user/insured/correct_service/list",
    component: _dd47f582,
    name: "user-insured-correct_service-list"
  }, {
    path: "/user/insured/invoice_manage/apply_invoice",
    component: _a64ec3d4,
    name: "user-insured-invoice_manage-apply_invoice"
  }, {
    path: "/user/insured/invoice_manage/info",
    component: _31c770ca,
    name: "user-insured-invoice_manage-info"
  }, {
    path: "/user/insured/invoice_manage/list",
    component: _7b4e90ba,
    name: "user-insured-invoice_manage-list"
  }, {
    path: "/user/insurer/correct_service/info",
    component: _8b0f38fe,
    name: "user-insurer-correct_service-info"
  }, {
    path: "/user/insurer/correct_service/list",
    component: _03ff8371,
    name: "user-insurer-correct_service-list"
  }, {
    path: "/user/insurer/invoice_manage/info",
    component: _56826458,
    name: "user-insurer-invoice_manage-info"
  }, {
    path: "/user/insurer/invoice_manage/list",
    component: _bfecf770,
    name: "user-insurer-invoice_manage-list"
  }, {
    path: "/user/insurer/order/detail",
    component: _a0cbb214,
    name: "user-insurer-order-detail"
  }, {
    path: "/user/insurer/order/send_mail",
    component: _575e4c9f,
    name: "user-insurer-order-send_mail"
  }, {
    path: "/user/insurer/order/setting",
    component: _3c1e5c3e,
    name: "user-insurer-order-setting"
  }, {
    path: "/user/insurer/order/supplement_audit",
    component: _11d0240c,
    name: "user-insurer-order-supplement_audit"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/reinsure",
    component: _f73eacb2,
    name: "reinsure"
  }, {
    path: "*",
    component: _631ba4bc
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
