//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    PERSONAL_CENTER_MY_GB_MENU_CODE,
    ACCOUNT_SET_MY_ACCOUNT_MENU_CODE,
    MESSAGE_CENTER_MENU_CODE,
    ACCOUNT_SET_MY_ACCOUNT_INFO_MENU_CODE,
    ACCOUNT_SET_MY_ACCOUNT_SECURITY_MENU_CODE,
    ACCOUNT_SET_MY_ACCOUNT_INVOICE_MENU_CODE,
    ACCOUNT_SET_MY_ACCOUNT_ADDRESS_MENU_CODE
} from '@/constant/menuEnum'
export default {
    name: 'TabPanelUser',
    components: {},
    data() {
        return {
            tabList: [
                {
                    name: '我的工保',
                    linkName: 'user',
                    inCurTabLinkNameList: ['user'],
                    show: true,
                    code: PERSONAL_CENTER_MY_GB_MENU_CODE
                }, {
                    name: '账户设置',
                    linkName: 'set_account-user_info',
                    inCurTabLinkNameList: [
                        'set_account-user_info',
                        'set_account-receive_address',
                        'set_account-invoice_title',
                        'set_account-account_safe',
                        'set_account-account_safe-update_mobile',
                        'set_account-account_safe-update_password',
                        'set_account-account_safe-log_off'
                    ],
                    show: true,
                    code: ACCOUNT_SET_MY_ACCOUNT_MENU_CODE
                }, {
                    name: '消息中心',
                    linkName: 'message',
                    inCurTabLinkNameList: ['message'],
                    show: true,
                    code: MESSAGE_CENTER_MENU_CODE
                }
            ],
            tabListNew: [],
            activeIndex: null,
            // 账户设置跳转路由
            accountName: null
        }
    },
    computed: {
        role() {
            return this.$store.getters.role
        },

        buttonRole() {
            return this.$store.getters.buttonRole
        }
    },
    watch: {
        '$route.name': {
            handler(newVal, val) {
                let curTabIndex = 0
                this.handleIsShow()
                this.getAccountName()
                this.tabListNew.forEach((item, index) => {
                    // 当前 route.name 在 inCurTabLinkNameList 中，则选择当前tab高亮
                    if (item.inCurTabLinkNameList.indexOf(newVal) > -1) {
                        curTabIndex = index
                    }
                })
                this.activeIndex = curTabIndex
            },

            immediate: true
        }
    },
    mounted() {
        this.handleIsShow()
        this.getAccountName()
    },
    methods: {
        /**
         * 获取账户设置的linkName
         */
        getAccountName() {
            let { role } = this
            let menuPermission = Object.keys(role)
            let side = [
                {
                    name: 'set_account-receive_address',
                    code: ACCOUNT_SET_MY_ACCOUNT_ADDRESS_MENU_CODE
                },
                {
                    name: 'set_account-invoice_title',
                    code: ACCOUNT_SET_MY_ACCOUNT_INVOICE_MENU_CODE
                },
                {
                    name: 'set_account-account_safe',
                    code: ACCOUNT_SET_MY_ACCOUNT_SECURITY_MENU_CODE
                },
                {
                    name: 'set_account-user_info',
                    code: ACCOUNT_SET_MY_ACCOUNT_INFO_MENU_CODE
                }
            ]
            side.forEach(item => {
                if (menuPermission.indexOf(item.code) !== -1) {
                    this.accountName = item.name
                }
            })
            this.tabListNew.forEach(item => {
                if (item.name === '账户设置') {
                    item.linkName = this.accountName
                }
            })
        },
        /**
         * 判断菜单是否显示
         */
        handleIsShow() {
            let { tabList, role } = this
            let menuPermission = Object.keys(role)
            menuPermission.push(PERSONAL_CENTER_MY_GB_MENU_CODE)
            tabList.forEach(item => {
                if (menuPermission.indexOf(item.code) === -1) {
                    item.show = false
                }
            })
            this.tabListNew = tabList.filter(item => item.show) || []
        },
        handleTabChange(index) {
            this.activeIndex = index
        },
        handleLinkName(linkName) {
            if (linkName) {
                this.$router.push({
                    name: linkName
                })
            }
        }
    }
}
