/**
 * 格式化 权限为对象
 * @param permissions
 * @returns {{}}
 */
export function getRule(permissions) {
    permissions = permissions || []
    let obj = {}
    permissions.forEach(item => {
        const { value } = item
        obj[value] = item
    })
    return obj
}

/**
 * 判断菜单是否显示
 * @param permissions
 * @returns {{}}
 */
export function getShowMenu(menu, role) {
    const menuShow = Object.keys(role)
    menu.forEach(item => {
        item.hidden = false
        if (menuShow.indexOf(item.code) === -1) {
            item.hidden = true
        }
        if (item.treeList) {
            getShowMenu(item.treeList, role)
        }
    })
}
