//
//
//
//
//
//

export default {
    name: 'BasicLayout'
}
