/**
 * base module
 * @type {string}
 */

export const SET_TOKEN = 'SET_TOKEN'

export const SET_CRM_TOKEN = 'SET_CRM_TOKEN'

export const SET_USER_INFO = 'SET_USER_INFO'

export const SET_ROLE = 'SET_ROLE'

export const SET_BUTTON_ROLE = 'SET_BUTTON_ROLE'

export const SET_BREADCRUMB = 'SET_BREADCRUMB'

export const SET_BREADCRUMB_BY_NAME = 'SET_BREADCRUMB_BY_NAME'

export const SET_BREADCRUMB_BY_PATH = 'SET_BREADCRUMB_BY_PATH'

export const SET_INSURE_STEP_CURRENT = 'SET_INSURE_STEP_CURRENT'

export const SET_SIDE_MENU = 'SET_SIDE_MENU'

export const SHOW_INVITE_BROKER = 'SHOW_INVITE_BROKER'

export const SET_PRODUCT_INSURANCE_COUNT = 'SET_PRODUCT_INSURANCE_COUNT'

export const SET_AGENT_INSURANCE_COUNT = 'SET_AGENT_INSURANCE_COUNT'

export const SET_BUSINESS_DETAILS = 'SET_BUSINESS_DETAILS'
