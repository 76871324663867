/**
 * 保险端-点击可跳转的页面 （无投保页面）
*/
import {
    // 购物车
    SHOPPING_CART_LIST_PAGE_CODE,
    SHOPPING_CART_SETTLE_PAGE_CODE,

    // 支付
    IMMEDIATE_PAYMENT_PAGE_CODE,
    PAYMENT_PAID_PAGE_CODE,
    PAYMENT_COMPLETED_PAGE_CODE,
    PAYMENT_SUCCESS_PAGE_CODE,

    // 消息中心
    MESSAGE_CENTER_LIST_PAGE_CODE,

    // 账户设置/我的账户
    ACCOUNT_SET_PERSONAL_INFO_PAGE_CODE,
    ACCOUNT_SECURITY_PAGE_CODE,
    ACCOUNT_SECURITY_UPDATE_PASSWORD_PAGE_CODE,
    ACCOUNT_SECURITY_UPDATE_MOBILE_PAGE_CODE,
    ACCOUNT_SECURITY_LOG_OFF_PAGE_CODE,
    INVOICE_HEADER_PAGE_CODE,
    RECEIVING_ADDRESS_PAGE_CODE,

    // 我的工保
    PERSONAL_GB_PAGE_CODE,

    // 订单管理
    INSURANCE_ORDER_LIST_PAGE_CODE,
    INSURANCE_ORDER_DETAIL_PAGE_CODE,
    INSURANCE_SETTING_ABOUT_PAGE_CODE,
    INSURANCE_INSURER_AUDIT_PAGE_CODE,
    INSURANCE_SUPPLEMENT_AUDIT_PAGE_CODE,
    INSURANCE_INVOICE_SEND_MAIL_PAGE_CODE,

    // 客户服务
    INSURANCE_INVOICE_LIST_PAGE_CODE,
    INSURANCE_INVOICE_DETAIL_PAGE_CODE,
    INSURANCE_CORRECTION_LIST_PAGE_CODE,
    INSURANCE_CORRECTION_DETAIL_PAGE_CODE,
    DATA_BASE_LIST_PAGE_CODE
} from '@/constant/pageEnum/insurer'
const insurerRouter = [
    /**
     * 购物车
    */
    // 购物车-列表
    {
        path: '/cart',
        code: SHOPPING_CART_LIST_PAGE_CODE,
        name: 'cart'
    },
    // 购物车-去结算
    {
        path: '/cart/confirm_pay',
        code: SHOPPING_CART_SETTLE_PAGE_CODE,
        name: 'cart-confirm_pay'
    },

    /**
     * 支付
     */
    // 立即支付
    {
        path: '/cashier',
        code: IMMEDIATE_PAYMENT_PAGE_CODE,
        name: 'cashier'
    },
    // 已支付
    {
        path: '/cashier/paid',
        code: PAYMENT_PAID_PAGE_CODE,
        name: 'cashier-paid'
    },
    // 支付完成
    {
        path: '/cashier/pay_completed',
        code: PAYMENT_COMPLETED_PAGE_CODE,
        name: 'cashier-pay_completed'
    },
    // 支付成功
    {
        path: '/cashier/pay_success',
        code: PAYMENT_SUCCESS_PAGE_CODE,
        name: 'cashier-pay_success'
    },

    /**
     * 消息中心
     */
    // 消息中心-列表
    {
        path: '/message',
        code: MESSAGE_CENTER_LIST_PAGE_CODE,
        name: 'message'
    },

    /**
     * 账户设置/我的账户
     */
    // 账户设置-个人信息
    {
        path: '/set_account/user_info',
        code: ACCOUNT_SET_PERSONAL_INFO_PAGE_CODE,
        name: 'set_account-user_info'
    },
    // 账户设置-账户安全
    {
        path: '/set_account/account_safe',
        code: ACCOUNT_SECURITY_PAGE_CODE,
        name: 'set_account-account_safe'
    },
    // 账户设置-账户安全-修改密码
    {
        path: '/set_account/account_safe/update_password',
        code: ACCOUNT_SECURITY_UPDATE_PASSWORD_PAGE_CODE,
        name: 'set_account-account_safe-update_password'
    },
    // 账户设置-账户安全-修改手机号
    {
        path: '/set_account/account_safe/update_mobile',
        code: ACCOUNT_SECURITY_UPDATE_MOBILE_PAGE_CODE,
        name: 'set_account-account_safe-update_mobile'
    },
    // 账户设置-账户安全-账户注销
    {
        path: '/set_account/account_safe/log_off',
        code: ACCOUNT_SECURITY_LOG_OFF_PAGE_CODE,
        name: 'set_account-account_safe-log_off'
    },
    // 账户设置-发票抬头
    {
        path: '/set_account/invoice_title',
        code: INVOICE_HEADER_PAGE_CODE,
        name: 'set_account-invoice_title'
    },
    // 账户设置-收件地址
    {
        path: '/set_account/receive_address',
        code: RECEIVING_ADDRESS_PAGE_CODE,
        name: 'set_account-receive_address'
    },

    /**
     * 我的工保
     */
    // {
    //     path: "/user",
    //     code: PERSONAL_GB_PAGE_CODE,
    //     name: "user"
    // },

    /**
    * 订单管理
    */
    // 投保订单-列表
    {
        path: '/user/insurer/order',
        code: INSURANCE_ORDER_LIST_PAGE_CODE,
        name: 'user-insurer-order'
    },
    // 投保订单-订单详情
    {
        path: '/user/insurer/order/detail',
        code: INSURANCE_ORDER_DETAIL_PAGE_CODE,
        name: 'user-insurer-order-detail'
    },
    // 配置保单信息（已完成文字为更新保单信息）/配置支付方式
    {
        path: '/user/insurer/order/setting',
        code: INSURANCE_SETTING_ABOUT_PAGE_CODE,
        name: 'user-insurer-order-setting'
    },
    // 保险审核
    {
        path: '/user/insurer/audit',
        code: INSURANCE_INSURER_AUDIT_PAGE_CODE,
        name: 'user-insurer-audit'
    },
    // 补充资料审核
    {
        path: '/user/insurer/order/supplement_audit',
        code: INSURANCE_SUPPLEMENT_AUDIT_PAGE_CODE,
        name: 'user-insurer-order-supplement_audit'
    },
    // 投保订单-邮件发送 //TODO CODE
    {
        path: '/user/insurer/order/send_mail',
        code: INSURANCE_ORDER_LIST_PAGE_CODE,
        name: 'user-insurer-order-send_mail'
    },

    /**
     * 客户服务
    */
    // 发票管理-发票列表
    {
        path: '/user/insurer/invoice_manage/list',
        code: INSURANCE_INVOICE_LIST_PAGE_CODE,
        name: 'user-insurer-invoice_manage-list'
    },
    // 发票管理-发票详情
    {
        path: '/user/insurer/invoice_manage/info',
        code: INSURANCE_INVOICE_DETAIL_PAGE_CODE,
        name: 'user-insurer-invoice_manage-info'
    },
    // 批改服务-批改列表
    {
        path: '/user/insurer/correct_service/list',
        code: INSURANCE_CORRECTION_LIST_PAGE_CODE,
        name: 'user-insurer-correct_service-list'
    },
    // 批改服务-批改详情
    {
        path: '/user/insurer/correct_service/info',
        code: INSURANCE_CORRECTION_DETAIL_PAGE_CODE,
        name: 'user-insurer-correct_service-info'
    },

    /**
     * 资料库
     */
    // 资料库-资料列表-列表
    {
        path: '/data_base/list',
        code: DATA_BASE_LIST_PAGE_CODE,
        name: 'data_base-list'
    }

]

export default insurerRouter
