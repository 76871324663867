//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ConfigProvider as ALocaleProvider } from 'ant-design-vue'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import TopBar from '@/components/GlobalTopBar'
import InsureNav from '@/components/GlobalInsureBar'
import GlobalFooter from '@/components/GlobarFooter'
moment.locale('zh-cn')
export default {
    name: 'InsureLayout',
    components: {
        TopBar,
        GlobalFooter,
        ALocaleProvider,
        InsureNav
    },
    data() {
        return {
            zh_CN
        }
    }
}
