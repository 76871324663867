/**
 * 响应成功
 */
export const HTTP_ERR_SUCCESS = '0'
export const HTTP_CMS_ERR_SUCCESS = 1

/**
 * 返回结果字段
 * @type {string}
 */
export const HTTP_RESULT_FIELD = 'obj'

/**
 * 错误信息字段
 * @type {string}
 */
export const HTTP_ERR_MESSAGE_FIELD = 'errorMessage'

/**
 * 请求失败
 * @type {string}
 */
export const HTTP_ERR_FAIL = '0'

/**
 * 授权失效
 */
export const HTTP_ERR_UNAUTHORIZED = 401

