import { BASE_URL } from '@/config'

/** 客户服务——投保咨询
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 投保咨询订单列表
 *
 */
export const CUSTOMER_INSURANCE_LIST = {
    url: `${BASE_URL}/customer/potential-customer/select`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 投保咨询订单详情
 *
 */
export const CUSTOMER_INSURANCE_DETAIL = {
    url: `${BASE_URL}/customer/potential-customer/selectOne`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 投保咨询取消咨询
 *
 */
export const CUSTOMER_INSURANCE_CANCEL_CONSULTING = {
    url: `${BASE_URL}/customer/potential-customer/cancelConsulting`,
    method: 'put',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 投保咨询关闭咨询
 *
 */
export const CUSTOMER_INSURANCE_CLOSE_CONSULTING = {
    url: `${BASE_URL}/customer/potential-customer/closeConsulting`,
    method: 'put',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 投保咨询完成咨询
 *
 */
export const CUSTOMER_INSURANCE_COMPLETE_CONSULTING = {
    url: `${BASE_URL}/customer/potential-customer/completeConsulting`,
    method: 'put',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 总数查询--投保咨询/经纪人咨询
 *
 */
export const CUSTOMER_INSURANCE_COUNT = {
    url: `${BASE_URL}/customer/potential-customer/count`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}
