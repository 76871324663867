//
//
//
//
//
//
//
//
//

import LeftBar from './LeftBar'
import RightBar from './RightBar'

export default {
    name: 'CrmGlobalHeader',
    components: {
        LeftBar,
        RightBar
    },
    computed: {
        // 判断是否是crm分享链接 产品详情页不显示左侧用户信息
        isCrmAndProductPage() {
            return this.$route.path === '/product/info' || this.$route.path === '/cart' ? true : false
        }
    }
}
