//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Breadcrumb as ABreadcrumb,
    Divider as ADivider
} from 'ant-design-vue'
import CustomBreadcrumb from '@/components/Breadcrumb'

import {
    SERVICE_TEL,
    CNGB_URL,
    CNGB_URL_NEW,
    AN_BEI_URL,
    ICP_URL
} from '@/config'

export default {
    name: 'GlobarFooter',
    components: {
        ABreadcrumb,
        ADivider,
        CustomBreadcrumb,
        ABreadcrumbItem: ABreadcrumb.Item
    },
    data() {
        return {
            serviceTel: SERVICE_TEL,
            // 关于我们 url
            cngbUrl: CNGB_URL,
            // 浙公网安备 url
            anBeiUrl: AN_BEI_URL,
            // 浙ICP url
            ICPUrl: ICP_URL,
            // 网站导航1
            navigationList: [
                {
                    name: '首页',
                    linkName: 'index'
                },
                {
                    name: '保险产品',
                    linkName: 'product'
                },
                {
                    name: '保险方案',
                    linkName: 'quote'
                },
                {
                    name: '年薪百万',
                    linkName: 'salary_million'
                }
            ],
            // 网站导航2
            navigationIndexList: [
                {
                    name: '工保百科',
                    linkName: 'wiki'
                }, {
                    name: '新闻资讯',
                    linkName: 'news-list'
                }
            ],
            // 友情链接
            linkList: [
                {
                    name: '工保科技',
                    linkUrl: CNGB_URL_NEW
                }
            ]
        }
    },
    computed: {
        breadcrumbData() {
            return this.$store.state.breadcrumbData
        }
    },
    methods: {
        // 网站导航跳转
        handleLinkName(linkName) {
            if (linkName) {
                this.$router.push({
                    name: linkName
                })
            }
        }
    }
}
