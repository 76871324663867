//
//
//
//
//
//
//
//
//
//
//
//
//

import { SERVICE_TEL } from '@/config'
export default {
    name: 'LeftBar',
    data() {
        return {
            serviceTel: SERVICE_TEL
        }
    },
    computed: {
        homeAddress() {
            return this.$route?.query.agentUserId ? 'javascript:void(0);' : '/'
        }
    }
}
