export default class Middleware {
    constructor() {
        this.middlewares = []
    }

    use(fn) {
        if (typeof fn !== 'function') {
            throw new Error(`Middleware must be function, but get ${typeof fn}`)
        }
        this.middlewares.push(fn)
        return this
    }


    compose(data) {
        const { middlewares } = this
        const ctx = {
            data: data || {}
        }

        function dispatch(index) {
            const middleware = middlewares[index]
            if (!middleware) {
                return
            }
            try {
                // 修复 ie 下 bind 报错问题
                // ()=>{dispatch(index+1)}
                const result = middleware(ctx, () => {
                    dispatch(index + 1)
                })
                return Promise.resolve(result)
            } catch (e) {
                return Promise.reject(e)
            }
        }

        return dispatch(0)
    }
}
