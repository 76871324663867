//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Breadcrumb as ABreadcrumb } from 'ant-design-vue'

import { isUndefined } from '@/utils'

export default {
    name: 'Breadcrumb',
    components: {
        ABreadcrumb,
        ABreadcrumbItem: ABreadcrumb.Item
    },
    data() {
        return {}
    },
    computed: {
        breadcrumbData() {
            return this.$store.state.breadcrumbData
        }
    },
    methods: {
        // 获取 isShow 状态值
        getIsShowState({ isShow }) {
            return isUndefined(isShow) || isShow
        },
        // 获取可点击跳转的面包屑项的目标地址
        getTargetHref(path = '') {
            return path
                .split('/')
                .map(item => item.startsWith('_')
                    ? this.$route.params[item.substring(1)]
                    : item)
                .join('/')
        }
    }
}
