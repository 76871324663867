/**
 * vuex 全局实例hook
 * @type {null}
 */
// eslint-disable-next-line import/no-mutable-exports
let store = null

export default function (app) {
    store = app.store
}

export { store }
