/**
 * 面包屑配置说明
 * [
 *     {
 *         name: String, // 面包屑名称
 *         path: String, // 面包屑对应路径
 *         clickable: Boolean // 是否可点击跳转
 *         isShow: Boolean // 是否显示
 *     }
 * ]
 */

// 购物车
const CART = [
    {
        name: '购物车',
        path: '/cart'
    }, {
        name: '确认支付',
        path: '/cart/confirm_pay'
    }
]
// 投保单/反担保
const CAST_GUARANTEE = [
    {
        name: '投保单/反担保',
        path: '/signature'
    }
]
// 收银台
const CASHIER = [
    {
        name: '收银台',
        path: '/cashier'
    }, {
        name: '支付完成',
        path: '/cashier/pay_completed'
    }, {
        name: '支付成功',
        path: '/cashier/pay_success'
    }
]

// 保险产品
const PRODUCT = [
    {
        name: '推荐产品',
        path: '/product'
    }, {
        name: '产品详情',
        path: '/product/info'
    }
]

// 新闻资讯
const NEWS = [
    {
        name: '新闻资讯',
        path: '/news'
    }, {
        name: '',
        path: '/news/list',
        isShow: false
    }, {
        name: '资讯内容',
        path: '/news/detail'
    }
]


// 订单管理
const ORDER_MANAGE = [
    {
        name: '订单管理',
        path: '/order'
    },
    {
        name: '订单详情',
        path: '/order/detail'
    },
    {
        name: '全部订单',
        path: '/order/list'
    },
    {
        name: '申请退保',
        path: '/order/surrender'
    },
    {
        name: '订单评价',
        path: '/order/evaluation'
    },
    {
        name: '邮件发送',
        path: '/user/insurer/order/send_mail'
    }
]

// 批改服务
const CORRECT_SERVICE = [
    // 经纪人端
    {
        name: '',
        path: '/user/broker/correct_service',
        isShow: false
    },
    {
        name: '批改服务',
        path: '/user/broker/correct_service/list'
    },
    {
        name: '服务单详情',
        path: '/user/broker/correct_service/info'
    },

    // 保险端
    {
        name: '',
        path: '/user/insurer/correct_service',
        isShow: false
    },
    {
        name: '批改服务',
        path: '/user/insurer/correct_service/list'
    },
    {
        name: '服务单详情',
        path: '/user/insurer/correct_service/info'
    },

    // 用户端
    {
        name: '',
        path: '/user/insured/correct_service',
        isShow: false
    },
    {
        name: '批改服务',
        path: '/user/insured/correct_service/list'
    },
    {
        name: '服务单详情',
        path: '/user/insured/correct_service/info'
    }
]

// 经纪人咨询
const SERVICE_BROKER = [
    {
        name: '经纪人咨询',
        path: '/service_broker'
    }, {
        name: '咨询详情',
        path: '/service_broker/detail'
    }, {
        name: '经纪人咨询',
        path: '/user/broker/service_broker'
    }
]

// 投保
const INSURE = [
    {
        name: '填写投保信息',
        path: '/insure'
    },
    {
        name: '确认投保信息',
        path: '/insure/confirm'
    },
    {
        name: '提示',
        path: '/insure/message'
    },
    {
        name: '提示',
        path: '/insure/submit_message'
    },

    // 投保审核
    {
        name: '投保详情',
        path: '/insure/audit'
    },
    {
        name: '投保详情',
        path: '/user/insurer/audit'
    },
    {
        name: '补充资料审核',
        path: '/user/insurer/order/supplement_audit'
    },

    // 投保订单
    {
        name: '投保订单',
        path: '/user/insurer/order'
    },
    {
        name: '订单详情',
        path: '/user/insurer/order/detail'
    },
    {
        name: '投保订单配置',
        path: '/user/insurer/order/setting'
    }
]

// 投保咨询
const SERVICE_INSURE = [
    {
        name: '投保咨询',
        path: '/service_insure'
    }, {
        name: '服务单',
        path: '/service_insure/detail'
    }, {
        name: '投保咨询',
        path: '/user/broker/service_insure'
    }
]

// 理赔服务
const CLAIM_SERVICE = [
    {
        name: '理赔服务',
        path: '/claim_service'
    }, {
        name: '',
        path: '/claim_service/list',
        isShow: false
    }, {
        name: '服务单',
        path: '/claim_service/detail'
    }
]

// 报价工具
const QUOTATION_TOOL = [
    {
        name: '报价工具',
        path: '/user/broker/quotation_tool'
    }
]

// 发票管理
const INVOICE_MANAGE = [
    // 保险端
    {
        name: '',
        path: '/user/insurer/invoice_manage',
        isShow: false
    },
    {
        name: '发票管理',
        path: '/user/insurer/invoice_manage/list'
    },
    {
        name: '发票详情',
        path: '/user/insurer/invoice_manage/info'
    },
    // 用户端/经纪端
    {
        name: '',
        path: '/user/insured/invoice_manage',
        isShow: false
    },
    {
        name: '发票管理',
        path: '/user/insured/invoice_manage/list'
    },
    {
        name: '发票申请',
        path: '/user/insured/invoice_manage/apply_invoice'
    },
    {
        name: '发票详情',
        path: '/user/insured/invoice_manage/info'
    }
]

// 保单查询
const POLICY = [
    {
        name: '',
        path: '/policy',
        isShow: false
    }, {
        name: '保单查询',
        path: '/policy/list'
    }
]

// 邀请用户
const INVITATION_USERS = [
    {
        name: '客户服务',
        path: '/user/broker',
        isShow: false
    }, {
        name: '邀请用户',
        path: '/user/broker/invite_users'
    }
]
// 推广获客
const EXTEND_CUSTOMER = [
    {
        name: '客户服务',
        path: '/user/broker/promote_guest',
        isShow: false
    }, {
        name: '推广获客',
        path: '/user/broker/promote_guest/list'
    }
]

// 邀请经纪人
const INVITATION_BROKERS = [
    {
        name: '客户服务',
        path: '/user/broker',
        isShow: false
    }, {
        name: '邀请经纪人',
        path: '/user/broker/invite_brokers'
    }
]

// 账户设置
const SET_ACCOUNT = [
    {
        name: '个人中心',
        path: '/set_account'
    },
    {
        name: '账号安全',
        path: '/set_account/account_safe'
    },
    {
        name: '发票抬头',
        path: '/set_account/invoice_title'
    },
    {
        name: '收件地址',
        path: '/set_account/receive_address'
    },
    {
        name: '个人信息',
        path: '/set_account/user_info'
    },
    {
        name: '修改手机号',
        path: '/set_account/account_safe/update_mobile'
    },
    {
        name: '修改登录密码',
        path: '/set_account/account_safe/update_password'
    },
    {
        name: '账户注销',
        path: '/set_account/account_safe/log_off'
    }
]

// 条款协议
const TERMS_AGREEMENT = [
    {
        name: '条款协议',
        path: '/terms_agreement',
        isShow: false
    }, {
        name: '隐私政策条款',
        path: '/terms_agreement/privacy_policy_clause'
    }, {
        name: '工保网平台服务协议',
        path: '/terms_agreement/service_agreement'
    }
]
// 资料库
const DATA_FILE_BASE = [
    {
        name: '资料库',
        path: '/data_base'
    }, {
        name: '资料列表',
        path: '/data_base/list'
    }
]

export default [
    {
        name: '首页',
        path: '/'
    },
    {
        name: '登录入口',
        path: '/login'
    },

    {
        name: '新用户注册',
        path: '/register'
    },

    {
        name: '企业信息',
        path: '/companyInfo'
    },

    {
        name: '工保百科',
        path: '/wiki'
    },

    {
        name: '消息中心',
        path: '/message'
    },

    // {
    //     name: '保险方案',
    //     path: '/quote'
    // },

    {
        name: '企业服务',
        path: '/enterprise_service'
    },

    {
        name: '年薪百万',
        path: '/salary_million'
    },

    {
        name: '信息披露',
        path: '/info_disclosure'
    },

    {
        name: '我的工保',
        path: '/user',
        isShow: false
    },
    // 经纪人端
    {
        name: '',
        path: '/user/broker',
        isShow: false
    },
    // 保险端
    {
        name: '',
        path: '/user/insurer',
        isShow: false
    },
    // 用户端
    {
        name: '',
        path: '/user/insured',
        isShow: false
    },
    // 智核问卷
    {
        name: '智核问卷',
        path: '/questionnaire'
    },

    ...CART,
    ...CAST_GUARANTEE,

    ...CASHIER,

    ...PRODUCT,

    ...NEWS,

    ...ORDER_MANAGE,

    ...CORRECT_SERVICE,

    ...SERVICE_BROKER,

    ...INSURE,

    ...SERVICE_INSURE,

    ...CLAIM_SERVICE,

    ...QUOTATION_TOOL,

    ...INVOICE_MANAGE,

    ...POLICY,

    ...INVITATION_USERS,

    ...INVITATION_BROKERS,

    ...SET_ACCOUNT,

    ...TERMS_AGREEMENT,

    ...DATA_FILE_BASE,

    ...EXTEND_CUSTOMER
]
