import Vue from 'vue'
import { message, Modal, notification } from 'ant-design-vue'


// 注册 鉴权指令
Vue.use(Modal)

Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm
