import { getBrowser } from '@/utils/browser'
import redirects from '@/config/redirects'


export default function ({ redirect, route, req }) {
    // 获取浏览器相关信息
    const browser = getBrowser(req ? req.headers['user-agent'] : null)
    // 判断是否为重定向地址
    const red = redirects.find(r => r.from === route.path && (!r.is || browser.versions[r.is]))

    if (red) {
        return redirect(302, red.to)
    }
}
