//
//
//
//
//
//
//
//
//
//
//
//
//

import LeftPanel from './LeftPanel'
import TabPanel from './TabPanel'
import SearchPanel from './SearchPanel'
import TabBar from './TabBar'
export default {
    name: 'GlobalNavBar',
    components: {
        LeftPanel,
        TabPanel,
        SearchPanel,
        TabBar
    }
}
