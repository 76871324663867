/**
 * 菜单权限code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
*/
// 购物车
export const SHOPPING_CART_MENU_CODE = 'SHOPPING_CART'
// 个人中心
export const PERSONAL_CENTER_MENU_CODE = 'PERSONAL_CENTER'
// 个人中心/我的工保
export const PERSONAL_CENTER_MY_GB_MENU_CODE = 'PERSONAL_CENTER_MY_GN'
// 个人中心/账户设置/我的账户
export const ACCOUNT_SET_MY_ACCOUNT_MENU_CODE = 'ACCOUNT_SET_MY_ACCOUNT'
// 个人中心/账户设置/我的账户-个人信息
export const ACCOUNT_SET_MY_ACCOUNT_INFO_MENU_CODE = 'ACCOUNT_SET_MY_ACCOUNT_INFO'
// 个人中心/账户设置/我的账户-账户安全
export const ACCOUNT_SET_MY_ACCOUNT_SECURITY_MENU_CODE = 'ACCOUNT_SET_MY_ACCOUNT_SECURITY'
// 个人中心/账户设置/我的账户-发票抬头
export const ACCOUNT_SET_MY_ACCOUNT_INVOICE_MENU_CODE = 'ACCOUNT_SET_MY_ACCOUNT_INVOICE'
// 个人中心/账户设置/我的账户-收件地址
export const ACCOUNT_SET_MY_ACCOUNT_ADDRESS_MENU_CODE = 'ACCOUNT_SET_MY_ACCOUNT_ADDRESS'

// 个人中心/消息中心
export const MESSAGE_CENTER_MENU_CODE = 'MESSAGE_CENTER'

/**
 * 用户端
 */
// 订单管理
export const USER_ORDER_MANAGE_MENU_CODE = 'USER_ORDER_MANAGE'
// 订单管理-全部订单
export const USER_ORDER_MANAGE_ALL_ORDER_MENU_CODE = 'USER_ORDER_MANAGE_ALL_ORDER'
// 客户服务
export const USER_CUSTOMER_SERVICE_MENU_CODE = 'USER_CUSTOMER_SERVICE'
// 客户服务-投保咨询
export const USER_CUSTOMER_SERVICE_INSURANCE_MENU_CODE = 'USER_CUSTOMER_SERVICE_INSURANCE'
// 客户服务-经纪人咨询
export const USER_CUSTOMER_SERVICE_BROKER_MENU_CODE = 'USER_CUSTOMER_SERVICE_BROKER'
// 客户服务-发票管理
export const USER_CUSTOMER_SERVICE_INVOICE_MENU_CODE = 'USER_CUSTOMER_SERVICE_INVOICE'
// 客户服务-批改服务
export const USER_CUSTOMER_SERVICE_CORRECT_MENU_CODE = 'USER_CUSTOMER_SERVICE_CORRECT'
// 客户服务-保单查询
export const USER_CUSTOMER_SERVICE_POLICY_MENU_CODE = 'USER_CUSTOMER_SERVICE_POLICY'
// 资料库
export const USER_BASE_DATA_MENU_CODE = 'USER_BASE_DATA'
// 资料列表
export const USER_BASE_DATA_LIST_MENU_CODE = 'USER_BASE_DATA_LIST'

/**
 * 经纪端
 */
// 订单管理
export const BROKER_ORDER_MANAGE_MENU_CODE = 'BROKER_ORDER_MANAGE'
// 订单管理-全部订单
export const BROKER_ORDER_MANAGE_ALL_ORDER_MENU_CODE = 'BROKER_ORDER_MANAGE_ALL_ORDER'
// 客户服务
export const BROKER_CUSTOMER_SERVICE_MENU_CODE = 'BROKER_CUSTOMER_SERVICE'
// 客户服务-投保咨询
export const BROKER_CUSTOMER_SERVICE_INSURANCE_MENU_CODE = 'BROKER_CUSTOMER_SERVICE_INSURANCE'
// 客户服务-经纪人咨询
export const BROKER_CUSTOMER_SERVICE_BROKER_MENU_CODE = 'BROKER_CUSTOMER_SERVICE_BROKER'
// 客户服务-发票管理
export const BROKER_CUSTOMER_SERVICE_INVOICE_MENU_CODE = 'BROKER_CUSTOMER_SERVICE_INVOICE'
// 客户服务-批改服务
export const BROKER_CUSTOMER_SERVICE_CORRECT_MENU_CODE = 'BROKER_CUSTOMER_SERVICE_CORRECT'
// 客户服务-保单查询
export const BROKER_CUSTOMER_SERVICE_POLICY_MENU_CODE = 'BROKER_CUSTOMER_SERVICE_POLICY'
// 客户服务-邀请用户
export const BROKER_CUSTOMER_SERVICE_INVITE_USER_MENU_CODE = 'BROKER_CUSTOMER_SERVICE_INVITE'
// 客户服务-推广获客
export const BROKER_CUSTOMER_SERVICE_PROMOTE_GUEST_MENU_CODE = 'BROKER_CUSTOMER_SERVICE_PROMOTE_GUEST'
// 客户服务-邀请经纪人
export const BROKER_CUSTOMER_SERVICE_INVITE_BROKER_MENU_CODE = 'BROKER_CUSTOMER_SERVICE_INVITE_BROKER'
// 报价工具
export const BROKER_QUOTATION_MENU_CODE = 'BROKER_QUOTATION'
// 报价工具
export const BROKER_QUOTATION_TOOL_MENU_CODE = 'BROKER_QUOTATION_TOOL'
// 资料库
export const BROKER_BASE_DATA_MENU_CODE = 'BROKER_BASE_DATA'
// 资料列表
export const BROKER_BASE_DATA_LIST_MENU_CODE = 'BROKER_BASE_DATA_LIST'
/**
 * 保险端
 */
// 订单管理
export const INSURER_ORDER_MANAGE_MENU_CODE = 'INSURER_ORDER_MANAGE'
// 订单管理-投保订单
export const INSURER_ORDER_MANAGE_INSURE_ORDER_MENU_CODE = 'INSURER_ORDER_MANAGE_INSURE_ORDER'
// 客户服务
export const INSURER_CUSTOMER_SERVICE_MENU_CODE = 'INSURER_CUSTOMER_SERVICE'
// 客户服务-发票管理
export const INSURER_CUSTOMER_SERVICE_INVOICE_MENU_CODE = 'INSURER_CUSTOMER_SERVICE_INVOICE'
// 客户服务-批改服务
export const INSURER_CUSTOMER_SERVICE_CORRECT_MENU_CODE = 'INSURER_CUSTOMER_SERVICE_CORRECT'
// 资料库
export const INSURER_BASE_DATA_MENU_CODE = 'INSURER_BASE_DATA'
// 资料列表
export const INSURER_BASE_DATA_LIST_MENU_CODE = 'INSURER_BASE_DATA_LIST'


