import {
    API_VERSION,
    LOGIN_SOURCE_CODE,
    LOGIN_SOURCE_VAL_CODE,
    LOGIN_APP_CODE_AGENT,
    LOGIN_APP_CODE_USER,
    REGISTER_BUSINESS_DETAILS_CODE,
    INFO_SOURCE_VAL_CODE
} from '@/config'
import { getToken, removeToken, getCRMToken } from '@/utils/auth'
import {
    ROLE_CODE_USER,
    ROLE_CODE_BROKER
} from '@/constant/enum'
import { isCRM } from '@/utils/system'

let axios = null

/**
 * 登出
 */
function logout() {
    removeToken()
}

/**
 * 请求头-添加appCode
 */
function requestHeaderParams(config) {
    if (config.url === '/api/user/sendCode') {

        // 2.1 获取验证码
        config.headers['appCode'] = 'NET-AGENT'
    } else if (config.url === '/api/user/userRegister') {

        // 2.2 注册
        const { params } = config
        if (params) {
            const { roleCode } = params
            let appCode = ''
            switch (roleCode) {
                // 普通用户
                case ROLE_CODE_USER : {
                    appCode = LOGIN_APP_CODE_USER
                    break
                }
                // 经纪人
                case ROLE_CODE_BROKER : {
                    appCode = LOGIN_APP_CODE_AGENT
                    break
                }
            }
            // config.headers['appCode'] = appCode
        }
    }
}

/**
 * 根据所在项目获取对应token
 * @params app
 */
function getTokenByCRM(app, store) {
    const redirectString = app?.router?.history?.current?.query?.redirect || ''
    const query = app?.router?.history?.current?.query || {}
    let dataType = ''
    let data = null
    if (redirectString) {
        dataType = 'string'
        data = redirectString
    } else {
        dataType = 'object'
        data = query
    }
    const token = isCRM(dataType, data) ? getCRMToken() : getToken()
    if (isCRM(dataType, data)) {
        // CRM过来的直接替换token为crm的token
        store.dispatch('setTokenAction', token)
    }
    return token
}

export default function ({ $axios, redirect, route, store, app }) {

    axios = $axios

    $axios.onRequest(config => {
        // 1. 请求头-添加token、接口版本号
        // 获取token
        const TOKEN = getToken()

        // 根据所在项目获取对应token
        // const TOKEN = getTokenByCRM(app, store)

        if (TOKEN) {
            // config.headers['token'] = getToken()
            config.headers['token'] = TOKEN
        }

        if (config.url !== '/api/user/login') {
            config.headers['version'] = API_VERSION
        }

        // 2 请求头-添加appCode
        requestHeaderParams(config)

        // 3. 请求头-添加sourceCode、sourceValueCode
        //    密码登录、快速登录、获取验证码、注册、退出登录、注销
        const userApis = [
            '/api/user/login',
            '/api/user/sendCode',
            '/api/user/userRegister',
            '/api/user/logOut',
            '/api/user/user/update',
            '/api/user/quickLogin',
            '/api/user/userQuery/verifyIdentity',
            '/api/user/user/password',
            '/api/user/userExtends/updateMobile',
            '/api/user/logOff'
        ]
        if (userApis.includes(config.url)) {
            config.headers['sourceCode'] = LOGIN_SOURCE_CODE
            config.headers['sourceValueCode'] = LOGIN_SOURCE_VAL_CODE
        }
        // 4.请求头-添加businessDetails
        const userDetailApis = [
            '/api/user/login',
            '/api/user/userRegister',
            '/api/user/quickLogin'
        ]
        if (userDetailApis.includes(config.url)) {
            config.headers['businessDetails'] = encodeURIComponent(REGISTER_BUSINESS_DETAILS_CODE)
        }
        // 信息收集表单
        const infoFormApis = [
            '/api/customer/potential-customer/sendSms',
            '/api/customer/potential-customer/save'
        ]
        if (infoFormApis.includes(config.url)) {
            config.headers['sourceCode'] = LOGIN_SOURCE_CODE
            config.headers['sourceValueCode'] = INFO_SOURCE_VAL_CODE
            config.headers['businessDetails'] = encodeURIComponent(store.getters.setBusinessDetails)
        }

        // 5. 请求头-添加禁用缓存配置：解决ie缓存问题
        config.headers['Cache-Control'] = 'no-cache'
        config.headers['If-Modified-Since'] = '0'
    })

    $axios.onResponse(res => {
        // do something
    })

    $axios.onError(error => {

        console.log(error.response)
        const code = parseInt(error.response && error.response.status)
        if (code === 401) {
            const path = route.path
            logout()
            redirect(`/login?redirect=${path}`)
        }
    })
}

export {
    axios
}
