// 地址重定向配置
let shareUrl = process.env.VUE_APP_SHARE_BASE_URL
export default [
    {
        from: '/',
        to: `${shareUrl}quote`,
        is: 'mobile'
    },
    // 保险方案
    {
        from: '/quote',
        to: `${shareUrl}quote`,
        is: 'mobile'
    },
    // 企业服务
    {
        from: '/enterprise_service',
        to: `${shareUrl}enterprise_service`,
        is: 'mobile'
    },
    // 百万年薪
    {
        from: '/salary_million',
        to: `${shareUrl}salary_million`,
        is: 'mobile'
    },
    // 信息披露
    {
        from: '/info_disclosure',
        to: `${shareUrl}info_disclosure`,
        is: 'mobile'
    }
]
