/**
 * 经纪端-点击可跳转页面
*/
import {
    // 购物车
    SHOPPING_CART_LIST_PAGE_CODE,
    SHOPPING_CART_SETTLE_PAGE_CODE,

    // 支付
    IMMEDIATE_PAYMENT_PAGE_CODE,
    PAYMENT_PAID_PAGE_CODE,
    PAYMENT_COMPLETED_PAGE_CODE,
    PAYMENT_SUCCESS_PAGE_CODE,

    // 投保
    CLIENT_INSURE_PAGE_CODE,
    CLIENT_REINSURE_PAGE_CODE,
    BROKER_USER_CLIENT_INSURE_AUDIT_PAGE_CODE,
    CLIENT_CONFIRM_INSURE_INFO_PAGE_CODE,
    CLIENT_SUBMIT_INSURE_INFO_PAGE_CODE,
    CLIENT_SUBMIT_INSURE_ORDER_PAGE_CODE,

    // 消息中心
    MESSAGE_CENTER_LIST_PAGE_CODE,

    // 账户设置/我的账户
    ACCOUNT_SET_PERSONAL_INFO_PAGE_CODE,
    ACCOUNT_SECURITY_PAGE_CODE,
    ACCOUNT_SECURITY_UPDATE_PASSWORD_PAGE_CODE,
    ACCOUNT_SECURITY_UPDATE_MOBILE_PAGE_CODE,
    ACCOUNT_SECURITY_LOG_OFF_PAGE_CODE,
    INVOICE_HEADER_PAGE_CODE,
    RECEIVING_ADDRESS_PAGE_CODE,

    // 我的工保
    PERSONAL_GB_PAGE_CODE,
    ALL_ORDER_ORDER_LIST_PAGE_CODE,
    ALL_ORDER_ORDER_DETAIL_PAGE_CODE,
    ALL_ORDER_APPLY_SURRENDER_PAGE_CODE,
    ALL_ORDER_ORDER_EVALUATION_PAGE_CODE,
    BROKER_INSURANCE_CONSULTATION_LIST_PAGE_CODE,
    INSURANCE_CONSULTATION_DETAIL_PAGE_CODE,
    BROKER_BROKER_CONSULTATION_LIST_PAGE_CODE,
    BROKER_CONSULTATION_DETAIL_PAGE_CODE,
    INVOICE_MANAGE_LIST_PAGE_CODE,
    INVOICE_MANAGE_DETAIL_PAGE_CODE,
    INVOICE_MANAGE_APPLY_INVOICE_PAGE_CODE,
    BROKER_CORRECTION_SERVICE_LIST_PAGE_CODE,
    BROKER_CORRECTION_SERVICE_DETAIL_PAGE_CODE,
    POLICY_SELECT_LIST_PAGE_CODE,
    INVITE_USERS_PAGE_CODE,
    PROMOTE_GUEST_PAGE_CODE,
    INVITE_BROKERS_PAGE_CODE,
    QUOTATION_TOOL_LIST_PAGE_CODE,
    DATA_BASE_LIST_PAGE_CODE
} from '@/constant/pageEnum/broker'
const brokerRouter = [
    /**
     * 购物车
     */
    // 购物车-列表
    {
        path: '/cart',
        code: SHOPPING_CART_LIST_PAGE_CODE,
        name: 'cart'
    },
    // 购物车-去结算
    {
        path: '/cart/confirm_pay',
        code: SHOPPING_CART_SETTLE_PAGE_CODE,
        name: 'cart-confirm_pay'
    },

    /**
     * 支付
     */
    // 立即支付
    {
        path: '/cashier',
        code: IMMEDIATE_PAYMENT_PAGE_CODE,
        name: 'cashier'
    },
    // 已支付
    {
        path: '/cashier/paid',
        code: PAYMENT_PAID_PAGE_CODE,
        name: 'cashier-paid'
    },
    // 支付完成
    {
        path: '/cashier/pay_completed',
        code: PAYMENT_COMPLETED_PAGE_CODE,
        name: 'cashier-pay_completed'
    },
    // 支付成功
    {
        path: '/cashier/pay_success',
        code: PAYMENT_SUCCESS_PAGE_CODE,
        name: 'cashier-pay_success'
    },

    /**
     *  投保
     */
    // 投保页面
    {
        path: '/insure',
        code: CLIENT_INSURE_PAGE_CODE,
        name: 'insure'
    },
    // 重新投保
    {
        path: '/reinsure',
        code: CLIENT_REINSURE_PAGE_CODE,
        name: 'reinsure'
    },
    // 立即投保-投保审核
    {
        path: '/insure/audit',
        code: BROKER_USER_CLIENT_INSURE_AUDIT_PAGE_CODE,
        name: 'insure-audit'
    },
    // 立即投保-确认投保信息
    {
        path: '/insure/confirm',
        code: CLIENT_CONFIRM_INSURE_INFO_PAGE_CODE,
        name: 'insure-confirm'
    },
    // 投保-提交投保信息-提示页
    {
        path: '/insure/message',
        code: CLIENT_SUBMIT_INSURE_INFO_PAGE_CODE,
        name: 'insure-message'
    },
    // 投保-提交投保订单-提示页
    {
        path: '/insure/submit_message',
        code: CLIENT_SUBMIT_INSURE_ORDER_PAGE_CODE,
        name: 'insure-submit_message'
    },

    /**
     * 消息中心
    */
    // 消息中心-列表
    {
        path: '/message',
        code: MESSAGE_CENTER_LIST_PAGE_CODE,
        name: 'message'
    },

    /**
     * 账户设置/我的账户
     */
    // 账户设置-个人信息
    {
        path: '/set_account/user_info',
        code: ACCOUNT_SET_PERSONAL_INFO_PAGE_CODE,
        name: 'set_account-user_info'
    },
    // 账户设置-账户安全
    {
        path: '/set_account/account_safe',
        code: ACCOUNT_SECURITY_PAGE_CODE,
        name: 'set_account-account_safe'
    },
    // 账户设置-账户安全-修改密码
    {
        path: '/set_account/account_safe/update_password',
        code: ACCOUNT_SECURITY_UPDATE_PASSWORD_PAGE_CODE,
        name: 'set_account-account_safe-update_password'
    },
    // 账户设置-账户安全-修改手机号
    {
        path: '/set_account/account_safe/update_mobile',
        code: ACCOUNT_SECURITY_UPDATE_MOBILE_PAGE_CODE,
        name: 'set_account-account_safe-update_mobile'
    },
    // 账户设置-账户安全-账户注销
    {
        path: '/set_account/account_safe/log_off',
        code: ACCOUNT_SECURITY_LOG_OFF_PAGE_CODE,
        name: 'set_account-account_safe-log_off'
    },
    // 账户设置-发票抬头
    {
        path: '/set_account/invoice_title',
        code: INVOICE_HEADER_PAGE_CODE,
        name: 'set_account-invoice_title'
    },
    // 账户设置-收件地址
    {
        path: '/set_account/receive_address',
        code: RECEIVING_ADDRESS_PAGE_CODE,
        name: 'set_account-receive_address'
    },


    /**
     * 我的工保
     */
    // {
    //     path: "/user",
    //     code: PERSONAL_GB_PAGE_CODE,
    //     name: "user"
    // },

    /**
     * 订单管理
     */
    // 全部订单-订单列表
    {
        path: '/order/list',
        code: ALL_ORDER_ORDER_LIST_PAGE_CODE,
        name: 'order-list'
    },
    // 全部订单-订单详情
    {
        path: '/order/detail',
        code: ALL_ORDER_ORDER_DETAIL_PAGE_CODE,
        name: 'order-detail'
    },
    // 全部订单-申请退保
    {
        path: '/order/surrender',
        code: ALL_ORDER_APPLY_SURRENDER_PAGE_CODE,
        name: 'order-surrender'
    },
    // 全部订单-订单评价
    {
        path: '/order/evaluation',
        code: ALL_ORDER_ORDER_EVALUATION_PAGE_CODE,
        name: 'order-evaluation'
    },
    /**
     * 客户服务
     */
    // 客户服务-投保咨询-列表
    {
        path: '/user/broker/service_insure',
        code: BROKER_INSURANCE_CONSULTATION_LIST_PAGE_CODE,
        name: 'user-broker-service_insure'
    },
    // 客户服务-投保咨询-详情
    {
        path: '/service_insure/detail',
        code: INSURANCE_CONSULTATION_DETAIL_PAGE_CODE,
        name: 'service_insure-detail'
    },
    // 客户服务-经纪人咨询-列表
    {
        path: '/user/broker/service_broker',
        code: BROKER_BROKER_CONSULTATION_LIST_PAGE_CODE,
        name: 'user-broker-service_broker'
    },
    // 客户服务-经纪人咨询-详情
    {
        path: '/service_broker/detail',
        code: BROKER_CONSULTATION_DETAIL_PAGE_CODE,
        name: 'service_broker-detail'
    },
    // 客户服务-发票管理-列表
    {
        path: '/user/insured/invoice_manage/list',
        code: INVOICE_MANAGE_LIST_PAGE_CODE,
        name: 'user-insured-invoice_manage-list'
    },
    // 客户服务-发票管理-详情
    {
        path: '/user/insured/invoice_manage/info',
        code: INVOICE_MANAGE_DETAIL_PAGE_CODE,
        name: 'user-insured-invoice_manage-info'
    },
    // 客户服务-发票管理-申请发票
    {
        path: '/user/insured/invoice_manage/apply_invoice',
        code: INVOICE_MANAGE_APPLY_INVOICE_PAGE_CODE,
        name: 'user-insured-invoice_manage-apply_invoice'
    },
    // 客户服务-批改服务-列表
    {
        path: '/user/broker/correct_service/list',
        code: BROKER_CORRECTION_SERVICE_LIST_PAGE_CODE,
        name: 'user-broker-correct_service-list'
    },
    // 客户服务-批改服务-详情
    {
        path: '/user/broker/correct_service/info',
        code: BROKER_CORRECTION_SERVICE_DETAIL_PAGE_CODE,
        name: 'user-broker-correct_service-info'
    },
    // 客户服务-保单查询-保单列表
    {
        path: '/policy/list',
        code: POLICY_SELECT_LIST_PAGE_CODE,
        name: 'policy-list'
    },
    // 客户服务-邀请用户
    {
        path: '/user/broker/invite_users',
        code: INVITE_USERS_PAGE_CODE,
        name: 'user-broker-invite_users'
    },
    // 客户服务-推广获客
    {
        path: '/user/broker/promote_guest/list',
        code: PROMOTE_GUEST_PAGE_CODE,
        name: 'user-broker-promote_guest-list'
    },
    // 客户服务-邀请经纪人
    {
        path: '/user/broker/invite_brokers',
        code: INVITE_BROKERS_PAGE_CODE,
        name: 'user-broker-invite_brokers'
    },

    /**
     * 报价工具
     */
    // 报价工具-报价工具-列表
    {
        path: '/user/broker/quotation_tool',
        code: QUOTATION_TOOL_LIST_PAGE_CODE,
        name: 'user-broker-quotation_tool'
    },

    /**
     * 资料库
     */
    // 资料库-资料列表-列表
    {
        path: '/data_base/list',
        code: DATA_BASE_LIST_PAGE_CODE,
        name: 'data_base-list'
    }
]

export default brokerRouter

