//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SERVICE_TEL } from '@/config'
import {
    Breadcrumb as ABreadcrumb,
    Icon as AIcon
} from 'ant-design-vue'

import { getToken } from '@/utils/auth'

// 权限code
import { SHOPPING_CART_MENU_CODE, PERSONAL_CENTER_MENU_CODE } from '@/constant/menuEnum'
export default {
    name: 'RightBar',
    components: {
        ABreadcrumb,
        ABreadcrumbItem: ABreadcrumb.Item,
        AIcon
    },
    data() {
        return {
            SHOPPING_CART_MENU_CODE, // 购物车权限code
            PERSONAL_CENTER_MENU_CODE, // 个人中心权限code
            serviceTel: SERVICE_TEL,
            breadcrumbList: [
                {
                    name: '个人中心',
                    linkName: 'user',
                    iconShow: false,
                    code: PERSONAL_CENTER_MENU_CODE,
                    menuShow: false
                }, {
                    name: '购物车',
                    imgUrl: require('@/assets/images/topBar/shopping-car.png'),
                    linkName: 'cart',
                    iconShow: true,
                    code: SHOPPING_CART_MENU_CODE,
                    menuShow: false
                }
                // {
                //   name:'保单查询',
                //   linkName:'',
                //   iconShow:false
                // },
                //  {
                //   name:'我要理赔',
                //   linkName:'',
                //   iconShow:false
                // }
            ],
            breadcrumbListNew: []
        }
    },
    computed: {
        role() {
            return this.$store.getters.role
        },

        buttonRole() {
            return this.$store.getters.buttonRole
        }
    },
    watch: {
        role: {
            handler() {
                this.handleIsShow()
            },
            immediate: true
        }
    },
    async mounted() {
        // if(getToken()) {
        //     await this.$store.dispatch('getUserInfo')
        // }
    },
    methods: {
        /**
         * 判断菜单是否显示
         */
        async handleIsShow() {
            let { breadcrumbList, role } = this
            let menuPermission = role ? Object.keys(role) : []
            breadcrumbList.forEach(item => {
                if (menuPermission.indexOf(item.code) !== -1) {
                    item.menuShow = true
                }
            })
            this.breadcrumbListNew = breadcrumbList.filter(item => item.menuShow) || []
        },
        getLinkHref(name) {
            return this.$router.resolve({
                name
            }).href
        }
    }
}
