import { WHITE_LIST, BLACK_LIST } from '@/config'
import { getToken, removeToken } from '@/utils/auth'
import breadcrumbs from '@/config/breadcrumbs'
import { SET_BREADCRUMB } from '@/store/mutation-types'
import insurerRouter from '@/config/pageRoute/insurerRouter'
import brokerRouter from '@/config/pageRoute/brokerRouter'
import insuredRouter from '@/config/pageRoute/insuredRouter'

import Middleware from '@/utils/Middleware'

import {
    APP_CODE_NET_AGENT,
    APP_CODE_NET_INS,
    APP_CODE_NET_USER
} from '@/constant/enum'

/**
 * 路径转为数组
 * @param path
 * @returns {*}
 */
function path2Arr(path) {
    return path.split('/').filter(p => p)
}

/**
 * 匹配面包屑数据
 * @param matchPath
 * @returns {*}
 */
function matchBreadcrumbData(matchPath) {
    return path2Arr(matchPath)
        .map(path => {
            path = path.replace(/^:([^:?]+)(\?)?$/, (match, $1) => `_${$1}`)
            return '/' + path
        })
        .map((path, index, paths) => {

            // 第 0 个不需拼接
            if (index) {
                let result = ''
                for (let i = 0; i <= index; i++) {
                    result += paths[i]
                }
                return result
            }
            return path
        })
        .map(path => {
            const item = breadcrumbs.find(bread => bread.path === path)
            if (item) {
                return item
            }
            return {
                name: path.split('/').pop(),
                path,
                clickable: false,
                isShow: true
            }
        })
}

/**
 * @desc 获取首页面包屑数据
 * @returns {Object} 面包屑数据
 */
function getIndexBreadcrumbData() {
    return breadcrumbs.find(bread => bread.path === '/')
}

/**
 * 设置面包屑相关数据
 * @param store
 * @param to
 */
function commitBreadcrumbData(store, to) {
    const toPathArr = path2Arr(to.path)
    const toPathArrLength = toPathArr.length
    let matchPath = ''

    // 从 matched 中找出当前路径的路由配置
    for (let match of to.matched) {
        const matchPathArr = path2Arr(match.path)
        if (matchPathArr.length === toPathArrLength) {
            matchPath = match.path
            break
        }
    }

    const breadcrumbData = matchBreadcrumbData(matchPath)
    // 添加首页
    breadcrumbData.unshift(getIndexBreadcrumbData())
    store.commit(SET_BREADCRUMB, breadcrumbData)
}


// 是否是需要登录之后才可访问的
function needLogin(toPath) {
    return BLACK_LIST.some(({ path, needLogin }) => needLogin && toPath.startsWith(path))
}

// 是否是需要相关权限才可访问的
function needPermission(toPath) {
    return BLACK_LIST.some(({ path, needPermission }) => needPermission && toPath.startsWith(path))
}

// 判断是否在 鉴权列表 内
function checkPermission(store, to, next) {
    const { getters: { sideMenu } } = store
    const { path: toPath } = to

    if (needPermission(toPath)) {
        let permission = false

        function dfsSideMenu(nodes) {
            if (Array.isArray(nodes)) {
                nodes.forEach(({ link, treeList }) => {
                    dfsSideMenu(treeList)
                    if (toPath === link) {
                        permission = true
                    }
                })
            }
        }

        dfsSideMenu(sideMenu)

        if (permission) {
            next()
        } else {
            next('/error_page/404')
        }
    } else {
        next()
    }
}

/**
 * @desc 判断页面是否有权限
 */
function checkPagePermission(store, to, next) {
    // 获取appCode 根据appCode判断是经纪端/用户端/保险端
    let appCode = store.getters.userInfo.appCode
    let routers
    // 根据appCode获取菜单的权限code
    if (appCode === APP_CODE_NET_AGENT) {
        routers = brokerRouter
    } else if (appCode === APP_CODE_NET_INS) {
        routers = insurerRouter
    } else {
        routers = insuredRouter
    }
    // 从store里面获取页面权限
    const { getters: { role } } = store
    // 获取页面权限code值
    let pagePermission = Object.keys(role)
    const { path: toPath } = to
    // 根据接口返回的权限值判断对应菜单的code是否包含在里面 不包含代表页面无权限 跳转404页面
    routers.forEach(item => {
        if (toPath === item.path && pagePermission.indexOf(item.code) === -1) {
            next('/error_page/404')
        }
    })
}

/**
 * @desc 清除空值的url
 * @param {String} redirectRoute redirect的路由
 * @returns newRedirect
 */
function clearEmptyUrlParams(redirectRoute) {
    let [curRoute, curRouteParams] = redirectRoute.split('?')

    // 如果没带参数则直接使用
    if (!curRouteParams) return curRoute

    // 判断参数是否有值
    let curRouteParamsList = curRouteParams.split('&')
    let newRouteParamsList = curRouteParamsList.filter(item => item.split('=')[1])

    return curRoute + '?' + newRouteParamsList.join('&')
}


/**
 * 跳转到目标路径
 * @param store
 * @param to
 * @param next
 */
function toNext(store, to, next) {
    commitBreadcrumbData(store, to)
    next()
}

/**
 * 跳转至登录
 * @param store
 * @param to
 * @param next
 */
function toLogin(store, to, next) {
    // 跳转至登录页
    // 使用fullPath，防止参数丢失，使用clearEmptyUrlParams 处理空的参数
    // next(`/login?redirect=${clearEmptyUrlParams(to.fullPath)}`)
    // 因为如果参数为空，route的query会切割
    // ps: /login?redirect=/a/b/c?id=&name=123  ----->    query: {redirect: /a/b/c?id=, name: 123}
    next({
        path: '/login',
        query: {
            redirect: clearEmptyUrlParams(to.fullPath)
        }
    })
}

/**
 * 跳转至异常页面
 * @param next
 */
function toError(next) {
    next('/error_page/404')
}

const middleware = new Middleware()

let isRemoveToken = false

/**
 * 登录有效性校验
 */
middleware.use(async (ctx, nextAction) => {
    const { data } = ctx
    const { store, next, to } = data || {}
    // 判断是否已有token 或者token 没有被删除
    if (!isRemoveToken || getToken()) {
    // 判断是否前往登录页面，如果前往登录页则 强制重定向回首页
        nextAction()
    } else {
    // 判断是否不在白名单内，如果不在白名单内，则需要进行登录
        if (needLogin(to.path)) {
            // 跳转至登录页
            toLogin(store, to, next)
        } else {
            // 跳转至目标
            toNext(store, to, next)
        }
    }
})

/**
 * 判断是否有用户信息
 */
middleware.use(async (ctx, nextAction) => {

    const { data } = ctx
    const { store, next, to } = data || {}

    // 如果已有用户信息 及权限 则向下继续。否则获取用户信息
    if (store.getters.userInfo) {
        nextAction()
    } else {
        try {
            // dispatch 权限
            await store.dispatch('getUserInfo')

            nextAction()
        } catch (e) {
            // 移除无用的token
            removeToken()
            // 设置状态为已删除
            isRemoveToken = true
            // 如果不在白名单内
            if (needLogin(to.path)) {
                toLogin(store, to, next)
            } else {
                toNext(store, to, next)
            }
        }
    }
})


middleware.use(async (ctx, nextAction) => {
    const { data } = ctx
    const { next, to } = data || {}
    if (to.path === '/login') {
        next({
            path: '/'
        })
    } else {
        nextAction()
    }
})

/**
 * 校验是否在白名单内
 */
middleware.use(async (ctx, nextAction) => {

    const { data } = ctx
    const { store, next, to } = data || {}
    const { getters: { sideMenu } } = store
    const { path: toPath } = to

    // 判断是否不在白名单内
    // 如果不在白名单内，则进行权限校验
    if (needPermission(toPath)) {
        let permission = false

        function dfsSideMenu(nodes) {
            if (Array.isArray(nodes)) {
                nodes.forEach(({ link, treeList }) => {
                    dfsSideMenu(treeList)
                    if (toPath === link) {
                        permission = true
                    }
                })
            }
        }

        dfsSideMenu(sideMenu)

        // 在权限列表中 则 继续执行
        if (permission) {
            nextAction()
            // next()
        } else {
            // 如果不在
            toError(next)
            // next("/error_page/404")
        }
    } else {
        nextAction()
    }
})

/**
 * 判断是否在对应角色的权限内
 */
middleware.use(async (ctx, nextAction) => {

    // 路由策略
    const tactics = {
        [APP_CODE_NET_INS]: brokerRouter,
        [APP_CODE_NET_INS]: insurerRouter,
        default: insuredRouter
    }

    const { data } = ctx
    const { store, next, to } = data || {}
    // 获取appCode 根据appCode判断是经纪端/用户端/保险端
    const appCode = store.getters.userInfo.appCode
    // 根据appCode获取菜单的权限code
    const routers = tactics[appCode] || tactics.default
    // 从store里面获取页面权限
    const { getters: { role } } = store
    // 获取页面权限code值
    let pagePermission = Object.keys(role)
    const { path: toPath } = to
    // 根据接口返回的权限值判断对应菜单的code是否包含在里面 不包含代表页面无权限 跳转404页面
    let isError = false
    routers.forEach(item => {
        if (toPath === item.path &&
      pagePermission.indexOf(item.code) === -1) {
            isError = true
        }
    })

    if (isError) {
        toError(next)
    } else {
        nextAction()
    }
})


/**
 * 兜底操作
 */
middleware.use(async ctx => {
    const { data } = ctx
    const { store, next, to } = data || {}
    toNext(store, to, next)
})

export default function ({ store, app: { router } }) {


    router.beforeEach(async (to, from, next) => {
    // window.NProgress.start() // 进度条开始
        middleware.compose({
            from,
            to,
            next,
            store
        })
    // if (getToken()) {
    //     // 是否已登录
    //     if (to.path === '/login') {
    //         next({ path: '/' })
    //         // window.NProgress.done()
    //     } else {
    //         // 是否已获取到用户权限列表
    //         if (!store.getters.userInfo) {
    //             // 如果没有 则 进去权限获取请求
    //             try {
    //                 // dispatch 权限
    //                 await store.dispatch('getUserInfo')
    //                 // dispatah 路由
    //                 // let routes = await store.dispatch('generateRoutes')
    //                 // 添加404
    //                 // routes = [...routes, {path: '*', redirect: '/404', hidden: true}]
    //                 // 向路由表中 添加动态路由
    //                 // router.addRoutes(routes)
    //                 // 跳转至目标
    //                 // next({...to, replace: true})
    //
    //                 commitBreadcrumbData(store, to)
    //                 checkPermission(store, to, next)
    //                 checkPagePermission(store, to, next)
    //             } catch (e) {
    //                 removeToken()
    //
    //                 if (needLogin(to.path)){
    //                   next({
    //                     path: '/login',
    //                     query: {
    //                       redirect: clearEmptyUrlParams(to.fullPath)
    //                     }
    //                   })
    //                 }else {
    //                   next()
    //                 }
    //
    //                 // notification.error({
    //                 //     message: '错误',
    //                 //     description: '请求用户信息失败，请重试'
    //                 // })
    //                 // setTimeout(()=>{
    //                 //
    //                 // },500)
    //                 // throw new Error(e)
    //             }
    //         } else {
    //             commitBreadcrumbData(store, to)
    //             checkPermission(store, to, next)
    //             checkPagePermission(store, to, next)
    //         }
    //     }
    // } else {
    //     if (needLogin(to.path)) {
    //         // 跳转至登录页
    //         // 使用fullPath，防止参数丢失，使用clearEmptyUrlParams 处理空的参数
    //         // next(`/login?redirect=${clearEmptyUrlParams(to.fullPath)}`)
    //         next({
    //             path: '/login',
    //             query: {
    //                 redirect: clearEmptyUrlParams(to.fullPath)
    //             }
    //         })
    //         // 因为如果参数为空，route的query会切割
    //         // ps: /login?redirect=/a/b/c?id=&name=123  ----->    query: {redirect: /a/b/c?id=, name: 123}
    //
    //         // window.NProgress.done()
    //     } else {
    //         commitBreadcrumbData(store, to)
    //         next()
    //     }
    // }
    })

    router.afterEach(() => {
    // window.NProgress.done()
    })
}
