//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Radio as ARadio,
    Button as AButton,
    Tabs as ATabs
} from 'ant-design-vue'

export default {
    name: 'TabPanel',
    components: {
        ARadio,
        ARadioGroup: ARadio.Group,
        ARadioButton: ARadio.Button,
        AButton,
        ATabs,
        ATabPane: ATabs.TabPane
    },
    data() {
        return {
            tabList: [
                {
                    name: '首页',
                    linkName: ''
                },
                {
                    name: '保险产品',
                    linkName: 'product'
                },
                {
                    name: '投保咨询',
                    linkName: ''
                },
                {
                    name: '关于我们',
                    linkName: ''
                }
            ],
            activeIndex: 0
        }
    },
    methods: {
        handleTabChange(index, linkName) {
            this.activeIndex = index
            this.$router.push({
                name: linkName
            })
        }
    }
}
