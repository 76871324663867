// 接口地址
export const BASE_URL = process.env.VUE_APP_BASE_URL
// cms接口地址
export const CMS_BASE_URL = process.env.VUE_APP_CMS_URL
// 域名
export const HOST = process.client ? `${window.location.protocol}//${window.location.host}` : ''

// 接口版本号
export const API_VERSION = '3.1.4'

// 登录-经纪人用户
export const LOGIN_APP_CODE_AGENT = 'NET-AGENT'
// 登录-保险公司用户
export const LOGIN_APP_CODE_INS = 'net-ins'
// 登录-普通用户
export const LOGIN_APP_CODE_USER = 'NET-USER'
// 登录-来源编码
export const LOGIN_SOURCE_CODE = 'GONG_BAO_NET'
// 登录-来源编码值
export const LOGIN_SOURCE_VAL_CODE = 'GBW_FRONT'
// 信息收集表单-来源编码值
export const INFO_SOURCE_VAL_CODE = 'INFO_COLLECTION_FORM'
// 注册-业务来源明细
export const REGISTER_BUSINESS_DETAILS_CODE = '工保网PC'
// 登录-ip
export const LOGIN_IP = '183.129.233.146'

// 媒体查询枚举
export const MediaQueryEnum = {
    'screen-xs': {
        maxWidth: 575
    },
    'screen-sm': {
        minWidth: 576,
        maxWidth: 767
    },
    'screen-md': {
        minWidth: 768,
        maxWidth: 991
    },
    'screen-lg': {
        minWidth: 992,
        maxWidth: 1199
    },
    'screen-xl': {
        minWidth: 1200,
        maxWidth: 1599
    },
    'screen-xxl': {
        minWidth: 1600
    }
}

// 系统免鉴权白名单
export const WHITE_LIST = [
    '/login',
    '/register',
    '/product',
    '/product/info',
    '/share/insure/confirm',
    '/404',
    '/401',
    '/500'
]

/**
 * @name BLACK_LIST
 * @type Array
 * @description 系统鉴权黑名单
 *
 * @name needLogin
 * @type Boolean
 * @description 是否为需要登录才能访问的路由页面
 *
 * @name needPermission
 * @type Boolean
 * @description 是否为需要相关权限才能访问的路由页面
 */
export const BLACK_LIST = [
    // 电子签章
    {
        path: '/signature',
        needLogin: true,
        needPermission: false
    },
    // 我的工保 - start
    {
        path: '/user',
        needLogin: true,
        needPermission: false
    },
    {
        path: '/user/broker',
        needLogin: true,
        needPermission: true
    },
    {
        path: '/user/insurer',
        needLogin: true,
        needPermission: true
    },
    {
        path: '/user/insured',
        needLogin: true,
        needPermission: true
    },
    {
        path: '/order',
        needLogin: true,
        needPermission: true
    },
    {
        path: '/service_insure',
        needLogin: true,
        needPermission: true
    },
    {
        path: '/service_broker',
        needLogin: true,
        needPermission: true
    },
    {
        path: '/policy',
        needLogin: true,
        needPermission: true
    },
    // 资料库
    {
        path: '/data_base',
        needLogin: true,
        needPermission: true
    },
    // 我的工保 - end
    // 账户设置 - start
    {
        path: '/set_account',
        needLogin: true,
        needPermission: false
    },
    // 账户设置 - end
    // 消息中心 - start
    {
        path: '/message',
        needLogin: true,
        needPermission: false
    },
    // 消息中心 - end
    // 立即投保 - start
    {
        path: '/insure',
        needLogin: true,
        needPermission: false
    }
    // 立即投保 - end
]

// 默认跳转路径
export const DEFAULT_REDIRECT_PATH = '/'

// 系统token key 名称
export const TOKEN_KEY = 'GONGBAO_TOKEN'

// CRM系统token key 名称
export const CRM_TOKEN_KEY = 'Admin-Token'


// 系统 enterpriseAuthorizationId 名称
export const ENTERPRISE_AUTHORIZATION_ID = 'ENTERPRISE_AUTHORIZATION_ID'

// token 有效期 1（天）
export const TOKEN_TIME = 60 * 60 * 24

// 默认table 分页数量
export const DEFAULT_PAGES_SIZE = 10
// 详情记录列表 分页数量
export const DETAIL_LIST_PAGE_SIZE = 5
// 区分是前台还是后台  前台默认1
export const IS_CLIENT = 1


// 客户端id
export const CLIENT_ID = 'cms'
// 客户端秘钥
export const CLIENT_SECRET = 'cms_secret'

// 阿里云oss-回调地址
export const OSS_CALLBACK_URL = process.env.VUE_APP_OSS_CALLBACK_URL
// 阿里云oss-秘钥
export const OSS_ACCESS_KEY_SECRET = process.env.VUE_APP_OSS_ACCESS_KEY_SECRET

// 短信发送间隔
export const SMS_COUNTDOWN_TIME = 60

// 投保-保存间隔
export const INSURE_SAVE_COUNTDOWN_TIME = 3

// 登录页跳转链接
export const LOGIN_PAGE_JUMP_URL = '/'

// 客服电话
export const SERVICE_TEL = '400-800-5100'

// 联系时间
export const SERVICE_TIME = '工作日8:30~20:30'

// 查询快递公司--快递类型
export const COURIER_TYPE = 1

// 中国保险行业协会信息披露
export const ASSOCIATION_URL = 'http://icid.iachina.cn/?columnid_url=201509301401'
// 经纪人信息查询
export const AGENT_URL = 'http://iir.circ.gov.cn'
// 自营网络平台网址
export const WEBSITE_URL = 'https://gongbao.cn'
// 机构信息披露网站地址
export const MECHANISM_URL = 'https://j.gongbao.cn'

// 工保科技官网 url
export const CNGB_URL = 'https://j.gongbao.cn/'

export const CNGB_URL_NEW = 'https://cngongbao.com/'

// 浙公网安备 url
export const AN_BEI_URL = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802011271'

// 浙ICP url
export const ICP_URL = 'https://beian.miit.gov.cn/'

// 工保网来源id
export const POTENTIAL_CUSTOMER_SOURCE_ID = 1


// share项目对应的host
export const SHARE_BASE_URL = process.env.VUE_APP_SHARE_BASE_URL

// 工保通传递信息加密公钥
export const GONGBAOTONG_PUBLIC_KEY =
    '-----BEGIN PUBLIC KEY-----\n' +
    'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBALAzzRnm4aLnGo2YdL6NSP1GUxCl3yqV\n' +
    'kg6Lw6UqatUmCy0hfBbd7sNCJUahC8pxbFgSxQAB1tsRWetGKXmsigcCAwEAAQ==\n' +
    '-----END PUBLIC KEY-----'

// 工保通传递信息加密私钥
export const GONGBAOTONG_PRIVATE_KEY =
    '-----BEGIN PRIVATE KEY-----\n' +
    'MIIBUwIBADANBgkqhkiG9w0BAQEFAASCAT0wggE5AgEAAkEAsDPNGebhoucajZh0\n' +
    'vo1I/UZTEKXfKpWSDovDpSpq1SYLLSF8Ft3uw0IlRqELynFsWBLFAAHW2xFZ60Yp\n' +
    'eayKBwIDAQABAkB7dSAtGN9Zw7GQKpUJ+nZ8t6x1yrLN5JS8ymLftBO8mcym6Uzr\n' +
    'U9dbnZwErm2f2rDHQUD/Qzro5hNhWsWGp0rBAiEA2IYhOKILKWUz9IT8fhwxnsJ5\n' +
    'sDL0FxvjTOcPVB86VuECIQDQU7uKftk784+x6V+iFtt/xLIYU71ck85jJIxtdc3F\n' +
    '5wIgRYyYATPzt+E9Fg7dd7igq6k2I0XKp92aXmpdvxykxaECIEIo5V1VbY3hitf4\n' +
    'gnuKgMjx4ZFiPrgA5MGNoN8+U0wvAiA2I9NpFz5nLeR0Sf8GvTSO4R7rDi+DTOYF\n' +
    'v2MpI88BQA==\n' +
    '-----END PRIVATE KEY-----'

// 线索端口
export const GU_WEN = 'gu_wen'

// 七鱼客服地址
export const QI_YU = 'https://qiyukf.com/script/0b89a5c668263ab092c4b96d0bebe2a1.js?hidden=1'
