import { BASE_URL } from '@/config'
/** 基础查询接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 退出
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const USER_LOG_OUT = {
    url: `${BASE_URL}/user/logOut`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取用户信息
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_INFO = {
    url: `${BASE_URL}/user/getUserInfo`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 根据用户唯一标志查询用户信息（批改服务，查询保险用户下的保险企业、保险机构）
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_RELATED = {
    url: `${BASE_URL}/user/userExtends/selectOne`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}


/**
 * new 根据用户唯一标志查询用户信息（批改服务，查询保险用户下的保险企业、保险机构）
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_RELATED_TOKEN = {
    url: `${BASE_URL}/user/userExtends/token`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 根据用户唯一标志查询用户信息（批改服务，查询保险用户下的保险企业、保险机构）----新接口
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_RELATED_NEW = {
    url: `${BASE_URL}/user/user/selectOne`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 判断用户手机号/验证码是否匹配
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const AUTH_USER = {
    url: `${BASE_URL}/user/personClassify/authUser`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 更新用户信息
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const UPDATE_USER_INFO = {
    url: `${BASE_URL}/user/user/update`,
    method: 'put',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/** 阿里云oss 接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 阿里云oss-获取token
 * @type {{}}
 */
export const GET_OSS_TOKEN = {
    url: `${BASE_URL}/common/oss/token`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/** 阿里云oss 接口 sts模式
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 阿里云oss-获取token sts
 * @type {{}}
 */
export const GET_OSS_TOKEN_STS = {
    url: `${BASE_URL}/common/oss/sts`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/** 阿里云oss 接口 host
 * ————————————————————————————————————————————————————————————————————————————————————————————*/
/**
 * 阿里云oss-获取host
 * @type {{}}
 */
export const GET_OSS_ADDRESS = {
    url: `${BASE_URL}/common/oss/address`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取图形验证码
 *
 */
export const ORDER_SURRENDER_IMAGE_CODE = {
    url: `${BASE_URL}/insurance/insurance-refund/imageVerificationCode`,
    method: 'get',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 发送验证码
 *
 */
export const ORDER_SURRENDER_SEND_SMS = {
    url: `${BASE_URL}/insurance/insurance-refund/sendSms`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 验证码校验
 *
 */
export const ORDER_SURRENDER_VALIDATION_SMS = {
    url: `${BASE_URL}/insurance/insurance-refund/validationSms`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 图片验证码校验
 *
 */
export const PICTURE_CAPTCHA_VERIFICATION = {
    url: `${BASE_URL}/insurance/insurance-refund/validationImageVerification`,
    method: 'post',
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/** 系统地区管理 接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 获取省市区-联合-缓存
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_PROVINCE_REDIS_LIST = {
    url: `${BASE_URL}/product/province/selectRedisProvince`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 获取用户权限
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_PERMISSION = {
    url: `${BASE_URL}/user/getUserPermission`,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 根据用户唯一标志查询用户组信息
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_USER_GROUP_BY_USER_ID = {
    url: `${BASE_URL}/user/userGroup/queryUserGroupByUserId`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 修改密码
 * @type {{}}
 */
export const UPDATE_PASSWORD = {
    url: `${BASE_URL}/user/user/password`,
    method: 'put',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 修改手机号
 * @type {{}}
 */
export const UPDATE_MOBILE = {
    url: `${BASE_URL}/user/userExtends/updateMobile`,
    method: 'put',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 注销
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const USER_LOG_OFF = {
    url: `${BASE_URL}/user/logOff`,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 30000
}

/**
 * 用户 静默登录获取新token接口
 * ————————————————————————————————————————————————————————————————————————————————————————————*/

/**
 * 获取用户信息
 * @type {{headers: {"Content-Type": string}, method: string, url: string, timeout: number}}
 */
export const GET_NEW_TOKEN = {
    url: `${BASE_URL}/user/freePasswordLogin`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        sourceCode: 'GONG_BAO_NET',
        sourceValueCode: 'INFO_COLLECTION_FORM',
        appCode: 'net-user',
        businessDetails: encodeURIComponent('gateWay-define')
    },
    timeout: 30000
}


