/**
 * 用户端页面权限code
 * @type {string}
 */
/**
 * 购物车页面code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 购物车-列表
export const SHOPPING_CART_LIST_PAGE_CODE = 'SHOPPING_CART_LIST'
// 购物车-去结算
export const SHOPPING_CART_SETTLE_PAGE_CODE = 'SHOPPING_CART_SETTLE'

/**
 * 支付
 */
// 立即支付
export const IMMEDIATE_PAYMENT_PAGE_CODE = 'IMMEDIATE_PAYMENT'
// 已支付
export const PAYMENT_PAID_PAGE_CODE = 'PAYMENT_PAID'
// 支付完成
export const PAYMENT_COMPLETED_PAGE_CODE = 'PAYMENT_COMPLETED'
// 支付成功
export const PAYMENT_SUCCESS_PAGE_CODE = 'PAYMENT_SUCCESS'


/**
 * 投保页面code
 */
// 投保
export const CLIENT_INSURE_PAGE_CODE = 'CLIENT_INSURE'
// 重新投保
export const CLIENT_REINSURE_PAGE_CODE = 'CLIENT_REINSURE'
// 立即投保-投保审核
export const BROKER_USER_CLIENT_INSURE_AUDIT_PAGE_CODE = 'BROKER_USER_CLIENT_INSURE_AUDIT'
// 立即投保-确认投保信息
export const CLIENT_CONFIRM_INSURE_INFO_PAGE_CODE = 'CLIENT_CONFIRM_INSURE_INFO'
// 投保-提交投保信息-提示页
export const CLIENT_SUBMIT_INSURE_INFO_PAGE_CODE = 'CLIENT_SUBMIT_INSURE_INFO'
// 投保-提交投保订单-提示页
export const CLIENT_SUBMIT_INSURE_ORDER_PAGE_CODE = 'CLIENT_SUBMIT_INSURE_ORDER'

/**
 * 消息中心
 */
// 消息中心-列表
export const MESSAGE_CENTER_LIST_PAGE_CODE = 'MESSAGE_CENTER_LIST'

/**
 * 账户设置/我的账户
 */
// 账户设置-个人信息
export const ACCOUNT_SET_PERSONAL_INFO_PAGE_CODE = 'ACCOUNT_SET_PERSONAL_INFO'
// 账户设置-账户安全
export const ACCOUNT_SECURITY_PAGE_CODE = 'ACCOUNT_SECURITY'
// 账户设置-账户安全-修改密码
export const ACCOUNT_SECURITY_UPDATE_PASSWORD_PAGE_CODE = 'ACCOUNT_SECURITY_UPDATE_PASSWORD'
// 账户设置-账户安全-修改手机号
export const ACCOUNT_SECURITY_UPDATE_MOBILE_PAGE_CODE = 'ACCOUNT_SECURITY_UPDATE_MOBILE'
// 账户设置-账户安全-账户注销
export const ACCOUNT_SECURITY_LOG_OFF_PAGE_CODE = 'ACCOUNT_SECURITY_LOG_OFF'
// 账户设置-发票抬头
export const INVOICE_HEADER_PAGE_CODE = 'INVOICE_HEADER'
// 账户设置-收件地址
export const RECEIVING_ADDRESS_PAGE_CODE = 'RECEIVING_ADDRESS'
/**
 * 我的工保
 */
export const PERSONAL_GB_PAGE_CODE = 'PERSONAL_GB'
/**
* 订单管理
*/
// 全部订单-订单列表
export const ALL_ORDER_ORDER_LIST_PAGE_CODE = 'ALL_ORDER_ORDER_LIST'
// 全部订单-订单详情
export const ALL_ORDER_ORDER_DETAIL_PAGE_CODE = 'ALL_ORDER_ORDER_DETAIL'
// 全部订单-申请退保
export const ALL_ORDER_APPLY_SURRENDER_PAGE_CODE = 'ALL_ORDER_APPLY_SURRENDER'
// 全部订单-订单评价
export const ALL_ORDER_ORDER_EVALUATION_PAGE_CODE = 'ALL_ORDER_ORDER_EVALUATION'

/**
 * 客户服务
*/
// 客户服务-投保咨询-列表
export const USER_INSURANCE_CONSULTATION_LIST_PAGE_CODE = 'USER_INSURANCE_CONSULTATION_LIST'
// 客户服务-投保咨询-详情
export const INSURANCE_CONSULTATION_DETAIL_PAGE_CODE = 'INSURANCE_CONSULTATION_DETAIL'
// 客户服务-经纪人咨询-列表
export const USER_BROKER_CONSULTATION_LIST_PAGE_CODE = 'USER_BROKER_CONSULTATION_LIST'
// 客户服务-经纪人咨询-详情
export const BROKER_CONSULTATION_DETAIL_PAGE_CODE = 'BROKER_CONSULTATION_DETAIL'
// 客户服务-发票管理-列表
export const INVOICE_MANAGE_LIST_PAGE_CODE = 'INVOICE_MANAGE_LIST'
// 客户服务-发票管理-详情
export const INVOICE_MANAGE_DETAIL_PAGE_CODE = 'INVOICE_MANAGE_DETAIL'
// 客户服务-发票管理-申请发票
export const INVOICE_MANAGE_APPLY_INVOICE_PAGE_CODE = 'INVOICE_MANAGE_APPLY_INVOICE'
// 客户服务-批改服务-列表
export const USER_CORRECTION_SERVICE_LIST_PAGE_CODE = 'USER_CORRECTION_SERVICE_LIST'
// 客户服务-批改服务-详情
export const USER_CORRECTION_SERVICE_DETAIL_PAGE_CODE = 'USER_CORRECTION_SERVICE_DETAIL'
// 客户服务-保单查询-保单列表
export const POLICY_SELECT_LIST_PAGE_CODE = 'POLICY_SELECT_LIST'

/**
 * 资料库
 */
// 资料库-资料列表-列表
export const DATA_BASE_LIST_PAGE_CODE = 'DATA_BASE_LIST'


