//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ConfigProvider as ALocaleProvider } from 'ant-design-vue'
import InsureNav from '@/components/GlobalInsureBar'
import CrmGlobalHeader from '@/components/CrmGlobalHeader'
import CrmGlobalFooter from '@/components/CrmGlobalFooter'

import moment from 'moment'

import 'moment/locale/zh-cn'

import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
moment.locale('zh-cn')

export default {
    name: 'CrmInsureLayoutStep3',
    components: {
        CrmGlobalHeader,
        CrmGlobalFooter,
        ALocaleProvider,
        InsureNav
    },
    data() {
        return {
            zh_CN
        }
    }
}
