//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Divider as ADivider
} from 'ant-design-vue'

import { getToken } from '@/utils/auth'
import privacyPhone from '@/mixins/privacyPhone'
export default {
    name: 'LeftBar',
    components: {
        ADivider
    },
    mixins: [privacyPhone],
    data() {
        return {}
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo
        },
        token() {
            return getToken()
        },
        getNickName() {
            const { userInfo } = this
            const { userExtends, mobile } = userInfo
            const { alias } = userExtends || {}
            // 产品反馈无昵称显示手机号
            let getNickName = alias ? alias : this.privacyPhone(mobile)
            return getNickName
        },
        // 是否展示登录
        showLogin() {
            const { userInfo } = this
            const { path, query } = this.$route || {}
            const { agentUserId } = query || {}
            let result = true
            if (!userInfo && (path === '/product/info' || path === '/cart') && agentUserId) {
                result = false
            } else {
                result = true
            }
            return result
        }
    },
    mounted() {
    },
    methods: {
        handleLogin() {
            this.$router.push({
                name: 'login'
            })
        },
        handleRegister() {
            this.$router.push({
                name: 'register'
            })
        },
        handleLogout() {
            this.$store.dispatch('logOut')
            // window.location.reload()
        }
    }
}
