//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'TabBar',
    data() {
        return {
            activeIndex: 0,
            // 年薪百万左侧的 tab list
            leftTabList: [
                {
                    name: '首页',
                    linkUrl: 'index',
                    index: 0
                }, {
                    name: '保险产品',
                    linkUrl: 'product',
                    index: 1
                }, {
                    name: '企业服务',
                    linkUrl: 'enterprise_service',
                    index: 2
                }
                // {
                //     name: '保险方案',
                //     linkUrl: 'quote',
                //     index: 2
                // }

            ],
            // 年薪百万右侧的 tab list
            rightTabList: [
                {
                    name: '工保百科',
                    linkUrl: 'wiki',
                    index: 3
                }, {
                    name: '新闻资讯',
                    linkUrl: 'news-list',
                    index: 4
                }
            ]
        }
    },
    methods: {
        // 获取 a标签 class类
        getAClass({ index, linkUrl }) {
            let cls = `is-${linkUrl}`
            if (this.activeIndex === index) {
                cls += ' active'
            }
            return cls
        },
        // 跳转至
        jumpTo(linkUrl, index) {
            this.activeIndex = index
            this.$router.push({
                name: linkUrl
            })
        },
        // 信息披露
        handleInfoDisclosure() {
            this.$router.push({
                name: 'info_disclosure'
            })
        }
    }
}
