//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ConfigProvider } from 'ant-design-vue'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'

import '@/assets/styles/share.css'
export default {
    name: 'ShareLayout',
    components: {
        AConfigProvider: ConfigProvider
    },
    data() {
        return {
            zh_CN
        }
    }
}
