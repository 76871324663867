import {
    USER_TYPE_BROKER, // 用户类型-枚举 -- 经纪端
    USER_TYPE_INSURER, // 用户类型-枚举 -- 保险端
    USER_TYPE_INSURED // 用户类型-枚举 -- 用户端
} from '@/constant/enum'
import {
    // 用户端
    USER_ORDER_MANAGE_MENU_CODE,
    USER_ORDER_MANAGE_ALL_ORDER_MENU_CODE,
    USER_CUSTOMER_SERVICE_MENU_CODE,
    USER_CUSTOMER_SERVICE_INSURANCE_MENU_CODE,
    USER_CUSTOMER_SERVICE_BROKER_MENU_CODE,
    USER_CUSTOMER_SERVICE_INVOICE_MENU_CODE,
    USER_CUSTOMER_SERVICE_CORRECT_MENU_CODE,
    USER_CUSTOMER_SERVICE_POLICY_MENU_CODE,
    USER_BASE_DATA_MENU_CODE,
    USER_BASE_DATA_LIST_MENU_CODE,

    // 经纪端
    BROKER_ORDER_MANAGE_MENU_CODE,
    BROKER_ORDER_MANAGE_ALL_ORDER_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_INSURANCE_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_BROKER_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_INVOICE_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_CORRECT_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_POLICY_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_INVITE_USER_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_INVITE_BROKER_MENU_CODE,
    BROKER_CUSTOMER_SERVICE_PROMOTE_GUEST_MENU_CODE,
    BROKER_QUOTATION_MENU_CODE,
    BROKER_QUOTATION_TOOL_MENU_CODE,
    BROKER_BASE_DATA_MENU_CODE,
    BROKER_BASE_DATA_LIST_MENU_CODE,

    // 保险端
    INSURER_ORDER_MANAGE_MENU_CODE,
    INSURER_ORDER_MANAGE_INSURE_ORDER_MENU_CODE,
    INSURER_CUSTOMER_SERVICE_MENU_CODE,
    INSURER_CUSTOMER_SERVICE_INVOICE_MENU_CODE,
    INSURER_CUSTOMER_SERVICE_CORRECT_MENU_CODE,
    INSURER_BASE_DATA_MENU_CODE,
    INSURER_BASE_DATA_LIST_MENU_CODE
} from '@/constant/menuEnum'

export const SideMenuFactory = {
    // 经纪端
    [USER_TYPE_BROKER]: [
        // 经纪端 - 订单管理 - start
        {
            id: '1',
            hidden: false,
            menuName: '订单管理',
            iconImg: require('@/assets/images/user_center/order-manage.png'),
            code: BROKER_ORDER_MANAGE_MENU_CODE,
            treeList: [
                {
                    id: '1-1',
                    hidden: false,
                    menuName: '全部订单',
                    component: 'order-list',
                    link: '/order/list',
                    code: BROKER_ORDER_MANAGE_ALL_ORDER_MENU_CODE
                },
                {
                    id: '1-2',
                    hidden: true,
                    menuName: '',
                    component: 'order-detail',
                    link: '/order/detail'
                },
                {
                    id: '1-3',
                    hidden: true,
                    menuName: '',
                    component: 'order-surrender',
                    link: '/order/surrender'
                },
                {
                    id: '1-4',
                    hidden: true,
                    menuName: '',
                    component: 'order-evaluation',
                    link: '/order/evaluation'
                }
            ]
        },
        // 经纪端 - 订单管理 - end
        // 经纪端 - 客户服务 - start
        {
            id: '2',
            hidden: false,
            menuName: '客户服务',
            iconImg: require('@/assets/images/user_center/customer-service.png'),
            code: BROKER_CUSTOMER_SERVICE_MENU_CODE,
            treeList: [
                // 投保咨询 - start
                {
                    id: '2-1',
                    hidden: false,
                    menuName: '投保咨询',
                    component: 'user-broker-service_insure',
                    link: '/user/broker/service_insure',
                    code: BROKER_CUSTOMER_SERVICE_INSURANCE_MENU_CODE
                },
                {
                    id: '2-2',
                    hidden: true,
                    menuName: '',
                    component: 'service_insure-detail',
                    link: '/service_insure/detail'
                },
                // 投保咨询 - end

                // 经纪人咨询 - start
                {
                    id: '2-3',
                    hidden: false,
                    menuName: '经纪人咨询',
                    component: 'user-broker-service_broker',
                    link: '/user/broker/service_broker',
                    code: BROKER_CUSTOMER_SERVICE_BROKER_MENU_CODE
                },
                {
                    id: '2-4',
                    hidden: true,
                    menuName: '',
                    component: 'service_broker-detail',
                    link: '/service_broker/detail'
                },
                // 经纪人咨询 - end

                // 发票管理 - start
                {
                    id: '2-5',
                    hidden: false,
                    menuName: '发票管理',
                    component: 'user-insured-invoice_manage-list',
                    link: '/user/insured/invoice_manage/list',
                    code: BROKER_CUSTOMER_SERVICE_INVOICE_MENU_CODE
                },
                {
                    id: '2-6',
                    hidden: true,
                    menuName: '',
                    component: 'user-insured-invoice_manage-info',
                    link: '/user/insured/invoice_manage/info'
                },
                {
                    id: '2-7',
                    hidden: true,
                    menuName: '',
                    component: 'user-insured-invoice_manage-apply_invoice',
                    link: '/user/insured/invoice_manage/apply_invoice'
                },
                // 发票管理 - end

                // 批改服务 - start
                {
                    id: '2-8',
                    hidden: false,
                    menuName: '批改服务',
                    component: 'user-broker-correct_service-list',
                    link: '/user/broker/correct_service/list',
                    code: BROKER_CUSTOMER_SERVICE_CORRECT_MENU_CODE
                },
                {
                    id: '2-9',
                    hidden: true,
                    menuName: '',
                    component: 'user-broker-correct_service-info',
                    link: '/user/broker/correct_service/info'
                },
                // 批改服务 - end

                // 保单查询 - start
                {
                    id: '2-10',
                    hidden: false,
                    menuName: '保单查询',
                    component: 'policy-list',
                    link: '/policy/list',
                    code: BROKER_CUSTOMER_SERVICE_POLICY_MENU_CODE
                },
                // 保单查询 - end

                // 邀请用户 - start
                // {
                //     id: '2-11',
                //     hidden: false,
                //     menuName: '邀请用户',
                //     component: 'user-broker-invite_users',
                //     link: '/user/broker/invite_users',
                //     code: BROKER_CUSTOMER_SERVICE_INVITE_USER_MENU_CODE
                // },
                // 邀请用户 - end

                // 获客推广 - start
                {
                    id: '2-11',
                    hidden: false,
                    menuName: '推广获客',
                    component: 'user-broker-promote_guest-list',
                    link: '/user/broker/promote_guest/list',
                    code: BROKER_CUSTOMER_SERVICE_PROMOTE_GUEST_MENU_CODE
                },
                // 获客推广 - end

                // 邀请经纪人 - start
                {
                    id: '2-12',
                    hidden: false,
                    menuName: '邀请经纪人',
                    component: 'user-broker-invite_brokers',
                    link: '/user/broker/invite_brokers',
                    code: BROKER_CUSTOMER_SERVICE_INVITE_BROKER_MENU_CODE
                }
                // 邀请经纪人 - end

            ]
        },
        // 经纪端 - 客户服务 - end
        // 经纪端 - 报价工具 - start
        {
            id: '3',
            hidden: false,
            menuName: '报价工具',
            iconImg: require('@/assets/images/user_center/order-manage.png'),
            code: BROKER_QUOTATION_MENU_CODE,
            treeList: [
                {
                    id: '3-1',
                    hidden: false,
                    menuName: '报价工具',
                    component: 'user-broker-quotation_tool',
                    link: '/user/broker/quotation_tool',
                    code: BROKER_QUOTATION_TOOL_MENU_CODE
                }
            ]
        },
        // 经纪端 - 报价工具 - end

        // 经纪端 - 资料库 - start
        {
            id: '4',
            hidden: false,
            menuName: '资料库',
            iconImg: require('@/assets/images/data_base/data_base_icon@1x.png'),
            code: BROKER_BASE_DATA_MENU_CODE,
            treeList: [
                {
                    id: '4-1',
                    hidden: false,
                    menuName: '资料列表',
                    component: 'data_base-list',
                    link: '/data_base/list',
                    code: BROKER_BASE_DATA_LIST_MENU_CODE
                }
            ]
        }
        // 经纪端 - 资料库 - end
    ],

    // 保险端
    [USER_TYPE_INSURER]: [
        // 保险端 - 订单管理 - start
        {
            id: '1',
            hidden: false,
            menuName: '订单管理',
            iconImg: require('@/assets/images/user_center/order-manage.png'),
            code: INSURER_ORDER_MANAGE_MENU_CODE,
            treeList: [
                {
                    id: '1-1',
                    hidden: false,
                    menuName: '投保订单',
                    component: 'user-insurer-order',
                    link: '/user/insurer/order',
                    code: INSURER_ORDER_MANAGE_INSURE_ORDER_MENU_CODE
                },
                {
                    id: '1-2',
                    hidden: true,
                    menuName: '',
                    component: 'user-insurer-order-detail',
                    link: '/user/insurer/order/detail'
                },
                {
                    id: '1-3',
                    hidden: true,
                    menuName: '',
                    component: 'user-insurer-order-setting',
                    link: '/user/insurer/order/setting'
                },
                {
                    id: '1-4',
                    hidden: true,
                    menuName: '投保审核',
                    component: 'user-insurer-audit',
                    link: '/user/insurer/audit'
                },
                {
                    id: '1-5',
                    hidden: true,
                    menuName: '补充资料审核',
                    component: 'user-insurer-order-supplement_audit',
                    link: '/user/insurer/order/supplement_audit'
                },
                {
                    id: '1-6',
                    hidden: true,
                    menuName: '邮件发送',
                    component: 'user-insurer-order-send_mail',
                    link: '/user/insurer/order/send_mail'
                }
            ]
        },
        // 保险端 - 订单管理 - end

        // 保险端 - 客户服务 - start
        {
            id: '2',
            hidden: false,
            menuName: '客户服务',
            iconImg: require('@/assets/images/user_center/customer-service.png'),
            code: INSURER_CUSTOMER_SERVICE_MENU_CODE,
            treeList: [
                // 发票管理 - start
                {
                    id: '2-1',
                    hidden: false,
                    menuName: '发票管理',
                    component: 'user-insurer-invoice_manage-list',
                    link: '/user/insurer/invoice_manage/list',
                    code: INSURER_CUSTOMER_SERVICE_INVOICE_MENU_CODE
                },
                {
                    id: '2-2',
                    hidden: true,
                    menuName: '',
                    component: 'user-insurer-invoice_manage-info',
                    link: '/user/insurer/invoice_manage/info'
                },
                // 发票管理 - end

                {
                    id: '2-3',
                    hidden: false,
                    menuName: '批改服务',
                    component: 'user-insurer-correct_service-list',
                    link: '/user/insurer/correct_service/list',
                    code: INSURER_CUSTOMER_SERVICE_CORRECT_MENU_CODE
                },
                {
                    id: '2-4',
                    hidden: true,
                    menuName: '',
                    component: 'user-insurer-correct_service-info',
                    link: '/user/insurer/correct_service/info'
                }
            ]
        },
        // 保险端 - 客户服务 - end

        // 保险端 - 资料库 - start
        {
            id: '3',
            hidden: false,
            menuName: '资料库',
            iconImg: require('@/assets/images/data_base/data_base_icon@1x.png'),
            code: INSURER_BASE_DATA_MENU_CODE,
            treeList: [
                {
                    id: '3-1',
                    hidden: false,
                    menuName: '资料列表',
                    component: 'data_base-list',
                    link: '/data_base/list',
                    code: INSURER_BASE_DATA_LIST_MENU_CODE
                }
            ]
        }
        // 保险端 - 资料库 - end
    ],

    // 用户端
    [USER_TYPE_INSURED]: [
        // 用户端 - 订单管理 - start
        {
            id: '1',
            hidden: false,
            menuName: '订单管理',
            iconImg: require('@/assets/images/user_center/order-manage.png'),
            code: USER_ORDER_MANAGE_MENU_CODE,
            treeList: [
                {
                    id: '1-1',
                    hidden: false,
                    menuName: '全部订单',
                    component: 'order-list',
                    link: '/order/list',
                    code: USER_ORDER_MANAGE_ALL_ORDER_MENU_CODE
                },
                {
                    id: '1-2',
                    hidden: true,
                    menuName: '',
                    component: 'order-detail',
                    link: '/order/detail'
                },
                {
                    id: '1-3',
                    hidden: true,
                    menuName: '',
                    component: 'order-surrender',
                    link: '/order/surrender'
                },
                {
                    id: '1-4',
                    hidden: true,
                    menuName: '',
                    component: 'order-evaluation',
                    link: '/order/evaluation'
                }
            ]
        },
        // 用户端 - 订单管理 - end
        // 用户端 - 客户服务 - start
        {
            id: '2',
            hidden: false,
            menuName: '客户服务',
            iconImg: require('@/assets/images/user_center/customer-service.png'),
            code: USER_CUSTOMER_SERVICE_MENU_CODE,
            treeList: [
                // 投保咨询 - start
                {
                    id: '2-1',
                    hidden: false,
                    menuName: '投保咨询',
                    component: 'service_insure',
                    link: '/service_insure',
                    code: USER_CUSTOMER_SERVICE_INSURANCE_MENU_CODE
                },
                {
                    id: '2-2',
                    hidden: true,
                    menuName: '',
                    component: 'service_insure-detail',
                    link: '/service_insure/detail'
                },
                // 投保咨询 - end

                // 经纪人咨询 - start
                {
                    id: '2-3',
                    hidden: false,
                    menuName: '经纪人咨询',
                    component: 'service_broker',
                    link: '/service_broker',
                    code: USER_CUSTOMER_SERVICE_BROKER_MENU_CODE
                },
                {
                    id: '2-4',
                    hidden: true,
                    menuName: '',
                    component: 'service_broker-detail',
                    link: '/service_broker/detail'
                },
                // 经纪人咨询 - end

                // 发票管理 - start
                {
                    id: '2-5',
                    hidden: false,
                    menuName: '发票管理',
                    component: 'user-insured-invoice_manage-list',
                    link: '/user/insured/invoice_manage/list',
                    code: USER_CUSTOMER_SERVICE_INVOICE_MENU_CODE
                },
                {
                    id: '2-6',
                    hidden: true,
                    menuName: '',
                    component: 'user-insured-invoice_manage-info',
                    link: '/user/insured/invoice_manage/info'
                },
                {
                    id: '2-7',
                    hidden: true,
                    menuName: '',
                    component: 'user-insured-invoice_manage-apply_invoice',
                    link: '/user/insured/invoice_manage/apply_invoice'
                },
                // 发票管理 - end

                // 批改服务 - start
                {
                    id: '2-8',
                    hidden: false,
                    menuName: '批改服务',
                    component: 'user-insured-correct_service-list',
                    link: '/user/insured/correct_service/list',
                    code: USER_CUSTOMER_SERVICE_CORRECT_MENU_CODE
                },
                {
                    id: '2-9',
                    hidden: true,
                    menuName: '',
                    component: 'user-insured-correct_service-info',
                    link: '/user/insured/correct_service/info'
                },
                // 批改服务 - end

                // 保单查询 - start
                {
                    id: '2-10',
                    hidden: false,
                    menuName: '保单查询',
                    component: 'policy-list',
                    link: '/policy/list',
                    code: USER_CUSTOMER_SERVICE_POLICY_MENU_CODE
                }
                // 保单查询 - end
            ]
        },
        // 用户端 - 客户服务 - end

        // 用户端 - 资料库 - start
        {
            id: '3',
            hidden: false,
            menuName: '资料库',
            iconImg: require('@/assets/images/data_base/data_base_icon@1x.png'),
            code: USER_BASE_DATA_MENU_CODE,
            treeList: [
                {
                    id: '3-1',
                    hidden: false,
                    menuName: '资料列表',
                    component: 'data_base-list',
                    link: '/data_base/list',
                    code: USER_BASE_DATA_LIST_MENU_CODE
                }
            ]
        }
        // 用户端 - 资料库 - end
    ]
}
