/**
 * 保险端页面权限code(无投保)
 * @type {string}
 */
/**
 * 购物车页面code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 购物车-列表
export const SHOPPING_CART_LIST_PAGE_CODE = 'SHOPPING_CART_LIST'
// 购物车-去结算
export const SHOPPING_CART_SETTLE_PAGE_CODE = 'SHOPPING_CART_SETTLE'

/**
 * 支付
 */
// 立即支付
export const IMMEDIATE_PAYMENT_PAGE_CODE = 'IMMEDIATE_PAYMENT'
// 已支付
export const PAYMENT_PAID_PAGE_CODE = 'PAYMENT_PAID'
// 支付完成
export const PAYMENT_COMPLETED_PAGE_CODE = 'PAYMENT_COMPLETED'
// 支付成功
export const PAYMENT_SUCCESS_PAGE_CODE = 'PAYMENT_SUCCESS'


/**
 * 消息中心
 */
// 消息中心-列表
export const MESSAGE_CENTER_LIST_PAGE_CODE = 'MESSAGE_CENTER_LIST'

/**
 * 账户设置/我的账户
 */
// 账户设置-个人信息
export const ACCOUNT_SET_PERSONAL_INFO_PAGE_CODE = 'ACCOUNT_SET_PERSONAL_INFO'
// 账户设置-账户安全
export const ACCOUNT_SECURITY_PAGE_CODE = 'ACCOUNT_SECURITY'
// 账户设置-账户安全-修改密码
export const ACCOUNT_SECURITY_UPDATE_PASSWORD_PAGE_CODE = 'ACCOUNT_SECURITY_UPDATE_PASSWORD'
// 账户设置-账户安全-修改手机号
export const ACCOUNT_SECURITY_UPDATE_MOBILE_PAGE_CODE = 'ACCOUNT_SECURITY_UPDATE_MOBILE'
// 账户设置-账户安全-账户注销
export const ACCOUNT_SECURITY_LOG_OFF_PAGE_CODE = 'ACCOUNT_SECURITY_LOG_OFF'
// 账户设置-发票抬头
export const INVOICE_HEADER_PAGE_CODE = 'INVOICE_HEADER'
// 账户设置-收件地址
export const RECEIVING_ADDRESS_PAGE_CODE = 'RECEIVING_ADDRESS'
/**
 * 我的工保
 */
export const PERSONAL_GB_PAGE_CODE = 'PERSONAL_GB'
/**
* 订单管理
*/
// 投保订单-列表
export const INSURANCE_ORDER_LIST_PAGE_CODE = 'INSURANCE_ORDER_LIST'
// 投保订单-订单详情
export const INSURANCE_ORDER_DETAIL_PAGE_CODE = 'INSURANCE_ORDER_DETAIL'
// 配置保单信息（已完成文字为更新保单信息）/配置支付方式
export const INSURANCE_SETTING_ABOUT_PAGE_CODE = 'INSURANCE_SETTING_ABOUT'
// 保险审核
export const INSURANCE_INSURER_AUDIT_PAGE_CODE = 'INSURANCE_INSURER_AUDIT'
// 补充资料审核
export const INSURANCE_SUPPLEMENT_AUDIT_PAGE_CODE = 'INSURANCE_SUPPLEMENT_AUDIT'
// 邮件发送
export const INSURANCE_INVOICE_SEND_MAIL_PAGE_CODE = 'INSURANCE_INVOICE_SEND_MAIL'


/**
 * 客户服务
*/
// 发票管理-发票列表
export const INSURANCE_INVOICE_LIST_PAGE_CODE = 'INSURANCE_INVOICE_LIST'
// 发票管理-发票详情
export const INSURANCE_INVOICE_DETAIL_PAGE_CODE = 'INSURANCE_INVOICE_DETAIL'
// 批改服务-批改列表
export const INSURANCE_CORRECTION_LIST_PAGE_CODE = 'INSURANCE_CORRECTION_LIS'
// 批改服务-批改详情
export const INSURANCE_CORRECTION_DETAIL_PAGE_CODE = 'INSURANCE_CORRECTION_DETAIL'

/**
 * 资料库
 */
// 资料库-资料列表-列表
export const DATA_BASE_LIST_PAGE_CODE = 'DATA_BASE_LIST'
