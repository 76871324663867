//
//
//
//
//
//
//
//
//
//
//

import Left from './Left'
import Right from './Right'
export default {
    name: 'GlobalInsureBar',
    components: {
        Left,
        Right
    },
    props: {
        current: {
            type: Number

        }
    }
}
