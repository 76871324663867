//
//
//
//
//
//
//
//

export default {
    name: 'RightBar'
}
