/**
 * 前端枚举维护
 * ————————————————————————————————————————————————————————————————————————————————————————————————————————————————
 **/

/**
 * 文件上传-阿里云oss目录
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */

/**
 * 阿里云oss目录
 * @type {string}
 */
// 阿里云oss目录 —— 产品
export const FILE_UPLOAD_DIRECTORY_INSURANCE = 'insurance/'
// 阿里云oss目录 —— 投保审核-补充资料
export const FILE_UPLOAD_DIRECTORY_INSURE_AUDIT_DOCUMENT =
    'additional-information/'

// 阿里云oss目录 —— 资料库
export const FILE_UPLOAD_DIRECTORY_DATA_BASE = 'attachments/'

// 阿里云oss目录 —— 邮件发送
export const FILE_UPLOAD_DIRECTORY_SEND_MAIL = 'custom/'

/**
 * 用户权限 枚举
 * @type {Number}
 */
// 用户权限 -- 菜单权限
export const PERMISSION_TYPE_MENU = 1
// 用户权限 -- 菜单权限
export const PERMISSION_TYPE_BUTTON = 2

/**
 * 用户模块 枚举
 * ————————————————————————————————————————————————————————————————————————————————————————————————————————————————
 **/
/**
 * 用户类型
 * @type {Number}
 */
// 用户类型-枚举 -- 经纪端
export const USER_TYPE_BROKER = 1
// 用户类型-枚举 -- 渠道端
export const USER_TYPE_CHANNEL = 2
// 用户类型-枚举 -- 用户端
export const USER_TYPE_INSURED = 3
// 用户类型-枚举 -- 保险端
export const USER_TYPE_INSURER = 4

// 用户-订单列表-用户评价
// 评价最低分数
export const EVALUATION_MIN_SCORE = 1

/**
 * 用户-订单列表-订单筛选状态 枚举
 * @type {Number}
 */
// 用户-订单列表-订单筛选状态 -- 全部
export const USER_ORDER_STATUS_ALL_SELECT = ''
export const USER_ORDER_STATUS_ALL_ENUM_SELECT = {
    label: '全部',
    value: USER_ORDER_STATUS_ALL_SELECT
}
// 用户-订单列表-订单筛选状态 -- 待支付
export const USER_ORDER_STATUS_WAIT_PAY_SELECT = 1
export const USER_ORDER_STATUS_WAIT_PAY_ENUM_SELECT = {
    label: '待支付',
    value: USER_ORDER_STATUS_WAIT_PAY_SELECT
}
// 用户-订单列表-订单筛选状态 -- 已支付
export const USER_ORDER_STATUS_PAID_SELECT = 3
export const USER_ORDER_STATUS_PAID_ENUM_SELECT = {
    label: '已支付',
    value: USER_ORDER_STATUS_PAID_SELECT
}
export const USER_ORDER_STATUS_ENUM_LIST_SELECT = [USER_ORDER_STATUS_ALL_ENUM_SELECT, USER_ORDER_STATUS_WAIT_PAY_ENUM_SELECT, USER_ORDER_STATUS_PAID_ENUM_SELECT]

/**
 * 用户-订单列表-订单搜索筛选 枚举 (前端自定义value值)
 * @type {Number}
 */
export const ORDER_NUMBER_SELECT_SEARCH = 1
export const ORDER_PROJECT_SELECT_SEARCH = 2
export const ORDER_COMPANY_SELECT_SEARCH = 3
export const ORDER_SELECT_SEARCH_LIST = [
    {
        label: '项目名称',
        value: ORDER_NUMBER_SELECT_SEARCH
    }, {
        label: '投保单号',
        value: ORDER_PROJECT_SELECT_SEARCH
    }, {
        label: '投保人名称',
        value: ORDER_COMPANY_SELECT_SEARCH
    }
]

/**
 * 订单列表-发票搜索筛选 枚举 (前端自定义value值)
 * @type {Number}
 */
export const INVOICE_PRODUCT_NAME_SEARCH = 1
export const INVOICE_APPLICANT_NAME_SEARCH = 2
export const INVOICE_POLICY_NUMBER_SEARCH = 3
export const INVOICE_APPLICANT_NUMBER_SEARCH = 4
export const INVOICE_LIST_SELECT = [
    {
        label: '项目名称',
        value: INVOICE_PRODUCT_NAME_SEARCH
    },
    {
        label: '投保人名称',
        value: INVOICE_APPLICANT_NAME_SEARCH
    },
    {
        label: '保单号',
        value: INVOICE_POLICY_NUMBER_SEARCH
    },
    {
        label: '投保单号',
        value: INVOICE_APPLICANT_NUMBER_SEARCH
    }
]

/**
 * 订单列表-保险端搜索筛选 枚举 (前端自定义value值)
 * @type {Number}
 */
export const INSURANCE_ORDER_APPLICANT_NUMBER_SEARCH = 1
export const INSURANCE_ORDER_INSURED_PERSON_SEARCH = 2
export const INSURANCE_ORDER_APPLICANT_NAME_SEARCH = 3
export const INSURANCE_ORDER_LIST_SELECT = [
    {
        label: '投保单号',
        value: INSURANCE_ORDER_APPLICANT_NUMBER_SEARCH
    }, {
        label: '被保人名称',
        value: INSURANCE_ORDER_INSURED_PERSON_SEARCH
    }, {
        label: '投保人名称',
        value: INSURANCE_ORDER_APPLICANT_NAME_SEARCH
    }
]
/**
 * 用户-订单列表-订单状态 枚举
 * @type {Number}
 */
// 用户-订单列表-订单状态 -- 待定
export const USER_ORDER_STATUS_PENDING = 0
export const USER_ORDER_STATUS_PENDING_ENUM = {
    label: '待定',
    value: USER_ORDER_STATUS_PENDING
}
// 用户-订单列表-订单状态 -- 待支付
export const USER_ORDER_STATUS_SUBMIT = 1
export const USER_ORDER_STATUS_SUBMIT_ENUM = {
    label: '待支付',
    value: USER_ORDER_STATUS_SUBMIT
}
// 用户-订单列表-订单状态 -- 支付中
export const USER_ORDER_STATUS_IN = 2
export const USER_ORDER_STATUS_IN_ENUM = {
    label: '支付中',
    value: USER_ORDER_STATUS_IN
}
// 用户-订单列表-订单状态 -- 支付成功
export const USER_ORDER_STATUS_DONE = 3
export const USER_ORDER_STATUS_DONE_ENUM = {
    label: '支付成功',
    value: USER_ORDER_STATUS_DONE
}
// 用户-订单列表-订单状态 -- 支付失败
export const USER_ORDER_STATUS_FAIL = 4
export const USER_ORDER_STATUS_FAIL_ENUM = {
    label: '支付失败',
    value: USER_ORDER_STATUS_FAIL
}
// 用户-订单列表-订单状态 -- 支付退款
export const USER_ORDER_STATUS_REFUND = 5
export const USER_ORDER_STATUS_REFUND_ENUM = {
    label: '支付退款',
    value: USER_ORDER_STATUS_REFUND
}
// 用户-订单列表-订单状态 -- 支付关闭
export const USER_ORDER_STATUS_OFF = 6
export const USER_ORDER_STATUS_OFF_ENUM = {
    label: '支付关闭',
    value: USER_ORDER_STATUS_OFF
}
// 用户-订单列表-订单状态 -- 订单取消
export const USER_ORDER_STATUS_CANCEL = 7
export const USER_ORDER_STATUS_CANCEL_ENUM = {
    label: '订单取消',
    value: USER_ORDER_STATUS_CANCEL
}
export const USER_ORDER_STATUS_ENUM_LIST = [
    USER_ORDER_STATUS_PENDING_ENUM,
    USER_ORDER_STATUS_SUBMIT_ENUM,
    USER_ORDER_STATUS_IN_ENUM,
    USER_ORDER_STATUS_DONE_ENUM,
    USER_ORDER_STATUS_OFF_ENUM,
    USER_ORDER_STATUS_FAIL_ENUM,
    USER_ORDER_STATUS_REFUND_ENUM,
    USER_ORDER_STATUS_CANCEL_ENUM
]

/**
 * 保险端-投保订单状态枚举
 * @type {Number}
 */
// 保险端-投保订单状态 -- 全部订单
export const INSURE_ORDER__ALL = ''
// 保险端-投保订单状态 -- 待审核
export const INSURE_ORDER_WAIT_VERIFY = 1
// 保险端-投保订单状态 -- 待补充
export const INSURE_ORDER_WAIT_AMENDS = 20
// 保险端-投保订单状态 -- 待支付
export const INSURE_ORDER_WAIT_PAY = 5
// 保险端-投保订单状态 -- 待出单
export const INSURE_ORDER_WAIT_ISSUE = 6
// 保险端-投保订单状态 -- 已完成
export const INSURE_ORDER_WAIT_FINISHED = 7
// 保险端-投保订单状态 -- 已关闭
export const INSURE_ORDER_CLOSED = 8
// 订单管理-订单状态枚举
export const INSURE_ORDER_STATE_ENUM_LIST = [
    {
        label: '全部订单',
        value: INSURE_ORDER__ALL
    },
    {
        label: '待审核',
        value: INSURE_ORDER_WAIT_VERIFY
    },
    {
        label: '待补充',
        value: INSURE_ORDER_WAIT_AMENDS
    },
    {
        label: '待支付',
        value: INSURE_ORDER_WAIT_PAY
    },
    {
        label: '待出单',
        value: INSURE_ORDER_WAIT_ISSUE
    },
    {
        label: '已完成',
        value: INSURE_ORDER_WAIT_FINISHED
    },
    {
        label: '已关闭',
        value: INSURE_ORDER_CLOSED
    }
]

/**
 * 客户服务-投保咨询状态枚举
 * @type {Number}
 */

// 客户服务-投保咨询状态 -- 全部咨询
export const CUSTOMER_SERVICE_INSURANCE_ALL = ''
// 客户服务-投保咨询状态 -- 取消
export const CUSTOMER_SERVICE_INSURANCE_CANCEL = -1
// 客户服务-投保咨询状态 -- 待处理
export const CUSTOMER_SERVICE_INSURANCE_PENDING = 0
// 客户服务-投保咨询状态 -- 处理中
export const CUSTOMER_SERVICE_INSURANCE_IN_PROCESS = 1
// 客户服务-投保咨询状态 -- 已完成
export const CUSTOMER_SERVICE_INSURANCE_COMPLETED = 2
// 客户服务-投保咨询状态 -- 已关闭
export const CUSTOMER_SERVICE_INSURANCE_CLOSED = 3
// 客户服务-投保咨询状态枚举
export const CUSTOMER_SERVICE_INSURANCE_STATE_ENUM_LIST = [
    {
        label: '取消',
        value: CUSTOMER_SERVICE_INSURANCE_CANCEL
    },
    {
        label: '全部咨询',
        value: CUSTOMER_SERVICE_INSURANCE_ALL
    },
    // {
    //     label: '待处理',
    //     value: CUSTOMER_SERVICE_INSURANCE_PENDING
    // },
    {
        label: '处理中',
        value: CUSTOMER_SERVICE_INSURANCE_IN_PROCESS
    },
    {
        label: '已完成',
        value: CUSTOMER_SERVICE_INSURANCE_COMPLETED
    },
    {
        label: '已关闭',
        value: CUSTOMER_SERVICE_INSURANCE_CLOSED
    }
]


/**
 * 客户服务-推广获客-搜索 枚举 (前端自定义value值)
 * @type {Number}
 */
export const PROMOTE_TITLE_SEARCH = 1
export const PROMOTE_POLICY_NAME_SEARCH = 2
export const PROMOTE_POLICY_TYPE_SEARCH = 3
export const PROMOTE_LIST_SELECT = [
    {
        label: '标题',
        value: PROMOTE_TITLE_SEARCH
    }, {
        label: '表单名称',
        value: PROMOTE_POLICY_NAME_SEARCH
    }, {
        label: '表单类型',
        value: PROMOTE_POLICY_TYPE_SEARCH
    }
]

/**
 * 客户服务-推广获客-表单类型 枚举
 * @type {Boolean}
 */
// 表单类型 枚举 —— 信息收集-单险种
export const PROMOTE_FORM_TYPE_INFO_SINGLE = '0'
// 表单类型 枚举 —— 信息收集-多险种
export const PROMOTE_FORM_TYPE_INFO_MULTIPLE = '1'
// 表单类型 枚举 —— 投保-单险种
export const PROMOTE_FORM_TYPE_INSURE_SINGLE = '2'
// 表单类型 枚举 —— 投保-多险种
export const PROMOTE_FORM_TYPE_INSURE_MULTIPLE = '3'

// 表单类型 枚举 枚举列表
export const PROMOTE_FORM_TYPE_ENUM_LIST = [
    {
        label: '信息收集-单险种',
        value: PROMOTE_FORM_TYPE_INFO_SINGLE
    },
    {
        label: '信息收集-多险种',
        value: PROMOTE_FORM_TYPE_INFO_MULTIPLE
    },
    {
        label: '投保-单险种',
        value: PROMOTE_FORM_TYPE_INSURE_SINGLE
    },
    {
        label: '投保-多险种',
        value: PROMOTE_FORM_TYPE_INSURE_MULTIPLE
    }
]


/**
 * 发票模块相关枚举
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
/**
 * 发票类型 枚举
 * @type {Number}
 */
// 发票类型 枚举 —— 电子发票
export const INVOICE_TYPE_ELECTRONIC = '电子发票'
// 发票类型 枚举 —— 纸质发票
export const INVOICE_TYPE_PAPER = '纸质发票'
// 发票类型枚举
export const INVOICE_TYPE_ENUM_LIST = [
    {
        label: INVOICE_TYPE_ELECTRONIC,
        value: 0
    }, {
        label: INVOICE_TYPE_PAPER,
        value: 1
    }
]
/**
 * 发票类型 枚举
 * @type {Number}
 */
// 发票类型 枚举 —— 电子发票
export const INVOICE_TYPE_ELECTRONIC_CONVERT = 0
// 发票类型 枚举 —— 纸质发票
export const INVOICE_TYPE_PAPER_CONVERT = 1
// 发票类型枚举
export const INVOICE_TYPE_CONVERT_ENUM_LIST = [
    {
        label: INVOICE_TYPE_ELECTRONIC_CONVERT,
        value: '电子发票'
    }, {
        label: INVOICE_TYPE_PAPER_CONVERT,
        value: '纸质发票'
    }
]
/**
 * 发票状态 枚举（保险端）
 * @type {Number}
 */
// 发票状态 枚举 —— 未出票
export const INVOICE_STATE_TICKET_NOT = '未出票'
// 发票状态 枚举 —— 发票完成
export const INVOICE_STATE_FINISHED = '发票完成'
// 发票状态 枚举 —— 拒绝发票
export const INVOICE_STATE_REFUSE = '拒绝发票'

// 发票状态 枚举 —— 未出票
export const INVOICE_STATE_TICKET_NOT_VALUE = 1
// 发票状态 枚举 —— 发票完成
export const INVOICE_STATE_FINISHED_VALUE = 3
// 发票状态 枚举 —— 拒绝发票
export const INVOICE_STATE_REFUSE_VALUE = 4
// 发票状态枚举
export const INVOICE_STATE_ENUM_LIST_INSURE = [
    {
        label: INVOICE_STATE_TICKET_NOT,
        value: INVOICE_STATE_TICKET_NOT_VALUE
    }, {
        label: INVOICE_STATE_FINISHED,
        value: INVOICE_STATE_FINISHED_VALUE
    }, {
        label: INVOICE_STATE_REFUSE,
        value: INVOICE_STATE_REFUSE_VALUE
    }
]
/**
 * 发票状态 枚举（用户端/经纪端）
 * @type {Number}
 */
// 发票状态 枚举 —— 待申请
export const INVOICE_STATE_PENDING_APPLY = '待申请'
// 发票状态 枚举 —— 已申请
export const INVOICE_STATE_APPLIED = '已申请'
// 发票状态 枚举 —— 待开票
export const INVOICE_STATE_PENDING_INVOICE = '待开票'
// 发票状态 枚举 —— 已开票
export const INVOICE_STATE_TICKETED = '已开票'
// 发票状态 枚举 —— 开票失败
export const INVOICE_STATE_FAILED = '开票失败'
// 发票状态 枚举 —— 待申请
export const INVOICE_STATE_PENDING_APPLY_VALUE = 0
// 发票状态 枚举 —— 已申请
export const INVOICE_STATE_APPLIED_VALUE = 1
// 发票状态 枚举 —— 待开票
export const INVOICE_STATE_PENDING_INVOICE_VALUE = 2
// 发票状态 枚举 —— 已开票
export const INVOICE_STATE_TICKETED_VALUE = 3
// 发票状态 枚举 —— 开票失败
export const INVOICE_STATE_FAILED_VALUE = 4
// 发票状态枚举
export const INVOICE_STATE_ENUM_LIST = [
    {
        label: INVOICE_STATE_PENDING_APPLY,
        value: 0
    },
    {
        label: INVOICE_STATE_APPLIED,
        value: 1
    },
    {
        label: INVOICE_STATE_PENDING_INVOICE,
        value: 2
    },
    {
        label: INVOICE_STATE_TICKETED,
        value: 3
    },
    {
        label: INVOICE_STATE_FAILED,
        value: 4
    }
]
/**
 * 订单状态 枚举
 * @type {Number}
 */
// 订单状态 枚举 —— 订单生成
export const INVOICE_ORDER_STATUS_GENERATE = '订单生成'
// 订单状态 枚举 —— 订单完成
export const INVOICE_ORDER_STATUS_COMPLETE = '订单完成'
// 订单状态 枚举 —— 发票开具
export const INVOICE_ORDER_STATUS_INVOICED = '发票开具'
// 订单状态枚举
export const INVOICE_ORDER_STATUS_ENUM_LIST = [
    {
        label: INVOICE_ORDER_STATUS_GENERATE,
        value: 0
    }, {
        label: INVOICE_ORDER_STATUS_COMPLETE,
        value: 1
    }, {
        label: INVOICE_ORDER_STATUS_INVOICED,
        value: 3
    }
]

/**
 * 批改服务相关枚举
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
/**
 * 批改状态枚举
 * @type {Number}
 */
// 批改状态 -- 全部订单
export const CORRECT_SERVICE_STATE_ALL = ''
// 批改状态-- 待处理
export const CORRECT_SERVICE_STATE_PENGDING = 0
// 批改状态 -- 已通过
export const CORRECT_SERVICE_STATE_PASS = 1
// 批改状态 -- 已拒绝
export const CORRECT_SERVICE_STATE_REFUSE = 2
// 批改状态枚举
export const CORRECT_SERVICE_STATE_ENUM_LIST = [
    {
        label: '全部批改',
        value: CORRECT_SERVICE_STATE_ALL
    },
    {
        label: '待处理',
        value: CORRECT_SERVICE_STATE_PENGDING
    },
    {
        label: '已通过',
        value: CORRECT_SERVICE_STATE_PASS
    },
    {
        label: '已拒绝',
        value: CORRECT_SERVICE_STATE_REFUSE
    }
]

/**
 * 批改类型枚举
 * @type {Number}
 */
// 批改类型 -- 修改
export const CORRECT_SERVICE_TYPE_UPDATE = 0
// 批改类型-- 退保
export const CORRECT_SERVICE_TYPE_REFUND = 1
// 批改类型 -- 理赔
export const CORRECT_SERVICE_TYPE_CLAIM = 2
// 批改类型枚举
export const CORRECT_SERVICE_TYPE_ENUM_LIST = [
    {
        label: '修改',
        value: CORRECT_SERVICE_TYPE_UPDATE
    }, {
        label: '退保',
        value: CORRECT_SERVICE_TYPE_REFUND
    }, {
        label: '理赔',
        value: CORRECT_SERVICE_TYPE_CLAIM
    }
]

/**
 * 立即投保相关枚举
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
/**
 * 投保模式
 * @type {string}
 */
// 投保
export const INSURE_MODE_INSURE = 'insure'
// 完善投保
export const INSURE_MODE_PERFECT_INSURE = 'perfect_insure'
// 重新投保
export const INSURE_MODE_REINSURE = 'reinsure'
// 重新投保重新选择商品
export const INSURE_MODE_REINSURE_AND_RECHOOSE = 'reinsure_and_rechoose'
// 再次购买
export const INSURE_MODE_ONCE_AGAIN_BUY = 'once_again_buy'

/**
 * 表单提交终端
 * @type {Number}
 */
// 表单提交终端 枚举 —— pc
export const INSURANCE_FORM_SOURCE_PC = 1

/**
 * 投保表单 表单来源
 * @type {Number}
 */
// 投保表单 表单来源 枚举 —— 用户端
export const INSURANCE_FORM_TEMPLATE_SOURCE_USER = 1
// 投保表单 表单来源 枚举 —— 管家端、业务助理、完善
export const INSURANCE_FORM_TEMPLATE_SOURCE_AGENT = 3
// 投保表单 表单来源 枚举 —— 经纪端
export const INSURANCE_FORM_TEMPLATE_SOURCE_BUSINESS = 5

/**
 * 投保表单-项目 枚举
 * @type {Number}
 */
// 投保表单 枚举 —— G端
export const INSURANCE_FORM_GOVERNMENT = 0
// 投保表单 枚举 —— B端
export const INSURANCE_FORM_CLIENT = 1

/**
 * 投保表单审核-投保状态 枚举
 * @type {Number}
 */
// 投保状态 枚举 —— 待审核
export const INSURANCE_STATUS_PENGDING = 0
// 投保状态 枚举 —— 审核失败
export const INSURANCE_STATUS_FAILED = 1
// 投保状态 枚举 —— 审核成功
export const INSURANCE_STATUS_COMPLETE = 2
// 投保状态 枚举 —— 拒保
export const INSURANCE_STATUS_REJECT = 3
// 投保状态枚举
export const INSURANCE_STATUS_ENUM_LIST = [
    {
        label: '待审核',
        value: INSURANCE_STATUS_PENGDING
    },
    {
        label: '审核失败',
        value: INSURANCE_STATUS_FAILED
    },
    {
        label: '审核成功',
        value: INSURANCE_STATUS_COMPLETE
    },
    {
        label: '拒保',
        value: INSURANCE_STATUS_REJECT
    }
]

/**
 * 投保表单审核-保险审核 枚举
 * @type {Number}
 */
// 保险审核 枚举 —— 待审核
export const INSURANCE_AUDIT_PENGDING = 0
// 保险审核 枚举 —— 审核成功
export const INSURANCE_AUDIT_COMPLETE = 1
// 保险审核 枚举 —— 审核失败
export const INSURANCE_AUDIT_FAILED = 2
// 投保状态枚举
export const INSURANCE_AUDIT_ENUM_LIST = [
    {
        label: '待审核',
        value: INSURANCE_AUDIT_PENGDING
    }, {
        label: '审核成功',
        value: INSURANCE_AUDIT_COMPLETE
    }, {
        label: '审核失败',
        value: INSURANCE_AUDIT_FAILED
    }
]

/**
 * 投保表单审核-审核结果 枚举
 * @type {Number}
 */
// 产品状态 -- 待审核
export const INSURANCE_AUDIT_RESULT_PENDING = 0
export const INSURANCE_AUDIT_RESULT_PENDING_ENUM = {
    label: '待审核',
    value: INSURANCE_AUDIT_RESULT_PENDING
}
// 产品状态 -- 通过
export const INSURANCE_AUDIT_RESULT_PASS = 1
export const INSURANCE_AUDIT_RESULT_PASS_ENUM = {
    label: '审核通过',
    value: INSURANCE_AUDIT_RESULT_PASS
}
// 产品状态 -- 未通过
export const INSURANCE_AUDIT_RESULT_NO_PASS = 2
export const INSURANCE_AUDIT_RESULT_NO_PASS_ENUM = {
    label: '审核未通过',
    value: INSURANCE_AUDIT_RESULT_NO_PASS
}

export const INSURANCE_AUDIT_RESULT_ENUM_LIST = [INSURANCE_AUDIT_RESULT_PENDING_ENUM, INSURANCE_AUDIT_RESULT_PASS_ENUM, INSURANCE_AUDIT_RESULT_NO_PASS_ENUM]
export const INSURANCE_AUDIT_RESULT_ENUM_FORM_LIST = [INSURANCE_AUDIT_RESULT_PASS_ENUM, INSURANCE_AUDIT_RESULT_NO_PASS_ENUM]

/**
 * 投保表单审核-操作权限 枚举
 * @type {Number}
 */
// 操作权限-枚举 -- 保险端
export const INSURANCE_AUDIT_ACTIONABLE_INSURER = 0
// 操作权限-枚举 -- 用户端/经纪端
export const INSURANCE_AUDIT_ACTIONABLE_NOT_INSURER = 1

/**
 * 投保表单审核-保险信息-是否字段 枚举
 * @type {Number}
 */
// 保险信息-是否字段-枚举 -- 字段
export const INSURANCE_AUDIT_INSURANCE_TEXT = 0
// 保险信息-是否字段-枚举 -- 资料
export const INSURANCE_AUDIT_INSURANCE_FILE = 1

/**
 * 投保表单 表单action类型
 * @type {Number}
 */
// 表单action 枚举 —— 不生效
export const INSURANCE_FORM_ACTION_DEFAULT = 0
export const INSURANCE_FORM_ACTION_DEFAULT_ENUM = {
    label: '不生效',
    value: INSURANCE_FORM_ACTION_DEFAULT
}
// 表单action 枚举 —— 工保网
export const INSURANCE_FORM_ACTION_GBW = 1
export const INSURANCE_FORM_ACTION_GBW_ENUM = {
    label: '工保网',
    value: INSURANCE_FORM_ACTION_GBW
}
export const INSURANCE_FORM_ACTION_ENUM_LIST = [INSURANCE_FORM_ACTION_DEFAULT_ENUM, INSURANCE_FORM_ACTION_GBW_ENUM]

/**
 * 投保表单 表单action-业务对象类型
 * @type {String}
 */
// 表单action-业务对象类型 枚举 —— 项目-信息
export const INSURANCE_FORM_ACTION_OBJECT_CODE_PROJECT_TEXT = 'xmjbxx'
// 表单action-业务对象类型 枚举 —— 项目-附件
export const INSURANCE_FORM_ACTION_OBJECT_CODE_PROJECT_FILE =
    'project_attachment'
// 表单action-业务对象类型 枚举 —— 企业-信息
export const INSURANCE_FORM_ACTION_OBJECT_CODE_BUSINESS_TEXT =
    'business_information'
// 表单action-业务对象类型 枚举 —— 企业-附件
export const INSURANCE_FORM_ACTION_OBJECT_CODE_BUSINESS_FILE =
    'bo_image_data_info'

/**
 * 投保表单 表单action业务类型
 * @type {Number}
 */
// 表单action业务类型 枚举 —— 投保
export const INSURANCE_FORM_ACTION_TYPE_POLICY_HOLDER = 0
// 表单action业务类型 枚举 —— 被保
export const INSURANCE_FORM_ACTION_TYPE_POLICY_INSURED = 1
// 表单action业务类型 枚举 —— 项目
export const INSURANCE_FORM_ACTION_TYPE_PROJECT = 2

/**
 * 投保表单 表单类型
 * @type {Number}
 */
// 投保表单 表单类型 枚举 —— 单选框
export const INSURANCE_FORM_RADIO_TYPE = 6
// 投保表单 表单类型 枚举 —— 复选框
export const INSURANCE_FORM_CHECKBOX_TYPE = 7
// 投保表单 表单类型 枚举 —— 下拉框
export const INSURANCE_FORM_SELECT_TYPE = 8
/**
 * 投保表单 字段类型格式
 * @type {String}
 */
// 字段类型格式 枚举 —— 输入框
export const INSURANCE_FORM_INIT_INPUT_TYPE_INPUT = 'Input_box'
export const INSURANCE_FORM_INIT_INPUT_TYPE_INPUT_ENUM = {
    label: '输入框',
    value: INSURANCE_FORM_INIT_INPUT_TYPE_INPUT
}
// 字段类型格式 枚举 —— 日期框
export const INSURANCE_FORM_INIT_INPUT_TYPE_DATE = 'date'
export const INSURANCE_FORM_INIT_INPUT_TYPE_DATE_ENUM = {
    label: '日期框',
    value: INSURANCE_FORM_INIT_INPUT_TYPE_DATE
}
// 字段类型格式 枚举 —— 数字框
export const INSURANCE_FORM_INIT_INPUT_TYPE_DIGITAL = 'digital'
export const INSURANCE_FORM_INIT_INPUT_TYPE_DIGITAL_ENUM = {
    label: '数字框',
    value: INSURANCE_FORM_INIT_INPUT_TYPE_DIGITAL
}
// 字段类型格式 枚举 —— 地址框
export const INSURANCE_FORM_INIT_INPUT_TYPE_ADDRESS = 'address'
export const INSURANCE_FORM_INIT_INPUT_TYPE_ADDRESS_ENUM = {
    label: '地址框',
    value: INSURANCE_FORM_INIT_INPUT_TYPE_ADDRESS
}
// 字段类型格式 枚举 —— 选择框
export const INSURANCE_FORM_INIT_INPUT_TYPE_CHOOSE = 'choose'
export const INSURANCE_FORM_INIT_INPUT_TYPE_CHOOSE_ENUM = {
    label: '选择框',
    value: INSURANCE_FORM_INIT_INPUT_TYPE_CHOOSE
}
// 字段类型格式 枚举 —— 选择框-单选框
export const INSURANCE_FORM_INIT_INPUT_TYPE_RADIO_BUTTON = 'radio_button'
// 字段类型格式 枚举 —— 选择框-复选框
export const INSURANCE_FORM_INIT_INPUT_TYPE_CHECK_BOX = 'check_box'
// 字段类型格式 枚举 —— 下拉框
export const INSURANCE_FORM_INIT_INPUT_TYPE_DROP_DOWN = 'drop_down'
export const INSURANCE_FORM_INIT_INPUT_TYPE_DROP_DOWN_ENUM = {
    label: '下拉框',
    value: INSURANCE_FORM_INIT_INPUT_TYPE_DROP_DOWN
}
// 字段类型格式 枚举 —— 下拉框-单选框
export const INSURANCE_FORM_INIT_INPUT_TYPE_RADIO_DROP = 'radio_drop_down_box'
// 字段类型格式 枚举 —— 下拉框-复选框
export const INSURANCE_FORM_INIT_INPUT_TYPE_CHECK_DROP = 'check_drop_down_box'
// 字段类型格式 枚举 —— 附件
export const INSURANCE_FORM_INIT_INPUT_TYPE_ATTACHMENT = 'attachment'
export const INSURANCE_FORM_INIT_INPUT_TYPE_ATTACHMENT_ENUM = {
    label: '附件',
    value: INSURANCE_FORM_INIT_INPUT_TYPE_ATTACHMENT
}
export const INSURANCE_FORM_INIT_INPUT_TYPE_ENUM_LIST = [
    INSURANCE_FORM_INIT_INPUT_TYPE_INPUT_ENUM,
    INSURANCE_FORM_INIT_INPUT_TYPE_DATE_ENUM,
    INSURANCE_FORM_INIT_INPUT_TYPE_DIGITAL_ENUM,
    INSURANCE_FORM_INIT_INPUT_TYPE_ADDRESS_ENUM,
    INSURANCE_FORM_INIT_INPUT_TYPE_CHOOSE_ENUM,
    INSURANCE_FORM_INIT_INPUT_TYPE_DROP_DOWN_ENUM,
    INSURANCE_FORM_INIT_INPUT_TYPE_ATTACHMENT_ENUM
]

/**
 * 投保表单-编辑-关联字段 枚举
 * @type {number}
 */
// 关联字段 -- 不关联
export const INSURANCE_FORM_ASSOCIATED_NULL = 0
export const INSURANCE_FORM_ASSOCIATED_NULL_ENUM = {
    label: '无',
    value: INSURANCE_FORM_ASSOCIATED_NULL
}
// 关联字段 -- 保险金额
export const INSURANCE_FORM_ASSOCIATED_INSURANCE_MONEY = 1
export const INSURANCE_FORM_ASSOCIATED_INSURANCE_MONEY_ENUM = {
    label: '保险金额',
    value: INSURANCE_FORM_ASSOCIATED_INSURANCE_MONEY,
    field: 'insuranceInformation_insurance_money',
    smallField: 'insurance_money'
}
// 关联字段 -- 起保日期
export const INSURANCE_FORM_ASSOCIATED_START_INSURANCE_TIME = 2
export const INSURANCE_FORM_ASSOCIATED_START_INSURANCE_TIME_ENUM = {
    label: '起保日期',
    value: INSURANCE_FORM_ASSOCIATED_START_INSURANCE_TIME,
    field: 'insuranceInformation_insurance_time',
    smallField: 'insurance_time'
}
// 关联字段 -- 起保天数
export const INSURANCE_FORM_ASSOCIATED_INSURANCE_DAY = 3
export const INSURANCE_FORM_ASSOCIATED_INSURANCE_DAY_ENUM = {
    label: '起保天数',
    value: INSURANCE_FORM_ASSOCIATED_INSURANCE_DAY,
    field: 'insuranceInformation_insurance_day',
    smallField: 'insurance_day'
}
// 关联字段 -- 保险费率
export const INSURANCE_FORM_ASSOCIATED_INSURANCE_RATE = 4
export const INSURANCE_FORM_ASSOCIATED_INSURANCE_RATE_ENUM = {
    label: '保险费率',
    value: INSURANCE_FORM_ASSOCIATED_INSURANCE_RATE,
    field: 'insuranceInformation_insurance_rate',
    smallField: 'insurance_rate'
}
// 关联字段 -- 保费金额
export const INSURANCE_FORM_ASSOCIATED_PAY_MONEY = 5
export const INSURANCE_FORM_ASSOCIATED_PAY_MONEY_ENUM = {
    label: '保费金额',
    value: INSURANCE_FORM_ASSOCIATED_PAY_MONEY,
    field: 'insuranceInformation_pay_money',
    smallField: 'pay_money'
}
export const INSURANCE_FORM_ASSOCIATED_LIST = [
    INSURANCE_FORM_ASSOCIATED_NULL_ENUM,
    INSURANCE_FORM_ASSOCIATED_INSURANCE_MONEY_ENUM,
    INSURANCE_FORM_ASSOCIATED_START_INSURANCE_TIME_ENUM,
    INSURANCE_FORM_ASSOCIATED_INSURANCE_DAY_ENUM,
    INSURANCE_FORM_ASSOCIATED_INSURANCE_RATE_ENUM,
    INSURANCE_FORM_ASSOCIATED_PAY_MONEY_ENUM
]

/**
 * 投保审核-保险审核-编修改-保险信息字段 枚举
 * @type {number}
 */
// 保险信息字段 -- 保险金额
export const INSURANCE_INFO_FIELD_INSURANCE_MONEY = 'insurance_money'
export const INSURANCE_INFO_FIELD_INSURANCE_MONEY_ENUM = {
    label: 'insuranceMoney',
    value: INSURANCE_INFO_FIELD_INSURANCE_MONEY
}
// 保险信息字段 -- 起保日期
export const INSURANCE_INFO_FIELD_INSURANCE_TIME = 'insurance_time'
export const INSURANCE_INFO_FIELD_INSURANCE_TIME_ENUM = {
    label: 'startInsuranceTime',
    value: INSURANCE_INFO_FIELD_INSURANCE_TIME
}
// 保险信息字段 -- 保障天数
export const INSURANCE_INFO_FIELD_INSURANCE_DAY = 'insurance_day'
export const INSURANCE_INFO_FIELD_INSURANCE_DAY_ENUM = {
    label: 'insuranceDay',
    value: INSURANCE_INFO_FIELD_INSURANCE_DAY
}
// 保险信息字段 -- 保险费率
export const INSURANCE_INFO_FIELD_INSURANCE_RATE = 'insurance_rate'
export const INSURANCE_INFO_FIELD_INSURANCE_RATE_ENUM = {
    label: 'insuranceRate',
    value: INSURANCE_INFO_FIELD_INSURANCE_RATE
}
// 保险信息字段 -- 保费金额
export const INSURANCE_INFO_FIELD_PAY_MONEY = 'pay_money'
export const INSURANCE_INFO_FIELD_PAY_MONEY_ENUM = {
    label: 'payMoney',
    value: INSURANCE_INFO_FIELD_PAY_MONEY
}
// 保险信息字段 -- 终保日期
export const INSURANCE_INFO_FIELD_END_TIME = 'end_time'
export const INSURANCE_INFO_FIELD_END_TIME_ENUM = {
    label: 'endTime',
    value: INSURANCE_INFO_FIELD_END_TIME
}
export const INSURANCE_INFO_FIELD_LIST = [
    INSURANCE_INFO_FIELD_INSURANCE_MONEY_ENUM,
    INSURANCE_INFO_FIELD_INSURANCE_TIME_ENUM,
    INSURANCE_INFO_FIELD_INSURANCE_DAY_ENUM,
    INSURANCE_INFO_FIELD_INSURANCE_RATE_ENUM,
    INSURANCE_INFO_FIELD_PAY_MONEY_ENUM,
    INSURANCE_INFO_FIELD_END_TIME_ENUM
]

/**
 * 投保表单-编辑-带出规则 枚举
 * @type {number}
 */
// 带出规则 -- 不计算
export const INSURANCE_FORM_DATE_RULES_NULL = 0
export const INSURANCE_FORM_DATE_RULES_NULL_ENUM = {
    label: '无',
    value: INSURANCE_FORM_DATE_RULES_NULL
}
// 带出规则 -- 内蒙古工期计算规则
export const INSURANCE_FORM_DATE_RULES_NMG_PROJECT_TIME = 1
export const INSURANCE_FORM_DATE_RULES_NMG_PROJECT_TIME_ENUM = {
    label: '内蒙古工期计算规则',
    value: INSURANCE_FORM_DATE_RULES_NMG_PROJECT_TIME
}
// 带出规则 -- 合肥工期计算规则
export const INSURANCE_FORM_DATE_RULES_HF_PROJECT_TIME = 2
export const INSURANCE_FORM_DATE_RULES_HF_PROJECT_TIME_ENUM = {
    label: '合肥工期计算规则',
    value: INSURANCE_FORM_DATE_RULES_HF_PROJECT_TIME
}
// 带出规则 -- 雅安工期计算规则
export const INSURANCE_FORM_DATE_RULES_YA_PROJECT_TIME = 3
export const INSURANCE_FORM_DATE_RULES_YA_PROJECT_TIME_ENUM = {
    label: '雅安工期计算规则',
    value: INSURANCE_FORM_DATE_RULES_YA_PROJECT_TIME
}
export const INSURANCE_FORM_DATE_RULES_LIST = [
    INSURANCE_FORM_DATE_RULES_NULL_ENUM,
    INSURANCE_FORM_DATE_RULES_NMG_PROJECT_TIME_ENUM,
    INSURANCE_FORM_DATE_RULES_HF_PROJECT_TIME_ENUM,
    INSURANCE_FORM_DATE_RULES_YA_PROJECT_TIME_ENUM
]

/**
 * 投保 用户类型
 * @type {Number}
 */
// 投保 用户类型 枚举 —— 用户端
export const INSURANCE_USER_TYPE_USER = 0
// 投保 用户类型 枚举 —— 经纪人
export const INSURANCE_USER_TYPE_AGENT = 1
// 投保 用户类型 枚举 —— 渠道
export const INSURANCE_USER_TYPE_CHANNEL = 2

/**
 * 投保 发票类型
 * @type {Number}
 */
export const INSURANCE_INVOICE_TYPE_PLAIN1 = 1 // 普通发票
export const INSURANCE_INVOICE_TYPE_PLAIN3 = 3 // 普通发票
export const INSURANCE_INVOICE_TYPE_SPECIAL2 = 2 // 专用发票
export const INSURANCE_INVOICE_TYPE_SPECIAL4 = 4 // 专用发票

export const INSURANCE_INVOICE_TYPE_PLAIN = 0 // 普通发票
export const INSURANCE_INVOICE_TYPE_PLAIN_ENUM = {
    label: '普通发票',
    value: INSURANCE_INVOICE_TYPE_PLAIN
}
export const INSURANCE_INVOICE_TYPE_SPECIAL = 1 // 专用发票
export const INSURANCE_INVOICE_TYPE_SPECIAL_ENUM = {
    label: '专用发票',
    value: INSURANCE_INVOICE_TYPE_SPECIAL
}

export const INSURANCE_INVOICE_TYPE_LIST = [INSURANCE_INVOICE_TYPE_PLAIN_ENUM, INSURANCE_INVOICE_TYPE_SPECIAL_ENUM]

/**
 * 投保流程
 * @type {Number}
 */
// 直接投保
export const INSURANCE_PROCESS_TYPE_DIRECT = 2


/**
 * 投保咨询-性别 枚举
 * @type {String}
 */
// 投保咨询-性别 -- 男
export const INSUREANCE_GENDER_MAN = '男'
// 投保咨询-性别 -- 女
export const INSUREANCE_GENDER_WOMAN = '女'

/**
 * 企业服务-性别 枚举
 * @type {String}
 */
// 企业服务-性别 -- 男
export const ENTERPRISE_SERVICE_GENDER_MAN = '先生'
// 企业服务-性别 -- 女
export const ENTERPRISE_SERVICE_GENDER_WOMAN = '女士'


/**
 * 轮播图类型 枚举
 * @type {Number}
 */

// 轮播图类型 -- 首页（0）
export const CAROUSAL_INDEX = 0
// 轮播图类型 -- 百万年薪（1）
export const CAROUSAL_MILLION = 1
// 轮播图类型 -- 报价方案（2）
export const CAROUSAL_QUOTATION = 2
// 轮播图类型 -- 工保百科（3）
export const CAROUSAL_WIKI = 3

/**
 * 系统编码 枚举
 * @type {String}
 */
// 系统编码 枚举 —— 后台
export const APP_CODE_NET_BACKEND = 'net-backend'
// 系统编码 枚举 —— 经纪人
export const APP_CODE_NET_AGENT = 'net-agent'
// 系统编码 枚举 —— 保险公司用户
export const APP_CODE_NET_INS = 'net-ins'
// 系统编码 枚举 —— 普通用户
export const APP_CODE_NET_USER = 'net-user'

/**
 * 咨询类型 枚举
 * @type {String}
 */
// 系统编码 枚举 —— 产品咨询
export const PRODUCT_CONSULTING = '0'
// 系统编码 枚举 —— 经纪人咨询
export const AGENT_CONSULTING = '1'

/**
 * @description 验证码类型
 * @type {String}
 */
export const VERIFY_CODE_TYPE = {
    login: 'PHONE_LOGIN',
    register: 'PHONE_REGISTER',
    updatePassword: 'PHONE_PASSWORD',
    updateMobile: 'PHONE_CHANGE',
    verityIdentity: 'PHONE_VERIFY'
}

/**
 * 购物车状态 枚举
 * @type {Number}
 */
// 购物车-待提交
export const CART_WAIT_SUBMIT = 0
// 购物车-已提交订单
export const CART_SUBMITTED = 1
// 购物车-待审查审核(暂时没用)
export const CART_WAIT_REVIEW = 2
// 购物车-待风控审核
export const CART_WAIT_REVIEW_RISK = 3
// 购物车-待保险审核
export const CART_WAIT_REVIEW_INSURE = 4
// 购物车-待支付订单
export const CART_WAIT_PAY = 5
// 购物车-待保单出单
export const CART_WAIT_ISSUED = 6
// 购物车-订单已完成
export const CART_COMPLETED = 7
// 购物车-订单已关闭
export const CART_CLOSED = 8
// 购物车-补充资料审核
export const CART_SUPPLEMENT_REVIEW = 20

export const INSURED_ORDER_STATUS_LIST = [
    {
        label: '待提交',
        value: CART_WAIT_SUBMIT
    },
    {
        label: '已提交订单',
        value: CART_SUBMITTED
    },
    {
        label: '待审查审核',
        value: CART_WAIT_REVIEW
    },
    {
        label: '待风控审核',
        value: CART_WAIT_REVIEW_RISK
    },
    {
        label: '待保险审核',
        value: CART_WAIT_REVIEW_INSURE
    },
    {
        label: '待支付订单',
        value: CART_WAIT_PAY
    },
    {
        label: '待保单出单',
        value: CART_WAIT_ISSUED
    },
    {
        label: '订单已完成',
        value: CART_COMPLETED
    },
    {
        label: '订单已关闭',
        value: CART_CLOSED
    },
    {
        label: '补充资料审核',
        value: CART_SUPPLEMENT_REVIEW
    }
]

/**
 * 购物车用户确认状态 枚举
 * @type {Number}
 */
// 未确认 0
export const CART_UNCONFIRMED = 0
// 待确认 1
export const CART_WAIT_CONFIRMED = 1
// 已确认 3
export const CART_CONFIRMED = 3

/**
 * 购物车风控控制状态 枚举
 * @type {Number}
 */
// 开启 1
export const RISK_STATE_OPEN = 1
// 关闭 2
export const RISK_STATE_CLOSE = 2

/**
 * 订单列表- 类型 枚举
 * @type {Number}
 */
// 订单列表- 类型- 用户
export const ORDER_TYPE_USER = 0
// 订单列表- 类型- 经纪人
export const ORDER_TYPE_AGENT = 1
// 订单列表- 类型-企业
export const ORDER_TYPE_COMPANY = 2

// 用户角色
export const ROLE_CODE_USER = 'gbwOrdinaryRole' // 工保网普通用户角色
export const ROLE_CODE_BROKER = 'InformalBroker' // 工保网非正式经纪人
export const ROLE_NEED_CONFIRM_TIP = [ROLE_CODE_BROKER] // 身份 需要对话框提示

/**
 * 是否默认  枚举
 * @type {Number}
 */

// 不默认
export const IS_DEFAULT_FALSE = 0
// 默认
export const IS_DEFAULT_TRUE = 1
/**
 *购物车状态 枚举
 * @type {Number}
 */
// 有效
export const STATE_EFFECTIVE = 0
// 无效
export const STATE_INVALID = 1
// 已关闭订单
export const STATE_CLOSE_ORDER = 2

// 账户设置 --- 发票抬头设置枚举  START  ————————————————————————————————————————————————————————

/**
 * 发票类型
 * @type {Number}
 */
export const PLAIN_INVOICE_STATE = 0 // 普通发票
export const DEDICATED_INVOICE_STATE = 1 // 专用发票

export const PLAIN_INVOICE_STATE_MENU = {
    label: '增值税普通发票',
    value: PLAIN_INVOICE_STATE
}

export const DEDICATED_INVOICE_STATE_MENU = {
    label: '增值税专用发票',
    value: DEDICATED_INVOICE_STATE
}

export const INVOICE_TITLE_MENU_LIST = [PLAIN_INVOICE_STATE_MENU, DEDICATED_INVOICE_STATE_MENU]

// 发票类型 Map
export const INVOICE_TITLE_TYPE_MAP = {
    [PLAIN_INVOICE_STATE]: PLAIN_INVOICE_STATE_MENU.label,
    [DEDICATED_INVOICE_STATE]: DEDICATED_INVOICE_STATE_MENU.label
}

/**
 * 默认状态枚举
 * @type {Number}
 */
export const INVOICE_TITLE_STATE_DEFAULT = 1 // 默认状态
export const INVOICE_TITLE_STATE_UNKNOWN = 0 // 未设置状态

// 账户设置 --- 发票抬头设置枚举  END  ————————————————————————————————————————————————————————

// 账户设置 --- 个人信息设置枚举  START  ————————————————————————————————————————————————————————
/**
 * 性别
 * @type {String}
 */
export const ACCOUNT_USER_SEX_FEMALE = 1 // 女性
export const ACCOUNT_USER_SEX_MALE = 0 // 男性
export const ACCOUNT_USER_SEX_UNKNOWN_FIRST = 2 // 未知
export const ACCOUNT_USER_SEX_UNKNOWN = 'UNKNOWN' // 未知

export const ACCOUNT_USER_SEX_FEMALE_MENU = {
    label: '女',
    value: ACCOUNT_USER_SEX_FEMALE
}

export const ACCOUNT_USER_SEX_MALE_MENU = {
    label: '男',
    value: ACCOUNT_USER_SEX_MALE
}

export const ACCOUNT_USER_SEX_UNKNOWN_MENU = {
    label: '未知',
    value: ACCOUNT_USER_SEX_UNKNOWN
}
export const ACCOUNT_USER_SEX_UNKNOWN_MENU_FIRST = {
    label: '未知',
    value: ACCOUNT_USER_SEX_UNKNOWN_FIRST
}

export const ACCOUNT_USER_MENU_ENUM = [ACCOUNT_USER_SEX_FEMALE_MENU, ACCOUNT_USER_SEX_MALE_MENU]

/**
 * @description 修改用户信息 更新 类型
 * @type {Number}
 */

export const UPDATE_INFO_TYPE = 0 // 常规更新
export const UPDATE_INFO_EMPTY_STRING_TYPE = 1 // 空字符串更新  (后端约定传空字符串则更新当前字段)

export const UPDATE_TYPE_USER = 'UPDATE_USER' // 修改-个人信息
export const UPDATE_TYPE_PASSWORD = 'UPDATE_PASSWORD' // 修改-密码
export const UPDATE_TYPE_PHONE = 'UPDATE_PHONE' // 修改-手机号

// 账户设置 --- 个人信息设置枚举  END  ————————————————————————————————————————————————————————

// 消息中心 --- 消息枚举  START  ————————————————————————————————————————————————————————

/**
 * 消息已读/未读
 * @type {Boolean}
 */
export const MESSAGE_READ = true // 已读
export const MESSAGE_UNREAD = false // 未读

export const MESSAGE_READ_MENU = {
    label: '已读',
    value: MESSAGE_READ
}

export const MESSAGE_UNREAD_MENU = {
    label: '未读',
    value: MESSAGE_UNREAD
}
export const MESSAGE_READ_STATE_MENU = [MESSAGE_READ_MENU, MESSAGE_UNREAD_MENU]

/**
 * 消息类型枚举
 * @type {Number}
 */
export const GENERAL_NOTICE = 0 // 普通通知
export const IMPORTANT_NOTICE = 1 // 重要通知
export const URGENT_NOTICE = 2 // 紧急通知
export const REMIND_NOTICE = 3 // 提醒

export const MESSAGE_TYPE_GENERAL_MENU = {
    label: '普通通知',
    value: GENERAL_NOTICE
}

export const MESSAGE_TYPE_IMPORTANT_MENU = {
    label: '重要通知',
    value: IMPORTANT_NOTICE
}

export const MESSAGE_TYPE_URGENT_MENU = {
    label: '紧急通知',
    value: URGENT_NOTICE
}

export const MESSAGE_TYPE_REMIND_MENU = {
    label: '提醒',
    value: REMIND_NOTICE
}
export const MESSAGE_TYPE_LIST_MENU = [
    MESSAGE_TYPE_GENERAL_MENU,
    MESSAGE_TYPE_IMPORTANT_MENU,
    MESSAGE_TYPE_URGENT_MENU,
    MESSAGE_TYPE_REMIND_MENU
]

// 消息中心 --- 消息枚举  END  ————————————————————————————————————————————————————————

/* 报价工具
----------------------------------------- */
export const PROJECT_TIME_MAX_VALUE = 300 // 最大项目工期

/**
 * 支付方式枚举
 * @type {String}
 */
export const PAY_METHOD_ONLINE = '1' // 线上支付
export const PAY_METHOD_OFFLINE = '2' // 线下支付
export const PAY_METHOD_ENTERPRISE = '3' // 企业支付

/**
 * 保险端-支付方式枚举
 * @type {Number}
 */
// 支付方式 -- 线下
export const ORDER_PAY_TYPE_ON_LINE = 0
export const ORDER_PAY_TYPE_ON_LINE_ENUM = {
    label: '线下',
    value: ORDER_PAY_TYPE_ON_LINE
}
// 支付方式 -- 线上
export const ORDER_PAY_TYPE_OFF_LINE = 1
export const ORDER_PAY_TYPE_OFF_LINE_ENUM = {
    label: '线上',
    value: ORDER_PAY_TYPE_OFF_LINE
}
export const ORDER_PAY_TYPE_ENUM_LIST = [ORDER_PAY_TYPE_ON_LINE_ENUM, ORDER_PAY_TYPE_OFF_LINE_ENUM]

/**
 * 退保进度枚举
 * @type {Number}
 */
// 待审核
export const WAIT_REVIEWED_STATE = 0
// 通过
export const PASSED_STATE = 1
// 拒绝
export const REFUSE_STATE = 2

/**
 * 经纪人枚举
 * @type {Number}
 */
// 外部经纪人（可邀请用户）
export const EXTERNAL_BROKER = 0
// 业务经纪人 （可邀请用户和经纪人）--邀请用户已改为推广获客
export const BUSINESS_BROKER = 1

export const HOUSE_KEEPER_USER_TYPE_CODE = 4

/**
 * 是否查询企业信息枚举
 * @type {Number}
 */
// 查询
export const QUERY_ENTERPRISE_INFO_YES = 1
// 不查询
export const QUERY_ENTERPRISE_INFO_NO = 0

/**
 * 用户标签码值
 * @type {Number}
 */
// 经纪人
export const TYPE_VALUE_BROKER = 1
// 普通用户
export const TYPE_VALUE_USER = 2
// 保险用户
export const TYPE_VALUE_INSURANCE = 3

/**
 * 表单禁用方式
 * @type {Number}
 */
export const DISABLED_TYPE_ONLINE = 0 // 使用线上数据
// 根据关联关系判断
export const DISABLED_TYPE_RELATED = 1
// 根据本地的数据
export const DISABLED_TYPE_LOCAL = 2
// 根据自己的规则的数据
export const DISABLED_TYPE_OTHER = 3

/**
 * 签章枚举
 * @type {Number}
 */
// 电子签章
export const ELECTRONIC_SIGNATURE_TYPE = 1
// 手动下载/上传
export const MANUAL_SIGNATURE_TYPE = 2

/**
 * 投保单/反担保参数枚举
 */
// 投保单
export const CAST_PARAMS = 'cast_insurance_single'
// 反担保
export const GUARANTEE_PARAMS = 'counter_guarantee'

/**
 * 投保单/反担保type枚举
 */
// 投保单
export const CAST_PARAMS_TYPE = 0
// 反担保
export const GUARANTEE_PARAMS_TYPE = 1

/**
 * 签署状态---补充资料审核是否通过枚举
 */
// 初始值
export const DEPLOY_INITIAL_STATE = 0
// 待签署
export const DEPLOY_INITIAL_STATE_WAIT = 1
// 已签署
export const DEPLOY_INITIAL_STATE_FINISH = 2


/**
 * 埋点---保险流程相关枚举
 * 1, "开始支付" -> 4、支付开始时间：点击“立即支付”按钮时间
 * 2, "开始上传保单" -> 线下保单处理时，点击“配置保单信息（已完成文字为更新保单信息）”按钮时间
 * 3, "开始提交"  -> 1、管家提交开始时间：购物车列表点击 “完善投保信息”按钮时间
 * 4, "保司开始审核" -> 3、保险公司审核开始时间：点击“保险审核”按钮时间
 */
// 开始支付
export const DATA_BURIED_TYPE_START_PAY = 1
// 开始上传保单
export const DATA_BURIED_TYPE_UPLOAD_POLICY = 2
// 开始提交
export const DATA_BURIED_TYPE_START_SUBMITTING = 3
// 保司开始审核
export const DATA_BURIED_TYPE_START_REVIEW = 4


/**
 * 投保表单模块类型枚举
 * @type {String}
 */
// 保险信息
export const INSURE_FORM_MODULE_TYPE_INSURE_INFO = '保险信息'
// 投保人信息
export const INSURE_FORM_MODULE_TYPE_POLICY_HOLDER = '投保'
// 被保险人信息
export const INSURE_FORM_MODULE_TYPE_POLICY_INSURED = '被保'
// 标的信息
export const INSURE_FORM_MODULE_TYPE_POLICY_OBJECT = '标的'


/**
 * 投保表单模块名称默认值
 * @type {String}
 */
// 保险信息
export const INSURE_FORM_MODULE_NAME_INSURE_INFO = '保险信息'
// 投保人信息
export const INSURE_FORM_MODULE_NAME_POLICY_HOLDER = '投保人信息'
// 被保险人信息
export const INSURE_FORM_MODULE_NAME_POLICY_INSURED = '被保人信息'
// 标的信息
export const INSURE_FORM_MODULE_NAME_POLICY_OBJECT = '项目标段信息'


/**
 * 业务来源类型枚举
 * @type {Number}
 */
// B端
export const BUSINESS_SOURCE_TYPE_B_LABEL = 'B端'
// G端
export const BUSINESS_SOURCE_TYPE_G_LABEL = 'G端'


/**
 * 产品列表-地区配置-费率类型 枚举值
 * @type {Number}
 */
// 费率类型 枚举 —— 次费率
export const PRODUCT_RATE_TYPE_TIMES = 0
// 费率类型 枚举 —— 日费率
export const PRODUCT_RATE_TYPE_YEAR = 1
// 费率类型 枚举 —— 月费率
export const PRODUCT_RATE_TYPE_MONTH = 2
// 费率类型 枚举 —— 季度费率
export const PRODUCT_RATE_TYPE_SEASON = 3
// 费率类型 枚举 —— 固定保费
export const PRODUCT_RATE_TYPE_REGULAR_INSURANCE = 4


/**
 * 保障类型枚举
 * @type {String}
 */
// 保障信息
export const GUARANTEE_TYPE_INFO = '保障信息'
// 保障规则
export const GUARANTEE_TYPE_RULE = '保障规则'


/**
 * 条款类型 枚举
 * @type {String}
 */
// 条款类型 枚举 —— 主条款
export const TERM_TYPE_PRINCIPAL_CLAUSE = '主条款'
// 条款类型 枚举 —— 附加条款
export const TERM_TYPE_ADDITIONAL_TERMS = '附加条款'
